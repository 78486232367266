import React, { useState, useEffect } from "react";

interface CheckBoxToggleProps {
  initialChecked?: boolean; // Use boolean type here
  onChange?: (checked: boolean) => void; // Change this to boolean
  disabled?: boolean; // Keep disabled as boolean
}

const CheckBoxToggle: React.FC<CheckBoxToggleProps> = ({
  initialChecked = false, // Default to false (unchecked)
  onChange,
  disabled = false, // Default disabled to false
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(initialChecked);

  useEffect(() => {
    if (initialChecked !== undefined) {
      setIsChecked(initialChecked);
    }
  }, [initialChecked]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const newChecked = e.target.checked; // Get the checked state from the checkbox
    setIsChecked(newChecked);
    if (onChange) {
      onChange(newChecked); // Pass boolean to onChange
    }
  };

  return (
    <label className="flex items-center cursor-pointer select-none">
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 text-green-600"
        checked={isChecked}
        onChange={handleChange}
        disabled={disabled}
      />
      <span
        className={`ml-2 text-sm ${
          disabled ? "text-gray-400 cursor-not-allowed" : ""
        }`}
      >
        {/* {isChecked ? "Checked" : "Unchecked"} */}
      </span>
    </label>
  );
};

export default CheckBoxToggle;
