import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { VerifyUserContext } from "../../context/VerifyUserContext";

const VerifyCreateUserCodes: React.FC = () => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  // const email = localStorage.getItem("email") || "";
  const [email, setEmail] = useState("") || "";
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{
    otp?: string;
    email?: string;
    usererror?: string;
    otprerror?: string;
    waitotp?: string;
    general?: string;
  }>({});
  const { verifyEmailCode } = useContext(VerifyUserContext);
  const navigate = useNavigate();

  // Create refs for each OTP input
  const otpRefs = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    // Focus the first input on component mount
    otpRefs.current[0]?.focus();
  }, []);

  const handleOtpChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) {
      // Only allow digits
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value.slice(-1); // Ensure only one digit
    setOtp(newOtp);

    if (value && index < otpRefs.current.length - 1) {
      // Move focus to next input if exists
      otpRefs.current[index + 1]?.focus();
    }
  };

  const handleOtpKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      if (otp[index]) {
        // If current input has a value, clear it
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        // Move to previous input if exists
        otpRefs.current[index - 1]?.focus();
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      // Optional: Handle left arrow navigation
      otpRefs.current[index - 1]?.focus();
    } else if (e.key === "ArrowRight" && index < otpRefs.current.length - 1) {
      // Optional: Handle right arrow navigation
      otpRefs.current[index + 1]?.focus();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);
    try {
      await verifyEmailCode(email, otp.join(""), navigate, setErrors);
    } catch (error) {
      setErrors({ general: "An error occurred. Please try again later." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4 text-slate-600 text-center">
          تحقق من الايميل
        </h2>
        {/* <div className="mt-2 text-gray-600 mb-4 flex justify-center text-SM font-normal">
          <a
            href={"https://mail.google.com"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-blue-500 mx-1">{email}</span>
          </a>
        </div> */}
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">
              البريد الإلكتروني
            </label>
            <input
              type="email"
              className={`w-full p-2 border ${
                errors.email ? "border-red-500" : "border-gray-300"
              } rounded-lg focus:outline-none focus:ring-2 `}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">{errors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-right ">كود التحقق OTP </label>
            <div className="flex justify-center">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  pattern="\d*"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  onKeyDown={(e) => handleOtpKeyDown(e, index)}
                  ref={(el) => (otpRefs.current[index] = el)}
                  className="w-12 h-12 text-center mx-1 border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                />
              ))}
            </div>
            {errors.otp && <p className="text-red-500 mt-2">{errors.otp}</p>}
          </div>
          <button
            type="submit"
            className={`w-full bg-[#2a66b1] text-white p-2 rounded mt-4 ${
              isLoading ? "opacity-75 animate-fade" : ""
            }`}
            disabled={isLoading}
          >
            تأكيد
          </button>
          <div className="mt-4 underline text-blue-400 flex justify-center text-md font-normal">
            <button type="button" onClick={() => navigate(-1)}>
              رجوع للخلف
            </button>
          </div>
        </form>
        {errors.general && (
          <p className="text-red-500 mt-4">{errors.general}</p>
        )}
        {errors.usererror && (
          <p className="text-red-500 mt-4">{errors.usererror}</p>
        )}
        {errors.otprerror && (
          <p className="text-red-500 mt-4">{errors.otprerror}</p>
        )}
        {errors.waitotp && (
          <p className="text-red-500 mt-4">{errors.waitotp}</p>
        )}
      </div>
    </div>
  );
};

export default VerifyCreateUserCodes;
