// src/components/MediaGroupComponent.tsx
import React from "react";
import { AiFillFileImage } from "react-icons/ai";
import MediaSendGroup from "./MediaSendGroup";

const MediaGroupComponent: React.FC = () => {
  return (
    <div className="justify-start mx-2 container mt-14">
      <div className="flex justify-start gap-3 sm:w-10/12 mx-auto text-lg mt-8">
      <AiFillFileImage size={25} className="text-cyan-600"/>
      <span>ارسال ملفات للمجموعات</span>
        </div>
      <MediaSendGroup />
    </div>
  );
};

export default MediaGroupComponent;
