import React, { useContext, useEffect, useState } from "react";
import DeviceMaster from "./DeviceMaster";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import UserDevices from "./UserDevices";

const UserMaster: React.FC = () => {
  const { isagent } = useContext(AuthContext);
  const location = useLocation();

  const [activeTab, setActiveTab] = useState<string>(
    isagent === 1 ? "users" : "devices"
  );

  // const getDeviceCountForUser = (userId: number) => {
  //   if (!deviceData || !deviceData.data) return 0;
  //   return deviceData.data.filter((device: any) => device.id_users === userId)
  //     .length;
  // };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabFromUrl =
      queryParams.get("tab") || (isagent === 1 ? "users" : "devices");
    setActiveTab(tabFromUrl);
  }, [location.search, isagent]);

  return (
    <div className="px-4 min-h-screen">
      <div className="bg-white rounded-sm md max-h-screen px-0 overflow-y-auto hide-scrollbar">
        {/* Tabs */}
        <div className="flex gap-2 justify-start bg-gray-100 p-2 rounded-lg mb-2 mt-6">
          <button
            onClick={() => setActiveTab("devices")}
            className={`py-2 px-2 ${
              activeTab === "devices"
                ? "bg-amber-50 rounded-md text-blue-500 font-bold"
                : "text-gray-600 font-semibold"
            }`}
          >
            الاجهزة المرتبطة بك
          </button>
          {isagent === 1 ? (
            <button
              onClick={() => setActiveTab("users")}
              className={`py-2 px-2 ${
                activeTab === "users"
                  ? "bg-amber-50 rounded-md text-blue-500 font-bold"
                  : "text-gray-600 font-semibold"
              }`}
            >
              المستخدمين
            </button>
          ):null}
        </div>
        {activeTab === "users" && (
          <div>
            <UserDevices />
          </div>
        )}

        {activeTab === "devices" && <DeviceMaster />}
      </div>
    </div>
  );
};

export default UserMaster;
