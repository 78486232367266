import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
} from "react";
import axios from "axios";
import { AuthContext } from "../AuthContext"; // Import AuthContext for token

// Interface for Deposit items from API response
interface Deposit {
  id: number;
  user_id: number;
  MasterId: number;
  user_master_id: number;
  AgentUserName: string;
  UserName: string;
  order_id: number | null;
  method_code: number;
  amount: string;
  method_currency: string;
  charge: string;
  rate: string;
  final_amo: string;
  detail: string;
  btc_amo: string;
  btc_wallet: string;
  trx: string;
  try: number;
  status: number;
  from_api: number;
  admin_feedback: string | null;
  created_at: string;
  updated_at: string;
}

// Interface for mapped UserDepositHistory
interface UserDepositHistory {
  status: ReactNode;
  MasterId: number;
  AgentUserName: string;
  UserName: string;
  updated_at: string;
  id: number;
  final_amo: string;
  amount: string;
  rate: string;
  details: string;
}

interface UserDepositHistoryApiResponse {
  status: boolean;
  errNum: string;
  msg: string;
  data: {
    Deposit: Deposit[];
  };
}

interface Filters {
  from_amount: string;
  to_amount: string;
  from_date: string;
  to_date: string;
  status: string;
  Search: string;
}

interface UserDepositHistoryContextType {
  UserDepositHistory: UserDepositHistory[] | null;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  loading: boolean;
  error: string | null;
  fetchUserDepositHistorys: () => Promise<void>;
}

interface UserDepositHistoryProviderProps {
  children: ReactNode;
}

export const UserDepositHistoryContext = createContext<
  UserDepositHistoryContextType | undefined
>(undefined);

const UserDepositHistoryProvider: React.FC<UserDepositHistoryProviderProps> = ({
  children,
}) => {
  const [UserDepositHistory, setUserDepositHistory] = useState<
    UserDepositHistory[] | null
  >(null);
  const [filters, setFilters] = useState<Filters>({
    from_amount: "",
    to_amount: "",
    from_date: "",
    to_date: "",
    status: "",
    Search: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Get token from AuthContext
  const { token } = useContext(AuthContext);

  const fetchUserDepositHistorys = useCallback(async () => {
    setLoading(true);
    const url =
      "https://click.one.sky4system.com/api/user/Setting/depositHistory";

    const formData = new FormData();
    if (filters.from_amount) {
      formData.append("from_amount", filters.from_amount);
    }
    if (filters.to_amount) {
      formData.append("to_amount", filters.to_amount);
    }
    if (filters.from_date) {
      formData.append("from_date", filters.from_date);
    }
    if (filters.to_date) {
      formData.append("to_date", filters.to_date);
    }
    if (filters.Search) {
      formData.append("Search", filters.Search);
    }

    const headers: HeadersInit = {};
    if (token) {
      headers["auth-token"] = token;
    }

    try {
      const response = await axios.post<UserDepositHistoryApiResponse>(
        url,
        formData,
        {
          headers,
          params: {
            token,
          },
        }
      );
      // console.log(response.data)
      if (response.data?.status) {
        // Map Deposit data to UserDepositHistory format
        const mappedData = response.data.data.Deposit.map((deposit) => ({
          id: deposit.id,
          MasterId: deposit.MasterId,
          amount: deposit.amount,
          AgentUserName: deposit.AgentUserName,
          UserName: deposit.UserName,
          final_amo: deposit.final_amo,
          debit_amount: deposit.amount,
          details: deposit.detail,
          status: deposit.status,
          updated_at: deposit.updated_at,
          rate: `${deposit.rate} ${deposit.method_currency}`,
        }));

        setUserDepositHistory(mappedData);
      } else {
        setError(response.data?.msg || "Failed to fetch user balances");
      }
    } catch (err) {
      setError("An error occurred while fetching user balances.");
    } finally {
      setLoading(false);
    }
  }, [filters, token]);

  useEffect(() => {
    fetchUserDepositHistorys();
  }, [fetchUserDepositHistorys]);

  return (
    <UserDepositHistoryContext.Provider
      value={{
        UserDepositHistory,
        filters,
        setFilters,
        loading,
        error,
        fetchUserDepositHistorys,
      }}
    >
      {children}
    </UserDepositHistoryContext.Provider>
  );
};

export const useUserDepositHistory = (): UserDepositHistoryContextType => {
  const context = useContext(UserDepositHistoryContext);
  if (!context) {
    throw new Error(
      "useUserDepositHistory must be used within a UserDepositHistoryProvider"
    );
  }
  return context;
};

export default UserDepositHistoryProvider;
