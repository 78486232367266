import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { hasPermission } from "../utils/permissionUtils";

interface ProtectedRouteProps {
  element: React.ReactElement;
  requiredSection: string; // Section to check (e.g., "Device")
  requiredAction: string; // Action to check (e.g., "view")
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  requiredSection,
  requiredAction,
}) => {
  const { permissions } = useContext(AuthContext);

  const hasRequiredPermission = hasPermission(
    permissions,
    requiredSection,
    requiredAction
  );

  return hasRequiredPermission ? element : <Navigate to="/dashboard" replace />;
};

export default ProtectedRoute;
