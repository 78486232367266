import React, { useCallback, useContext, useMemo } from "react";
import Select, { SingleValue } from "react-select";
import "./css/GroupsSelect.css";
import { UserMasterContext } from "../../../../context/UserMasterContext";

interface OptionType {
  value: string;
  label: string;
  userDetails?: any;
}

interface UsersSelectProps {
  onUsersSelect: (userDetails: any) => void;
  selectedOptions: SingleValue<OptionType> | null;
}

const UserSelected: React.FC<UsersSelectProps> = ({
  onUsersSelect,
  selectedOptions,
}) => {
  const { userData } = useContext(UserMasterContext);

  // Use useMemo to map users into select options
  const options = useMemo(() => {
    if (!userData || !Array.isArray(userData.data)) return [];

    return userData?.data.map((user: any) => ({
      value: user.id,
      label: user.name || "لا يوجد مستخدمين",
      userDetails: user,
    }));
  }, [userData]);

  const handleChange = useCallback(
    (selected: SingleValue<OptionType>) => {
      if (selected) {
        onUsersSelect(selected.userDetails);
      }
    },
    [onUsersSelect]
  );
  

  return (
    <div className="mt-2 mb-1">
      <Select
        className="py-1"
        onChange={handleChange}
        options={options}
        placeholder="اختار مستخدم"
      />
    </div>
  );
};

export default UserSelected;
