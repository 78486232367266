// RequestOtp.tsx
import React, { useState, useContext } from "react";
import { GenerateOtpContext } from "../../context/GenerateOtpContext";
import { useNavigate } from "react-router-dom";

const RequestOtp: React.FC = () => {
  const [errors, setErrors] = useState<{
    email?: string;
    general?: string;
    usererror?: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { email, setEmail, generateOtp } = useContext(GenerateOtpContext);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      setErrors({ email: "مطلوب حقل البريد الإلكتروني." });
      return;
    }

    setErrors({});
    setIsLoading(true);
    try {
      await generateOtp(email, setErrors);
    } catch (error) {
      setErrors({ general: "Error Connection." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      dir="rtl"
      className="min-h-screen flex items-center justify-center bg-gray-100 selection:bg-red-100"
    >
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 text-slate-600 text-center">
          كود تحقق الايميل
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2">البريد الالكتروني</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
          </div>
          <button
            type="submit"
            className={`w-full bg-[#2a66b1] text-white p-2 rounded mt-4 ${
              isLoading ? "opacity-75 animate-fade" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? "جار ارسال كود التحقق..." : "طلب كود التحقق"}
          </button>
          <div className="mt-4 underline text-blue-400 flex justify-center text-md font-normal">
            <button onClick={() => navigate(-1)}>رجوع للخلف</button>
          </div>
        </form>
        {errors.general && (
          <p className="text-red-500 mt-4">{errors.general}</p>
        )}
        {errors.usererror && (
          <p className="text-red-500 mt-4">{errors.usererror}</p>
        )}
      </div>
    </div>
  );
};

export default RequestOtp;
