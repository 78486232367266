import React, { useEffect, useState } from "react";
import Select, { components, createFilter, MultiValue } from "react-select";
import axios from "axios";
// import { DeviceMasterContext } from "../../../context/devices/DeviceMasterContext";
import colourStyles from "./data/colourStyles";
import DeviceSelect from "./data/DeviceSelect";
import { FiDownload, FiRefreshCcw } from "react-icons/fi";
import { FaRegWindowClose } from "react-icons/fa";
import * as XLSX from "xlsx";
import ContactSelect from "./data/ContactsSelect";

interface PhoneOption {
  label: string;
  value: string;
  name?: string;
}

interface SendResult {
  number: string;
  status: "success" | "error";
  message: string;
  isLoadingr?: boolean;
}

interface FileDetails {
  name: string;
  size: number;
}

const MediaMessage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"contacts" | "options">(
    "contacts"
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const [phoneNumbers, setPhoneNumbers] = useState<PhoneOption[]>([]);
  const [caption, setCaption] = useState<string>("");
  const [mediaFile, setMediaFile] = useState<File | null>(null);
  const [successSend, setSuccessSend] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);
  const [deviceError, setDeviceError] = useState<string | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedContact, setSelectedContact] = useState<any>(null);

  const [sendResults, setSendResults] = useState<SendResult[]>([]);
  const [phoneOptions, setPhoneOptions] = useState<PhoneOption[]>([]);

  // const { fetchDeviceMaster } =DeviceMasterContext);

  const [fileDetails, setFileDetails] = useState<FileDetails>({
    name: "",
    size: 0,
  });

  const handleSendBulkMessage = async () => {
    resetErrors();
    if (!validateInputs()) return;

    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    try {
      if (phoneNumbers.length > 0) {
        await Promise.all(
          phoneNumbers.map(({ value, name }) => sendFileMessage(value, name))
        );
      }

      if (selectedContact && selectedContact.length > 0) {
        await Promise.all(
          selectedContact.map(async (id: string) => {
            return await sendFileMessage(id);
          })
        );
        // setSendResults(sendResults);
      }
      // fetchDeviceMaster();
    } catch (error) {
      console.log("Error updating send message:", error);
      setSendResults([
        { number: "", status: "error", message: "حدث خطأ أثناء إرسال الملف" },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async (number: string, index: number) => {
    setSendResults((prevResults) =>
      prevResults.map((result, i) =>
        i === index ? { ...result, isLoadingr: true, message: "" } : result
      )
    );
    const response = await sendFileMessage(number);
    setSendResults((prevResults) =>
      prevResults.map((result, i) =>
        i === index
          ? {
              ...result,
              isLoadingr: false,
              status: response.status,
              message: response.message,
            }
          : result
      )
    );
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        if (sheet) {
          const json = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          const phoneNumbers = json
            .map((row: any) => {
              const name = row[1]?.toString().trim();
              const phoneNumber = row[0]?.toString().trim();
              return phoneNumber
                ? {
                    label: `${name} (${phoneNumber})`,
                    value: phoneNumber,
                    name,
                  }
                : null;
            })
            .filter((item: any) => item !== null);

          setPhoneOptions(phoneNumbers as PhoneOption[]);
        } else {
          console.error("No sheet found in the workbook");
        }
      };

      setFileDetails({ name: file.name, size: file.size });

      reader.readAsArrayBuffer(file);
    }
  };

  const handleInputChange = (inputValue: string) => {
    const formattedNumber = inputValue.trim();
    if (
      formattedNumber &&
      /^[0-9]{12,}$/.test(formattedNumber) &&
      !phoneNumbers.some((phone) => phone.value === formattedNumber)
    ) {
      setPhoneNumbers((prevNumbers) => [
        ...prevNumbers,
        { label: formattedNumber, value: formattedNumber },
      ]);
    }
  };

  const handleSelectChange = (selected: MultiValue<PhoneOption>) => {
    setPhoneNumbers(selected as PhoneOption[]);
  };

  const handleCheckboxChange = (selected: boolean) => {
    setIsSelected(selected);
    if (selected) {
      setPhoneNumbers(phoneOptions);
    } else {
      setPhoneNumbers([]);
    }
  };

  const resetErrors = () => {
    setPhoneError(null);
    setMessageError(null);
    setDeviceError(null);
    setSendResults([]);
  };

  const clearMessages = () => setSendResults([]);

  const validateInputs = (): boolean => {
    if (!selectedDevice) {
      setDeviceError("يجب اختيار جهاز متصل.");
      return false;
    }
    if (
      phoneNumbers.length === 0 &&
      (!selectedContact || selectedContact.length === 0)
    ) {
      setPhoneError("يجب إدخال رقم هاتف أو اختيار مجموعة اتصال.");
      return false;
    }
    if (!mediaFile) {
      setMessageError("يجب تحديد ملف لإرساله.");
      return false;
    }
    return true;
  };

  const handleNumberChange = (index: number, newNumber: string) => {
    setSendResults((prevResults) =>
      prevResults.map((result, i) =>
        i === index ? { ...result, number: newNumber } : result
      )
    );
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setMediaFile(file);
      await fileUpload(file);
    }
  };

  const validselectfile = (): boolean => {
    if (!selectedDevice) {
      setDeviceError("يجب تحديد جهاز.");
      return false;
    }
    return true;
  };

  const fileUpload = async (file: File) => {
    resetErrors();
    if (!validselectfile()) return;
    setIsLoadingUpload(true);
    const formData = new FormData();
    formData.append("deviceToken", selectedDevice?.Token);
    formData.append("deviceId", selectedDevice?.deviceId);
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/saveUrl",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total!
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
      setFileUrl(response.data.url);
      // console.log(response.data);
    } catch (error) {
      console.log("Error uploading file:", error);
      // Handle the error message here
    } finally {
      setIsLoadingUpload(false);
    }
  };

  const sendFileMessage = async (
    number: string,
    name?: string
  ): Promise<SendResult> => {
    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1000);

    const formData = new FormData();
    formData.append("deviceToken", selectedDevice?.Token);
    formData.append("deviceId", selectedDevice?.deviceId);
    formData.append("message", `${caption}`);
    formData.append("number", number);
    formData.append("url", `${fileUrl}`);

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/SendMediaUrl",
        formData
      );

      console.log(response.data);
      // console.log(`${fileUrl}`);

      const result = getSendResult(response.data, number);
      setSuccessSend((await result).successSend);
      return (await result).sendResult;
    } catch (error) {
      console.log("Error sending file message:", error);
      return { number, status: "error", message: "فشل في إرسال الملف" };
    }
  };

  const getSendResult = async (data: any, number: string) => {
    let result: SendResult;
    let successSend: { text: string; type: "success" | "error" };

    if (data.status) {
      result = {
        number,
        status: "success",
        message: `تم إرسال الملف بنجاح `,
      };
      successSend = {
        text: `تمت عملية الإرسال `,
        type: "success",
      };
    } else if (data.errNum === "403") {
      result = {
        number,
        status: "error",
        message: `سيتم إيقاف خدمة هذا الجهاز حتى يتم تجديد الاشتراك`,
      };
      successSend = {
        text: `سيتم إيقاف خدمة هذا الجهاز حتى يتم تجديد الاشتراك`,
        type: "error",
      };
    } else if (data.errNum === "401") {
      result = {
        number,
        status: "error",
        message: `الرقم ${number} خارج الخدمة`,
      };
      successSend = { text: `الرقم ${number} خارج الخدمة`, type: "error" };
    } else {
      result = { number, status: "error", message: `فشل في إرسال الملف` };
      successSend = { text: `فشل في الإرسال `, type: "error" };
    }
    setSendResults((prevResults) => {
      const existingResultIndex = prevResults.findIndex(
        (r) => r.number === number
      );

      if (existingResultIndex !== -1) {
        return prevResults.map((r, i) =>
          i === existingResultIndex ? { ...r, ...result } : r
        );
      } else {
        return [...prevResults, result];
      }
    });

    return { sendResult: result, successSend };
  };

  useEffect(() => {
    if (successSend) {
      const timeout = setTimeout(() => setSuccessSend(null), 3000);
      return () => clearTimeout(timeout);
    }
  }, [successSend]);

  const isImage = (url: string | null): boolean => {
    return !!url?.match(/\.(jpeg|jpg|gif|png|webp|svg|bmp)$/i);
  };

  const customMenu = (props: any) => {
    const { innerRef, innerProps } = props;

    return (
      <div ref={innerRef} {...innerProps} className="react-select-menu">
        <div className="p-2">
          <button
            onClick={() => handleCheckboxChange(!isSelected)}
            className="flex items-center focus:outline-none"
          >
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => handleCheckboxChange(!isSelected)}
              className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
            />
            {/* <span className="ml-2  text-sm">
              {isSelected ? "إلغاء الكل" : "اختيار الكل"}
            </span> */}
          </button>
        </div>
        <components.Menu {...props} />
      </div>
    );
  };

  return (
    <div className="mt-8 flex justify-center gap-4 hide-scrollbar">
      {/* Notifications */}

      {sendResults.length > 0 && (
        <div>
          <div
            className="absolute top-16 right-8 w-auto p-4 bg-gray-700 text-white overflow-y-scroll scrollbar-light text-sm rounded-lg shadow-lg z-50 opacity-75"
            style={{ maxHeight: "30rem" }}
          >
            <button onClick={clearMessages} className="cursor-pointer">
              <FaRegWindowClose className="p-1 text-4xl text-blue-600" />
            </button>
            {sendResults.map((result, index) => (
              <div key={index} className="grid items-center justify-between">
                {result.status === "error" && (
                  <div className="flex items-center">
                    <input
                      type="text"
                      value={result.number}
                      onChange={(e) =>
                        handleNumberChange(index, e.target.value)
                      }
                      className="border border-gray-500 my-1 rounded focus:outline-none focus:ring-0 bg-gray-600 focus:ring-blue-500 px-2 py-1 text-sm text-white"
                    />
                    <button
                      className="ml-8 text-xs text-blue-500 cursor-pointer underline"
                      onClick={() => handleResend(result.number, index)}
                      disabled={result.isLoadingr}
                    >
                      {result.isLoadingr ? (
                        "إعادة ارسال..."
                      ) : (
                        <FiRefreshCcw className="p-1 text-4xl text-blue-400" />
                      )}
                    </button>
                  </div>
                )}
                <p
                  className={`${
                    result.status === "error"
                      ? "text-red-500"
                      : "text-green-500"
                  }`}
                >
                  {result.message}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="sm:w-10/12 mx-auto w-full">
        <div className="my-4">
          <button
            type="button"
            className={`inline-flex justify-end gap-2 px-6 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition ${
              isLoading ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={handleSendBulkMessage}
            disabled={isLoading || isLoadingUpload}
          >
            {isLoading ? "جاري الإرسال..." : "إرسال"}
          </button>
        </div>
        <div className="my-6 absolute top-12">
          {successSend && (
            <p
              className={`text-sm mt-1 firework-message ${
                successSend.type === "error" ? "error-message" : ""
              }`}
            >
              {successSend.text}
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </p>
          )}
        </div>

        <div className="mb-6">
          <DeviceSelect onDeviceSelect={setSelectedDevice} />
          {deviceError && <p className="text-red-500 text-sm">{deviceError}</p>}
        </div>

        {/* contacts */}

        <div className="mb-4">
          <div className="mb-4 flex gap-2 bg-gray-100 p-2 rounded-lg">
            <button
              onClick={() => setActiveTab("contacts")}
              className={`py-2 px-2 ${
                activeTab === "contacts"
                  ? "bg-white rounded-md text-blue-500"
                  : "text-gray-600 font-semibold"
              }`}
            >
              تحديد من جهات اتصال
            </button>
            <button
              onClick={() => setActiveTab("options")}
              className={`py-2 px-2 ${
                activeTab === "options"
                  ? "bg-white rounded-md text-blue-500"
                  : "text-gray-600 font-semibold"
              }`}
            >
              إدخال يدوي
            </button>
          </div>

          {activeTab === "contacts" ? (
            <div className="mb-4">
              <ContactSelect
                onContactSelect={setSelectedContact}
                selectedOptions={selectedContact}
                selectedDeviceId={selectedDevice?.deviceId}
              />
            </div>
          ) : (
            <div className="mb-6">
              <div className="items-left w-3xl mb-6">
                <label
                  htmlFor="fileInput"
                  className="flex items-left px-2 py-2 rounded-md border border-gray-400 cursor-pointer"
                >
                  <span className="mr-4">
                    {fileDetails.name ? (
                      <span className="bg-slate-200 p-[2px] rounded-full">
                        {fileDetails.name} (
                        {(fileDetails.size / 1024).toFixed(2)} KB)
                      </span>
                    ) : (
                      <span className="bg-slate-100 p-[2px] rounded-full">
                        اختار ملف جهات اتصال
                      </span>
                    )}
                  </span>
                  <input
                    type="file"
                    onChange={handleFileUpload}
                    id="fileInput"
                    name="fileInput"
                    className="hidden"
                    accept=".xlsx, .xls"
                  />
                </label>
                <div className="flex gap-8 mb-4">
                  <a
                    href="/contacts.xlsx"
                    className="flex items-center"
                    download="contacts.xlsx"
                  >
                    <span className="inline-flex mt-4 text-blue-400 text-sm items-center gap-2">
                      <span>XLSX تنزيل نموذج</span>{" "}
                      <FiDownload className="mt-1" />
                    </span>
                  </a>
                </div>
              </div>

              <Select
                isMulti
                options={phoneOptions}
                value={phoneNumbers}
                onChange={handleSelectChange}
                onInputChange={handleInputChange}
                filterOption={createFilter({ ignoreAccents: false })}
                className="react-select-container"
                placeholder="...,967772423450 اختار جهات الارسال أو أدخل رقم"
                classNamePrefix="react-select"
                styles={colourStyles}
                closeMenuOnSelect={false}
                components={{ DropdownIndicator: customMenu }}
              />
            </div>
          )}
        </div>

        {/* end contacts */}
        <div>
          {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
        </div>
        <div className="mt-6">
          <div className="sm:flex justify-between grid-cols-1 gap-2">
            <label className="flex-inline">
              <input
                type="file"
                accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.rtf,.json,.xml,.html,.css,.js,.zip,.rar,.tar,.gz,.7z,.bmp,.tiff,.webp,.svg,.mpg,.mpeg,.avi,.mov,.wmv,.flv,.mkv,.ogg,.wav,.aac,.flac,.m4a,.epub,.md,.yaml,.yml,.psd,.ai,.indd"
                onChange={handleFileChange}
                className="block w-auto mb-4 text-sm text-slate-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-violet-50 file:text-violet-700
            hover:file:bg-violet-100
          "
              />
            </label>
            {isImage(fileUrl) ? (
              <img
                className="h-16 object-cover"
                src={fileUrl || undefined}
                alt="Uploaded content"
              />
            ) : (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={fileUrl || undefined}
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            )}
            {isLoadingUpload && (
              <div className="flex gap-4 w-[20rem] items-center">
                <div className="w-[20rem] bg-gray-200 rounded-full h-1">
                  <div
                    className="bg-blue-600 h-1 rounded-full"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
                <span className="ml-4 text-sm text-gray-700">
                  {uploadProgress}%
                </span>
              </div>
            )}
          </div>
          {messageError && (
            <p className="text-red-500 text-sm">{messageError}</p>
          )}
        </div>

        <div className="my-6">
          <div className="grid text-sm after:px-3.5 after:py-2.5 [&>textarea]:text-inherit after:text-inherit [&>textarea]:resize-none [&>textarea]:overflow-hidden [&>textarea]:[grid-area:1/1/2/2] after:[grid-area:1/1/2/2] after:whitespace-pre-wrap after:invisible after:content-[attr(data-cloned-val)_'_'] after:border">
            <textarea
              className="w-full text-slate-600 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded px-3.5 py-2.5 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
              name="message"
              id="message"
              placeholder="وصف الملف..."
              rows={3}
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaMessage;
