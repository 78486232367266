import React, { useContext, useState } from "react";
import { DeviceOrderCotext } from "../../../context/devices/DeviceOrederContext";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import axios from "axios";
import DeviceSelectOrder from "../messages/data/DeviceSelectOrder";
import PeroidSelectOrder from "../messages/data/PeroidSelectOrder";
import { PlanContext } from "../../../context/devices/PlanContext";

const OrderSubscripPage: React.FC = () => {
  const { fetchOrderMaster } = useContext(DeviceOrderCotext);
  const { token } = useContext(AuthContext);
  const [message, setMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);
  const [deviceError, setDeviceError] = useState<string | null>(null);
  const [peroidError, setPeroidError] = useState<string | null>(null);
  const [selectedPeroidSub, setSelectedPeroidSub] = useState<any>(null);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);

  const { planData } = useContext(PlanContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { planId } = useParams<{ planId: string }>();

  const selectedPlan = planId
    ? planData?.data?.find((plan: any) => plan.id === parseInt(planId))
    : undefined;

  const calculateTotalPrice = (): number => {
    if (!selectedPlan || !selectedPeroidSub) return 0;

    // Ensure that price is a string before using replace
    const priceString = selectedPlan.price ? String(selectedPlan.price) : "0";

    // Remove "$" and convert to a floating-point number
    const price = parseFloat(priceString.replace("$", ""));

    // If price is not a number, return 0, otherwise calculate the total price
    return isNaN(price) ? 0 : price * (selectedPeroidSub.month || 1);
  };

  const handleUpdateChange = async () => {
    setDeviceError(null);
    setPeroidError(null);

    if (!selectedDevice) {
      setDeviceError(".يجب تحديد الجهاز");
      return;
    }
    if (!selectedPeroidSub) {
      setPeroidError(".يجب مدة الاشتراك");
      return;
    }

    setIsLoading(true);
    setMessage(null);

    const formData = new FormData();
    formData.append("plan_id", planId || "");
    formData.append("Periode_id", selectedPeroidSub.id);
    formData.append("deviceToken", selectedDevice.Token);
    formData.append("deviceId", selectedDevice.deviceId);
    formData.append("amount", "1");
    formData.append("currency", "usd");

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/CreateOrderDevice",
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      if (response.data.status === true) {
        fetchOrderMaster();
        setMessage({
          text: "سيتم معالجة طلبك. سيتم ارسال طلب تجديد الباقة",
          type: "success",
        });
        navigate("/orders");
      } else if (response.data.errNum === "400") {
        setMessage({
          text: "لقد تم تجديد اشتراكك مسبقا",
          type: "success",
        });
      } else {
        setMessage({ text: "Failed to create order.", type: "error" });
      }
    } catch (error) {
      setMessage({
        text: "An error occurred. Internal Server Error 500.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-8 mt-16 border border-gray-300 rounded-md">
      <div className="flex justify-between">
        <div></div>
        <div className="my-4 justify-end text-gray-700 text-lg font-bold space-x-4">
          <span>ألإجمالي:</span>{" "}
          <span className="text-blue-500 font-bold">
            {calculateTotalPrice().toFixed(2)}$
          </span>
          دولار
        </div>
      </div>
      <div className="border-t border-gray-300 my-4"></div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            الجهاز
          </label>
          <DeviceSelectOrder onDeviceSelectOrder={setSelectedDevice} />
          {deviceError && (
            <p className="text-red-500 mt-2 text-sm">{deviceError}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            مدة الاشتراك
          </label>
          <PeroidSelectOrder onPeroidSelectOrder={setSelectedPeroidSub} />
          {peroidError && (
            <p className="text-red-500 mt-2 text-sm">{peroidError}</p>
          )}
        </div>
      </div>

      <div className="mt-6">
        {message && (
          <div
            className={`p-4 rounded ${
              message.type === "success"
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            }`}
          >
            {message.text}
          </div>
        )}
      </div>

      <div className="mt-6 flex justify-end">
        <button
          onClick={handleUpdateChange}
          className={`inline-flex gap-2 px-4 py-2 mt-6 border-gray-400 text-sm font-medium text-gray-100 ring-blue-100 bg-[#26b3ef] border border-transparent rounded-md hover:bg-[#3dc5ff] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-100 ${
            isLoading ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={isLoading}
        >
          {isLoading ? "تأكيد..." : "تأكيد"}
        </button>
      </div>
    </div>
  );
};

export default OrderSubscripPage;
