import React, { useContext } from "react";
import { MessageApiContext } from "../../../../context/messages/MessageApiContext";

interface CardProps {
  icon: JSX.Element;
  value: string;
  label: string;
}

const Card: React.FC<CardProps> = ({ icon, value, label }) => {
  const context = useContext(MessageApiContext);
  if (!context) {
    throw new Error(
      "MessageApiContext must be used within a MessageApiProvider"
    );
  }

  const { loadingm } = context;
  return (
    <div
      dir="rtl"
      className={`${
        loadingm
          ? "animate-pulse p-6 bg-slate-200 rounded-lg"
          : "bg-white rounded-lg p-6 cursor-pointer flex flex-col border border-stroke  shadow-sm"
      }`}
    >
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h2
            className={`${
              loadingm
                ? "animate-pulse bg-slate-300 text-xl font-extrabold text-slate-300"
                : "text-xl font-extrabold text-gray-700 "
            }`}
          >
            {value}
          </h2>
          <p
            className={`${
              loadingm
                ? "animate-pulse bg-slate-300 w-full rounded-full text-slate-300"
                : "text-md text-gray-500"
            }`}
          >
            {label}
          </p>
        </div>
        <div
          className={`${
            loadingm
              ? "animate-pulse bg-slate-300 text-slate-300 p-3 rounded-full shadow-inner"
              : "bg-slate-100 text-indigo-500 p-3 rounded-full shadow-inner"
          }`}
        >
          {icon}
        </div>
      </div>
    </div>
  );
};

export default Card;
