// src/components/MediaComponent.tsx
import React from "react";
import MediaMessage from "./MediaMessage";
import { AiFillFileImage } from "react-icons/ai";

const MediaComponent: React.FC = () => {
  return (
    <div className="justify-start container mt-14">
      <div className="flex justify-start gap-3 sm:w-10/12 mx-auto text-lg mt-8">
      <AiFillFileImage size={25} className="text-cyan-600" />
      <span className="">ارسال رسائل ملفات</span>
      </div>
      <MediaMessage />
    </div>
  );
};

export default MediaComponent;
