import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
} from "react";
import { AuthContext } from "../AuthContext";
import axios from "axios";

interface Contacts {
  pkId: number;
  sessionId: string;
  id: number;
  name: string;
  notify: string | null;
  verifiedName: string | null;
  imgUrl: string | null;
  status: string | null;
  to: string | null;
  from: string | null;
}

interface Schedule {
  id: string;
  user_id: number;
  device_id: string;
  msgId: string;
  from: string | null;
  to: string;
  type: string;
  message: string;
  send_type: string;
  status: string;
  created_at: string;
  updated_at: string;
  contacts?: Contacts | Contacts[] | null;
}

interface Filters {
  status: string;
  type: string;
  to_number: string;
  from_date: string;
  to_date: string;
  Search: string;
}

interface MessageData {
  [x: string]: any;
  current_page: string | null;
  totalMessege: string | null;
  DeliveredMessege: string | null;
  failedMessege: string | null;
  Messages: Schedule[];
}

interface MessageApiContextType {
  Messages: MessageData | null;
  MessagesData: MessageData | null;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  filters: Filters;
  loadingm: boolean;
  error: string | null;
  fetchMessages: () => Promise<void> | null;
  // fetchMessages: () => Promise<void> | null;
  setLoadingS: React.Dispatch<React.SetStateAction<boolean>>;
  fetchMessageById: (id: string) => Promise<void> | null;
}

interface MessageApiProviderProps {
  children: ReactNode;
}

export const MessageApiContext = createContext<
  MessageApiContextType | undefined
>(undefined);

const MessageApiProvider: React.FC<MessageApiProviderProps> = ({
  children,
}) => {
  const [Messages, setMessages] = useState<MessageData | null>(null);
  const [MessagesData, setMessagesData] = useState<MessageData | null>(null);
  const [loadingm, setLoadingS] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { token } = useContext(AuthContext);
  const [filters, setFilters] = useState<Filters>({
    status: "",
    type: "",
    to_number: "",
    from_date: "",
    to_date: "",
    Search: "",
  });
  const fetchMessages = useCallback(async () => {
    if (!token) {
      setLoadingS(false);
      return;
    }

    setLoadingS(true);
    const url =
      "https://click.one.sky4system.com/api/user/MesssageTransactions";

    const formData = new FormData();
    if (filters.status) {
      formData.append("status", filters.status);
    }

    if (filters.type) {
      formData.append("type", filters.type);
    }

    if (filters.to_number) {
      formData.append("to_number", filters.to_number);
    }

    if (filters.from_date) {
      formData.append("from_date", filters.from_date);
    }

    if (filters.to_date) {
      formData.append("to_date", filters.to_date);
    }

    if (filters.Search) {
      formData.append("Search", filters.Search);
    }

    const headers: HeadersInit = {};
    if (token) {
      headers["auth-token"] = token;
    }

    try {
      const response = await axios.post(url, formData, {
        headers: {
          ...(token ? { "auth-token": token } : {}),
        },
        params: {
          token,
        },
      });

      if (!response) {
        throw new Error(`HTTP error! status: ${response}`);
      }

      const data = await response.data;
      // console.log(data);
      if (data.status === true) {
        setMessages(data.data.Messege);
        setMessagesData(data.data);
      } else {
        setError(data.msg || "Failed to fetch Messages");
      }
    } catch (err) {
      setError(
        "An error occurred while fetching the Messages,Internal Server Error 500."
      );
    } finally {
      setLoadingS(false);
    }
  }, [token, filters]);

  const fetchMessageById = useCallback(
    async (id: string) => {
      const url = "https://click.one.sky4system.com/api/user/Get-Messagebyid";
      const headers: HeadersInit = {};
      const formData = new FormData();
      formData.append("id", id);

      if (token) {
        headers["auth-token"] = token;
      }

      try {
        const response = await axios.post(url, formData, {
          headers: {
            ...(token ? { "auth-token": token } : {}),
          },
          // params: {
          //   token,
          // },
        });

        if (!response) {
          throw new Error(`HTTP error! status: ${response}`);
        }

        const data = await response.data;
        // console.log(data);

        if (data.status === true && data.data.length > 0) {
          return data.data[0];
        } else {
          setError(data.msg || "Failed to fetch the schedule by ID");
          return null;
        }
      } catch (err) {
        setError("An error occurred while fetching the schedule by ID");
        return null;
      }
    },
    [token]
  );

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  return (
    <MessageApiContext.Provider
      value={{
        Messages,
        MessagesData,
        loadingm,
        error,
        setFilters,
        filters,
        fetchMessageById,
        setLoadingS,
        fetchMessages,
      }}
    >
      {children}
    </MessageApiContext.Provider>
  );
};

export default MessageApiProvider;
