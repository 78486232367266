import React, { useContext, useEffect, useState } from "react";
import { FaTrash, FaSpinner, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { AiOutlineQrcode, AiOutlineShoppingCart } from "react-icons/ai";

import { Link, useNavigate, useParams } from "react-router-dom";
import { CiMenuKebab } from "react-icons/ci";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { FiSettings } from "react-icons/fi";
import { LuRefreshCcw } from "react-icons/lu";
import { AuthContext } from "../../../../context/AuthContext";
import { DeviceMasterContext } from "../../../../context/devices/DeviceMasterContext";
// import ParentCeatDevice from "../../devices/ParentCeatDevice";
import {
  handleGetDevice,
  handleOpen,
  handleUpdateChangeDevice,
} from "../../../../api/helpersqr";
import Notification from "../../../notifications/Notification";
import ConfirmDialog from "../../devices/ConfirmDialog";
import QRCodeModal from "../../devices/ConfirmDialogQrCode";
import { handleDeleteDevice } from "../../../../api/helpers";
import { IoMdArrowForward } from "react-icons/io";
const DeviceMaster: React.FC = () => {
  const { master_user_id } = useParams<{ master_user_id: string }>();
  // const masterUserIdNumber = Number(master_user_id);
  const { token } = useContext(AuthContext);
  const { deviceData, fetchDeviceMaster, loading } =
    useContext(DeviceMasterContext);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDialogOpend, setIsDialogOpend] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingC, setIsLoadingC] = useState(false);
  const [qrCodeData, setQrCodeData] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  // console.log(master_user_id)
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    name: "",
    phone: "",
  });

  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  const handleDeleteClick = (device: any) => {
    setSelectedDevice(device);
    setIsDialogOpen(true);
  };

  const handleGetQrClick = async (device: any) => {
    setSelectedDevice(device);
    await handleOpen(device, setSelectedDevice);
    setIsDialogOpend(true);
  };
  const sortDevicesByDate = (data: any[]) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);
      return dateB.getTime() - dateA.getTime();
    });
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const applyFilters = (data: any[]) => {
    return data.filter((device) => {
      const matchesName = device.name
        .toLowerCase()
        .includes(filters.name.toLowerCase());

      return matchesName;
    });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value);
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  const sortedDevice = sortDevicesByDate(deviceData?.data ?? []);
  const totalPages = Math.ceil(sortedDevice.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredDevice = applyFilters(sortedDevice);
  const currentDevice = filteredDevice.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => setNotification(null), 3000);
      return () => clearTimeout(timeout);
    }
  }, [notification]);

  // const filteredDevice = applyFilters(
  //   sortDevicesByDate(
  //     deviceData?.data.filter(
  //       (device: any) => String(device.id_users) === master_user_id
  //     ) ?? []
  //   )
  // );
  return (
    <div className="px-4 my-4 overflow-auto">
      {/* <TimeNotification /> */}

      <div className="bg-white rounded-sm shadow-sm hide-scrollbar max-h-screen px-0 overflow-x-auto scrollbar-light">
        {/* Desktop Table */}
        <div className="" dir="rtl">
          {/* <div className="flex justify-start">
            <ParentCeatDevice />
          </div> */}
          <button onClick={() => navigate(-1)} className="flex-1">
            <span>
              <IoMdArrowForward className="w-6 h-6 mr-2" />
            </span>
          </button>
          <div className="flex">
            <div className="flex flex-col lg:flex-row gap-4 mb-4">
              <input
                type="text"
                name="name"
                placeholder="اسم الجهاز"
                value={filters.name}
                onChange={handleFilterChange}
                className="p-2 mt-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300 transition duration-300 ease-in-out transform hover:scale-105"
              />
            </div>
            <button
              onClick={fetchDeviceMaster}
              className={`${loading ? "cursor-not-allowed opacity-50" : ""}`}
              disabled={loading}
              type="button"
            >
              <LuRefreshCcw className="text-blue-500 mx-4" size={25} />
            </button>
          </div>

          <div className="overflow-x-auto hide-scrollbar">
            <table className="min-w-full max-h-screen divide-y divide-gray-200">
              <thead className="bg-white border-b-[1px] border-gray-300">
                <tr>
                  <th className="px-4 py-4 font-bold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex">رقم الجهاز</div>
                  </th>
                  <th className="px-4 py-4 font-bold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex">اسم الجهاز</div>
                  </th>
                  <th className="px-4 py-4 font-bold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex">رقم الهاتف</div>
                  </th>
                  <th className="px-4 py-4 font-bold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex">حالة الاتصال</div>
                  </th>
                  <th className="px-4 py-4 font-bold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex">حالة الجهاز</div>
                  </th>
                  <th className="px-4 py-4 font-bold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex">تاريخ الانتهاء</div>
                  </th>
                  <th className="px-2 py-4 font-bold text-right text-xs text-gray-500 uppercase tracking-wider">
                    عمليات
                  </th>
                </tr>
              </thead>

              {deviceData ? (
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentDevice.map((device: any) => (
                    <tr key={device.id}>
                      {/* {master_user_id} | {device.id_users} */}
                      {String(device.id_users) === master_user_id && (
                        <>
                          <td className="px-4 py-4 whitespace-nowrap text-xs text-gray-500">
                            {device.deviceId}
                          </td>
                          <td
                            title={`تفاصيل جهاز ${device.name}`}
                            className="px-4 py-4 whitespace-nowrap text-xs text-gray-500"
                          >
                            {device.name}
                          </td>
                          <td className="px-4 py-4 whitespace-nowrap text-xs text-gray-500">
                            {device.number}
                          </td>
                          <td className="px-4 py-4 whitespace-nowrap text-xs">
                            <span
                              className={`inline-flex px-2 py-1 text-xs font-semibold leading-5 rounded-full ${
                                device.status === "AUTHENTICATED"
                                  ? "bg-blue-100 text-blue-800"
                                  : "bg-red-100 text-red-800"
                              }`}
                            >
                              {device.status === "AUTHENTICATED"
                                ? "متصل"
                                : "غير متصل"}
                            </span>
                          </td>
                          <td className="px-4 py-4 whitespace-nowrap text-xs">
                            <span
                              className={`inline-flex px-2 py-1 text-xs font-semibold leading-5 rounded-full ${
                                device.payment_status === 1
                                  ? "bg-blue-100 text-blue-800"
                                  : "bg-red-100 text-red-800"
                              }`}
                            >
                              {device.payment_status === 1
                                ? "فعال"
                                : "غير فعال"}
                            </span>
                          </td>
                          <td className="px-4 py-4 whitespace-nowrap text-xs text-gray-500">
                            {" "}
                            {new Date(device.ExpiredDate) < new Date() ? (
                              <Link to={"/promotions"}>
                                <span className="bg-red-200 p-1 cursor-pointer rounded-lg text-red-800">
                                  تجديد الاشتراك
                                </span>{" "}
                              </Link>
                            ) : (
                              device.ExpiredDate
                            )}
                          </td>
                          <td className="flex gap-2">
                            {new Date(device.ExpiredDate) < new Date() ? (
                              <div className="px-2 py-3 whitespace-nowrap text-right text-xs font-medium">
                                <Link to={"/promotions"}>
                                  <span className=" p-1 cursor-pointer rounded-lg text-red-800">
                                    <AiOutlineShoppingCart size={30} />
                                  </span>{" "}
                                </Link>
                              </div>
                            ) : (
                              <div className="px-2 py-3 whitespace-nowrap text-right text-xs font-medium">
                                {master_user_id === device.id_users || (
                                  <div>
                                    <button
                                      onClick={() => handleGetQrClick(device)}
                                      className="text-gray-700 hover:text-gray-800 flex items-center justify-center"
                                      title="إنشاء رمز مصادقة WhatsApp"
                                    >
                                      <AiOutlineQrcode className="h-8 w-full cursor-pointer" />
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </td>
                          <td>
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <div>
                                <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-full px-2 py-2 text-xs font-semibold text-gray-900  hover:bg-gray-50">
                                  <CiMenuKebab size={20} />
                                </MenuButton>
                              </div>

                              <MenuItems
                                transition
                                className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                              >
                                <div className="py-1">
                                  <MenuItem>
                                    <div className="block px-3 py-2 text-xs text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                      <div className="px-2 whitespace-nowrap text-right text-xs font-medium">
                                        <Link
                                          title={` ${device.name}`}
                                          className="underline text-blue-400 flex gap-2 items-center justify-start"
                                          to={`/user-device-settings/${device.id_users}/${device.deviceId}`}
                                        >
                                          <FiSettings size={25} /> الإعدادات
                                        </Link>
                                      </div>
                                    </div>
                                  </MenuItem>
                                  <div className="border-b border-gray-300 my-1"></div>
                                  <MenuItem>
                                    <div className="block px-3 py-2 text-xs text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                      <div className="px-2 whitespace-nowrap text-right text-xs font-medium">
                                        <button
                                          onClick={() =>
                                            handleDeleteClick(device)
                                          }
                                          className="text-red-400 hover:text-red-500 flex items-center gap-2 justify-center"
                                          title=""
                                        >
                                          <FaTrash className="h-5 w-5 cursor-pointer" />{" "}
                                          حذف الجهاز
                                        </button>
                                      </div>
                                    </div>
                                  </MenuItem>
                                </div>
                              </MenuItems>
                            </Menu>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="max-h-screen">
                  <tr>
                    <td colSpan={7} className="text-center py-4">
                      <div className="flex justify-center items-center">
                        <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>

            <div className="flex justify-between items-center p-4">
              <div className="flex items-center gap-2">
                <label htmlFor="itemsPerPage" className="text-gray-500">
                  الصفوف بكل صفحة:
                </label>
                <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  className="text-xs text-gray-900 border focus:outline-none border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-300 focus:border-gray-300 p-1 dark:focus:border-gray-400"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={filteredDevice.length}>الكل</option>
                </select>
              </div>
              <div className="flex justify-center gap-4 items-center p-4">
                <button
                  onClick={handlePreviousPage}
                  className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                  disabled={currentPage === 1}
                >
                  <FaArrowLeft />
                </button>
                <span className="text-gray-500">
                  صفحة {currentPage} من {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                  disabled={currentPage === totalPages}
                >
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
          {/* Pagination */}
        </div>
      </div>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      {isDialogOpen && (
        <ConfirmDialog
          isOpenn={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onConfirm={() =>
            handleDeleteDevice(
              selectedDevice,
              token,
              fetchDeviceMaster,
              setIsLoading,
              setNotification,
              setIsDialogOpen,
              setSelectedDevice
            )
          }
          isLoading={isLoading}
          title="تأكيد الحذف"
          message="هل أنت متأكد أنك تريد حذف هذا الجهاز؟"
        />
      )}
      {isDialogOpend && (
        <QRCodeModal
          onConfirm={() =>
            handleGetDevice(
              selectedDevice,
              token,
              fetchDeviceMaster,
              setIsLoading,
              setIsDialogOpend,
              setSelectedDevice,
              setQrCodeData,
              setNotification
            )
          }
          onDisconnect={() =>
            handleUpdateChangeDevice(
              selectedDevice,
              token,
              setIsDialogOpend,
              setSelectedDevice, // Correct order
              setIsLoadingC,
              setNotification,
              fetchDeviceMaster
            )
          }
          onCancel={() => setIsDialogOpend(false)}
          isLoading={isLoading}
          isLoadingC={isLoadingC}
          qrCodeData={qrCodeData}
          selectedDevice={selectedDevice}
        />
      )}
    </div>
  );
};

export default DeviceMaster;
