// VerifyUserContext.tsx
import React, { createContext, ReactNode } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface VerifyUserContextType {
  verifyEmailCode: (
    email: string,
    otp: string,
    navigate: ReturnType<typeof useNavigate>,
    setErrors: (errors: Record<string, string>) => void
  ) => Promise<void>;
}

export const VerifyUserContext = createContext<VerifyUserContextType>({
  verifyEmailCode: async () => {},
});

const VerifyUserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const verifyEmailCode = async (
    email: string,
    otp: string,
    navigate: ReturnType<typeof useNavigate>,
    setErrors: (errors: Record<string, string>) => void
  ) => {
    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/confirm_email_by_otp",
        { email, otp }
      );
      console.log(response.data);
      if (response.data.status === true) {
        localStorage.removeItem("email");
        navigate("/login");
      }
      if (response.data.errNum === "E040") {
        setErrors({ usererror: "عنوان البريد الاكتروني غير موجود" });
      } else if (response.data.errNum === "E050") {
        setErrors({ otprerror: "الكود الذي ادخلته غير صالح" });
      } 
      else if (response.data.errNum === "502") {
        setErrors({ waitotp: response.data.msg });
      } else {
        setErrors({ general: response.data.msg });
      }
    } catch (error) {
      setErrors({ general: "An error occurred. Please try again later." });
    }
  };

  return (
    <VerifyUserContext.Provider value={{ verifyEmailCode }}>
      {children}
    </VerifyUserContext.Provider>
  );
};

export default VerifyUserProvider;
