import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { DeviceMasterContext } from "../../../../context/devices/DeviceMasterContext";
import { AuthContext } from "../../../../context/AuthContext";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";
import { Device } from "../../../../interfaces/Device";
import SwitchToggle from "../settings/SwitchToggle";

const DevicedetailsWH: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [Devicedetails, setDevicedetails] = useState<Device | undefined>(
    undefined
  );
  const [message, setMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);
  const [isSwitchLoading, setIsSwitchLoading] = useState<boolean>(false);
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(false);
  // const [webhookstate, setWebhookstate] = useState("");

  const { deviceData, fetchDeviceMaster } = useContext(DeviceMasterContext);
  const { token } = useContext(AuthContext);

  const fetchDevicedetails = useCallback(async () => {
    setIsLoading(true);
    try {
      if (deviceData && deviceData.data) {
        const foundDevice = deviceData.data.find(
          (device: Device) => device.deviceId === deviceId
        );
        if (foundDevice) {
          setDevicedetails(foundDevice);
          setIsSwitchChecked(foundDevice.hook_status);
        } else {
          console.warn("Device not found in deviceData.");
        }
      } else {
        console.warn("deviceData or deviceData.data is undefined.");
      }
    } catch (error) {
      console.log("Error fetching device details:", error);
    } finally {
      setIsLoading(false);
    }
  }, [deviceId, deviceData]);

  useEffect(() => {
    fetchDevicedetails();
  }, [fetchDevicedetails]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (message) {
      timeout = setTimeout(() => setMessage(null), 1000);
    }
    return () => clearTimeout(timeout);
  }, [message]);

  const handleSwitchChange = async (checked: boolean) => {
    if (deviceData && deviceData.data) {
      const foundDevice = deviceData.data.find(
        (device: Device) => device.deviceId === deviceId
      );
      if (foundDevice) {
        if (Devicedetails) {
          setIsSwitchLoading(true);
          try {
            const response = await axios.post(
              "https://click.one.sky4system.com/api/user/updatewebhookStatus",
              {
                deviceToken: Devicedetails.Token,
                deviceId: Devicedetails.deviceId,
                webhook_status: checked ? "1" : "0",
              },
              {
                headers: {
                  "auth-token": token,
                },
              }
            );

            // console.log(response.data);
            // setWebhookstate(response.data);
            if (response.data.status === true) {
              setIsSwitchChecked(response.data.data.hook_status === "1");
              if (response.data.data.hook_status === "1") {
                setMessage({
                  text: ".تم تفعيل الويب هوك بنجاح  ",
                  type: "success",
                });
              } else {
                setMessage({
                  text: ".تم إلغاء تفعيل الويب هوك بنجاح  ",
                  type: "success",
                });
              }
              // console.log(response.data.data);
              fetchDeviceMaster();
            } else {
              setMessage({
                text: ".يرجى ادخال عنوان الويب هوك",
                type: "error",
              });
            }
            await fetchDevicedetails();
            return response.data;
          } catch (error) {
            console.error("Error updating WebHook Status:", error);
          } finally {
            setIsSwitchLoading(false);
          }
        }
      }
    }
  };

  return (
    <div className="p-4">
      {isLoading ? (
        <div></div>
      ) : (
        <div>
          {Devicedetails ? (
            <div>
              <div className="flex justify-between">
                <div>
                  <SwitchToggle
                    initialChecked={isSwitchChecked}
                    onChange={handleSwitchChange}
                    disabled={isSwitchLoading}
                  />
                </div>
                <div className="text-lg font-bold text-gray-700">
                  <span>تفعيل الويب هوك</span>
                </div>
              </div>
              <div>
                {message && (
                  <p
                    className={`text-sm mt-1 firework-message ${
                      message.type === "error" ? "error-message" : ""
                    }`}
                  >
                    {message.text}
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="text-center py-4">
              <div className="flex justify-center items-center">
                <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DevicedetailsWH;
