import React from 'react';
import { FaArrowLeft, FaStar, FaShieldAlt, FaWhatsapp } from 'react-icons/fa';
import { TbApi } from "react-icons/tb";

import { motion } from 'framer-motion';

const FeaturesSection: React.FC = () => {
  return (
    <section className="dark:bg-gray-900 bg-slate-100 text-gray-700 dark:text-gray-300 py-12 px-4 sm:px-6 lg:px-8" dir='rtl'>
      <div className="container mx-auto">
        <motion.h2
          className="text-3xl font-bold mb-8 text-center flex items-center justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <FaStar className="text-amber-600 mr-3" />
          خدماتنا
        </motion.h2>
        <motion.p
          className="text-lg mb-8 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          بفضل واجهة سهلة الاستخدام، يمكنك تصميم حملات ترويجية وإعلامية تلبي احتياجاتك بدقة. اختر أنسب الأدوات لتوصيل رسائلك بشكل فعال، سواء كانت نصية أو متعددة الوسائط، واستمتع بتجربة تسويقية شاملة تُعزز من وصولك وتأثيرك في السوق.
        </motion.p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            {
              icon: <TbApi className="text-[#3a74ba] h-10 w-10 ml-2" />,
              title: "ربط ال API",
              items: [
                "اربط نظامك بمنصتنا بسهولة، مهما كانت لغة البرمجة التي تعتمدها، وابدأ بإرسال التنبيهات والإشعارات إلى عملائك عبر الرسائل القصيرة من خلال API المتخصصة لدينا.",
                "دعم متعدد اللغات: تكامل سلس مع نظامك أيًا كانت لغة البرمجة المستخدمة.",
                "إشعارات فورية: أرسل التنبيهات والإشعارات بسرعة وكفاءة لعملائك عبر الرسائل القصيرة",
                "سهولة الاستخدام: واجهة برمجة تطبيقات (API) مخصصة لتلبية احتياجاتك بسهولة وفعالية"
              ]
            },
            {
              icon: <FaWhatsapp className="text-[#3a74ba] ml-2 h-10 w-10" />,
              title: "الرسائل النصية",
              items: [
                "واجهة برمجة تطبيقات واتساب للأعمال",
                "توفير تجربة تواصل استثنائية مع عملائك، تضمن لك تلبية احتياجاتهم بسرعة وسهولة."
              ]
            },
            {
              icon: <FaShieldAlt className="text-[#3a74ba] ml-2 h-10 w-10" />,
              title: "الأمان والحماية",
              items: [
                "نظام أمان متقدم لحماية بياناتك وخصوصيتك.",
                "تشفير البيانات وحمايتها من الوصول غير المصرح به.",
                "رصد ومراقبة متقدمة لاكتشاف التهديدات الأمنية والتعامل معها بسرعة."
              ]
            }
          ].map((feature, index) => (
            <motion.div
              key={index}
              className="dark:bg-gray-800 bg-white text-gray-700 dark:text-gray-300  rounded-3xl shadow-lg p-6"
              initial={{ opacity: 0, scale: 0.95, rotate: 0 }}
              whileInView={{ opacity: 1, scale: 1, rotate: 0 }}
              whileHover={{ scale: 1.02, rotate: 3, boxShadow: '0 4px 20px rgba(0,0,0,0.3)' }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <h3 className="text-2xl font-semibold mb-4 flex items-center">
                {feature.icon}
                {feature.title}
              </h3>
              <ul className="space-y-4">
                {feature.items.map((item, i) => (
                  <motion.li
                    key={i}
                    className="flex items-start"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: i * 0.2 }}
                  >
                    <FaArrowLeft className="text-[#3a74ba] ml-2" />
                    <span>{item}</span>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
