import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
} from "react";
import axios from "axios";
import { AuthContext } from "../AuthContext"; // Import AuthContext for token

interface UserAgenBalan {
  id: number;
  AgentUserName: string;
  UserName: string;
  currency: string;
  openbalance: string;
  debit_amount: string;
  credit_amount: string;
  debit1: string;
  credit1: string;
  closebalance: string;
  closebalancelocle: string;
  currency_name: string;
}

interface UserAgenBalanApiResponse {
  status: boolean;
  errNum: string;
  msg: string;
  data: {
    UsersAgentbalance: UserAgenBalan[];
  };
}

interface Filters {
  currency: string;
  name: string;
}

interface UserAgenBalanContextType {
  UsersAgentbalance: UserAgenBalan[] | null;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  loading: boolean;
  error: string | null;
  fetchUserAgenBalans: () => Promise<void>;
}

interface UserAgenBalanProviderProps {
  children: ReactNode;
}

export const UserAgenBalanContext = createContext<UserAgenBalanContextType | undefined>(undefined);

const UserAgenBalanProvider: React.FC<UserAgenBalanProviderProps> = ({ children }) => {
  const [UsersAgentbalance, setUserAgenBalan] = useState<UserAgenBalan[] | null>(null);
  const [filters, setFilters] = useState<Filters>({
    currency: "",
    name: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Get token from AuthContext
  const { token } = useContext(AuthContext);

  const fetchUserAgenBalans = useCallback(async () => {
    setLoading(true);
    const url = "https://click.one.sky4system.com/api/user/Setting/UsersAgentbalance";

    const headers: HeadersInit = {};
    if (token) {
      headers["auth-token"] = token;
    }

    try {
      const response = await axios.get<UserAgenBalanApiResponse>(url, {
        headers,
        params: {
          token,
        },
      });

      // console.log(response.data)
      if (response.data?.status) {
        setUserAgenBalan(response.data?.data?.UsersAgentbalance ?? []);
      } else {
        setError(response.data?.msg || "Failed to fetch user balances");
      }
    } catch (err) {
      setError("An error occurred while fetching user balances.");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchUserAgenBalans();
  }, [fetchUserAgenBalans]);

  return (
    <UserAgenBalanContext.Provider
      value={{
        UsersAgentbalance,
        filters,
        setFilters,
        loading,
        error,
        fetchUserAgenBalans,
      }}
    >
      {children}
    </UserAgenBalanContext.Provider>
  );
};

export const useUserAgenBalan = (): UserAgenBalanContextType => {
  const context = useContext(UserAgenBalanContext);
  if (!context) {
    throw new Error("useUserAgenBalan must be used within a UserAgenBalanProvider");
  }
  return context;
};

export default UserAgenBalanProvider;
