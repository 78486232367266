import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
} from "react";
import axios from "axios";
import { AuthContext } from "../AuthContext"; // Import AuthContext for token

// Interface for Deposit items from API response
interface Statement {
  user_id: number;
  name: string;
  jounral_name: string;
  currency_name: string;
  trx_type: number;
  currency: string;
  referance_id: number;
  transaction_date: string;
  year: number;
  transaction_status: number;
  master_id: number;
  debit_amount: string;
  credit_amount: string;
  debit_local: string;
  credit_local: string;
  details: string;
  status: number;
  charge: string;
  deviceid: string;
  trans_date: string;
  balance: string;
}

interface UserStatementTransaction {
  id: number;
  user_id: number;
  name: string;
  jounral_name: string;
  currency_name: string;
  trx_type: number;
  currency: string;
  referance_id: number;
  transaction_date: string;
  year: number;
  transaction_status: number;
  master_id: number;
  debit_amount: string;
  credit_amount: string;
  debit_local: string;
  credit_local: string;
  details: string;
  status: number;
  charge: string;
  deviceid: string;
  trans_date: string;
  balance: string;
}

interface Result {
  currency_name: string;
  total_debit: number;
  total_credit: string;
  balance: string;
  balanceInWords: string;
}

interface UserStatementTransactionApiResponse {
  status: boolean;
  errNum: string;
  msg: string;
  data: {
    statement: Statement[];
    results: Result[];
  };
}


interface Filters {
  deviceId: string;
  type: string;
  from_date: string;
  to_date: string;
  status: string;
  currencyid: string;
  Search: string;
}

interface UserStatementContextType {
  results: Result[] | null;
  UserStatementTransaction: UserStatementTransaction[] | null;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  loading: boolean;
  error: string | null;
  fetchUserStatementTransactions: () => Promise<void>;
}

interface UserStatementTransactionProviderProps {
  children: ReactNode;
}

export const UserStatementContext = createContext<
  UserStatementContextType | undefined
>(undefined);

const UserStatementTransactionProvider: React.FC<UserStatementTransactionProviderProps> = ({
  children,
}) => {
  const [UserStatementTransaction, setUserStatementTransaction] = useState<
    UserStatementTransaction[] | null
  >(null);
  const [results, setresults] = useState<Result[] | null>(null);
  const [filters, setFilters] = useState<Filters>({
    deviceId: "",
    currencyid: "",
    type: "",
    from_date: "",
    to_date: "",
    status: "",
    Search: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Get token from AuthContext
  const { token } = useContext(AuthContext);

  const fetchUserStatementTransactions = useCallback(async () => {
    setLoading(true);
    const url =
      "https://click.one.sky4system.com/api/user/Setting/Statement";

    const formData = new FormData();
    if (filters.deviceId) {
      formData.append("deviceId", filters.deviceId);
    }
    if (filters.currencyid) {
      formData.append("currencyid", filters.currencyid);
    }
    if (filters.from_date) {
      formData.append("from_date", filters.from_date);
    }
    if (filters.to_date) {
      formData.append("to_date", filters.to_date);
    }
    if (filters.type) {
      formData.append("type", filters.type);
    }
    if (filters.Search) {
      formData.append("Search", filters.Search);
    }

    const headers: HeadersInit = {};
    if (token) {
      headers["auth-token"] = token;
    }

    try {
      const response = await axios.post<UserStatementTransactionApiResponse>(
        url,
        formData,
        {
          headers,
          params: {
            token,
          },
        }
      );
    
      if (response.data?.status) {
        const mappedData = response.data.data.statement.map((item) => ({
          id: item.master_id,
          user_id: item.user_id,
          name: item.name,
          jounral_name: item.jounral_name,
          currency_name: item.currency_name,
          trx_type: item.trx_type,
          currency: item.currency,
          referance_id: item.referance_id,
          year: item.year,
          transaction_status: item.transaction_status,
          master_id: item.master_id,
          debit_local: item.debit_local,
          credit_local: item.credit_local,
          charge: item.charge,
          deviceid: item.deviceid,
          trans_date: item.trans_date,
          transaction_date: item.transaction_date,
          debit_amount: item.debit_amount,
          credit_amount: item.credit_amount,
          balance: item.balance,
          details: item.details,
          status: item.status,
        }));
        setresults(response.data?.data?.results ?? []);
        setUserStatementTransaction(mappedData);
      } else {
        setError(response.data?.msg || "Failed to fetch user statements");
      }
    } catch (err) {
      setError("An error occurred while fetching user statements.");
    } finally {
      setLoading(false);
    }
    
  }, [filters, token]);

  useEffect(() => {
    fetchUserStatementTransactions();
  }, [fetchUserStatementTransactions]);

  return (
    <UserStatementContext.Provider
      value={{
        UserStatementTransaction,
        results,
        filters,
        setFilters,
        loading,
        error,
        fetchUserStatementTransactions,
      }}
    >
      {children}
    </UserStatementContext.Provider>
  );
};

export const useUserStatementTransaction = (): UserStatementContextType => {
  const context = useContext(UserStatementContext);
  if (!context) {
    throw new Error(
      "useUserStatementTransaction must be used within a UserStatementTransactionProvider"
    );
  }
  return context;
};

export default UserStatementTransactionProvider;
