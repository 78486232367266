import React, { createContext, ReactNode } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface CreateUserContextType {
  signup: (
    name: string,
    username: string,
    phone: string,
    email: string,
    password: string,
    navigate: ReturnType<typeof useNavigate>,
    setErrors: (errors: Record<string, string>) => void
  ) => Promise<void>;
}

export const CreateUserContext = createContext<CreateUserContextType>({
  signup: async () => {},
});

const CreateUserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const signup = async (
    name: string,
    username: string,
    phone: string,
    email: string,
    password: string,
    navigate: ReturnType<typeof useNavigate>,
    setErrors: (errors: Record<string, string>) => void
  ) => {
    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("role", "2");

      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/createUser",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      if (response.data.status === true) {
        console.log(response.data);
        navigate("/verfy-code-mail");
      } 
      else {
        if (response.data.errNum === 'E023') {
          setErrors({ email: "البريد الاكتروني موجود مسبقا." });
        }else if (response.data.errNum === 'E026') {
          setErrors({ username: "اسم المستخدم موجود مسبقا." });
        }
        else if (response.data.errNum === 'E028') {
          setErrors({ phone: "رقم الهاتف موجود مسبقا." });
        }
         else {
          setErrors({ general: response.data.msg });
        }
      }

    } catch (error) {
      console.log(error);
      throw new Error("Invalid credentials");
    }
  };

  return (
    <CreateUserContext.Provider value={{ signup }}>
      {children}
    </CreateUserContext.Provider>
  );
};

export default CreateUserProvider;
