import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaSpinner } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { DeviceMasterContext } from "../../../context/devices/DeviceMasterContext";
import { DeviceOrderCotext } from "../../../context/devices/DeviceOrederContext";
import { AuthContext } from "../../../context/AuthContext";
import axios from "axios";
import { DevicePeriodContextCotext } from "../../../context/devices/DevicePeriodContext";

const EditPromotion: React.FC = () => {
  const { deviceData } = useContext(DeviceMasterContext);
  const { orderData, loading } = useContext(DeviceOrderCotext);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const location = useLocation();
  const [loadingRows, setLoadingRows] = useState<{ [key: number]: boolean }>(
    {}
  );
  const { peroidData } = useContext(DevicePeriodContextCotext);

  const [message, setMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);
  const { fetchOrderMaster } = useContext(DeviceOrderCotext);
  const { token } = useContext(AuthContext);
  const itemsPerPage = 4;
// console.log(deviceData)

  const [filters, setFilters] = useState({
    name: "",
    phone: "",
  });

  const sortUsersByDate = (data: any[]) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);
      return dateB.getTime() - dateA.getTime();
    });
  };

  const handleUpdateChange = async (
    planId?: number,
    peroidId?: number,
    orderId?: number,
    deviceId?: string,
    deviceToken?: string
  ) => {
    if (orderId !== undefined) {
      setLoadingRows((prevState) => ({ ...prevState, [orderId]: true }));
    }
    setMessage(null);

    const formData = new FormData();
    if (planId !== undefined) formData.append("plan_id", planId.toString());
    if (peroidId !== undefined)
      formData.append("Periode_id", peroidId.toString());
    if (orderId !== undefined) formData.append("order_id", orderId.toString());
    if (deviceId) formData.append("deviceId", deviceId);
    if (deviceToken) formData.append("deviceToken", deviceToken);
    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/updateDeviceSubscribe",
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      console.log(response.data);
      if (response.data.status === true) {
        fetchOrderMaster();
        setMessage({
          text: "لقد تم تجديد الباقة بنجاح",
          type: "success",
        });
      } else if (response.data.errNum === "400") {
        setMessage({
          text: "لقد تم تجديد اشتراكك مسبقا",
          type: "success",
        });
      } else {
        setMessage({ text: ".لايمكنك الموافقة على طلبك. يجب تأكيد الموافقة من قبل الادارة", type: "error" });
      }
    } catch (error) {
      setMessage({
        text: "An error occurred. Internal Server Error 500.",
        type: "error",
      });
    } finally {
      if (orderId !== undefined) {
        setLoadingRows((prevState) => ({ ...prevState, [orderId]: false }));
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      // setisLoading(false);
    }, 300);
  }, [location.search, orderData]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const applyFilters = (data: any[]) => {
    return data.filter((order) => {
      //   const matchesName = order.name.toLowerCase().includes(filters.name.toLowerCase());
      const matchesPhone = order.phone.includes(filters.phone);
      return matchesPhone;
    });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  if (loading) {
    return (
      <div className="grid justify-center py-6 mt-10 my-4">
        <FaSpinner className="animate-spin text-blue-500" size={28} />
      </div>
    );
  }

  const sortedUsers = sortUsersByDate(orderData?.data ?? []);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredUsers = applyFilters(sortedUsers);
  const currentOrders = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedUsers.length / itemsPerPage);
  

  const getDeviceName = (deviceId: string) => {
    if (!deviceData || !deviceData.data) {
      return "No Device Data";
    }
    const device = deviceData.data.find(
      (d: { deviceId: string }) => d.deviceId === deviceId
    );

    return device ? device.name : "Unknown Device";
  };

  const getDeviceToken = (deviceId: string) => {
    if (!deviceData || !deviceData.data) {
      return "No Device Data";
    }
    const device = deviceData.data.find(
      (d: { deviceId: string }) => d.deviceId === deviceId
    );

    return device ? device.Token : "Unknown Device";
  };
  const getPeroidMonth = (periode_id: string) => {
    if (!peroidData || !peroidData.data) {
      return "No order Data";
    }
    const order = peroidData.data.find(
      (d: { id: string }) => d.id === periode_id
    );

    return order ? order.name : "Unknown order";
  };
  return (
    <div className="px-4 min-h-screen my-4 mt-16">
      <div className="bg-white rounded-sm md max-h-screen px-0 scrollbar-lightr">
        <div className="my-6 absolute top-12">
          {message && (
            <div
              className={`p-4 rounded ${
                message.type === "success"
                  ? "bg-green-100 text-green-700"
                  : "bg-red-100 text-red-700"
              }`}
            >
              {message.text}
            </div>
          )}
        </div>
        <div>
          <div className="hidden lg:block" dir="rtl">
            <div className="flex flex-col lg:flex-row gap-4 mb-6 px-4">
              <input
                type="text"
                name="phone"
                placeholder="search"
                value={filters.phone}
                onChange={handleFilterChange}
                className="p-2 mt-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105"
              />
            </div>
            {orderData?.data?.length ? (
              <>
                <table className="w-11/12 divide-y divide-gray-200">
                  <tbody className="bg-white divide-y mt-8 divide-gray-200 min-h-screen">
                    {currentOrders.map((order: any) => (
                      <tr
                        key={order.id}
                        className="cursor-pointer hover:bg-gray-100"
                      >
                        <td className="px-4 w-8 py-4 font-bold whitespace-nowrap text-sm text-gray-500">
                          {/* {order.DeviceId} */}
                          <div>
                            {getDeviceName(order.DeviceId)}
                          </div>
                        </td>
                        <td className="px-4 py-4 w-10 whitespace-nowrap text-sm text-gray-500">
                          <div>
                            <span className="text-blue-800 font-bold">${order.amount}</span>
                          </div>
                        </td>
                        <td className="px-4 py-4 w-10 whitespace-nowrap text-sm text-gray-500">{getPeroidMonth(order.periode_id)}</td>
                        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                          {order.phone}
                        </td>
                        
                        <td className="px-4 py-4 whitespace-nowrap text-sm">
                          {new Intl.DateTimeFormat("ar-EG", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          }).format(new Date(order.created_at))}
                        </td>
                        <td className="px-4 py-1 whitespace-nowrap text-sm">
                          <div
                            className={`mt-1 justify-end inline-flex px-3 py-1 text-xs font-semibold leading-5 rounded-full ${
                              order.status === "pending"
                                ? "bg-white text-red-800"
                                : "bg-blue-200 text-blue-800"
                            }`}
                          >
                            {order.status === "pending" ? (
                              <button
                                onClick={() =>
                                  handleUpdateChange(
                                    order.plan_id,
                                    order.periode_id,
                                    order.id,
                                    order.DeviceId,
                                    getDeviceToken(order.DeviceId)
                                  )
                                }
                                className={`inline-flex gap-2 px-2 py-1 border-gray-400 text-sm font-medium text-gray-100 ring-blue-100 bg-[#e44138] border border-transparent rounded-md hover:bg-[#ed4b42] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-100 ${
                                  loadingRows[order.id]
                                    ? "cursor-not-allowed opacity-50"
                                    : ""
                                }`}
                                disabled={loadingRows[order.id]}
                              >
                                {loadingRows[order.id]
                                  ? "جاري التأكيد..."
                                  : "الموافقة على الطلب"}
                              </button>
                            ) : (
                              "تمت الموافقة "
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <div className="px-4 py-4 text-center text-gray-600">
                {/* <div>لا توجد بيانات</div> */}
                <div className="px-4 py-4 text-center text-gray-600">
                  <div className="flex justify-center items-center">
                    {/* <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" /> */}
                    <span>No Data</span>
                  </div>
                </div>
              </div>
            )}
            {/* Pagination */}
            <div className="flex justify-center gap-4 items-center p-4">
              <button
                onClick={handlePreviousPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === 1}
              >
                <FaArrowLeft />
              </button>
              <span className="text-gray-500">
                صفحة {currentPage} من {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === totalPages}
              >
                <FaArrowRight />
              </button>
            </div>
          </div>

          {/* Mobile Cards */}
          <div className="lg:hidden" dir="rtl">
            {orderData ? (
              currentOrders.map((order: any) => (
                <div
                  key={order.id}
                  className="shadow-md hover:md mt-16 transition-shadow duration-300 ease-in-out px-4 py-6 mb-0 rounded-lg border border-gray-200"
                >
                  <p className="text-md font-semibold text-gray-800 mb-3">
                    الجهاز: {getDeviceName(order.DeviceId)}
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    <span className="font-medium text-gray-900">الباقة:</span>{" "}
                    <span>{order.amount}$ / شهرية</span>
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    <span className="font-medium text-gray-900">
                      رقم الهاتف:
                    </span>{" "}
                    {order.phone}
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    <span className="font-medium text-gray-900">
                      حالة الطلب:
                    </span>{" "}
                    <span
                      className={`inline-flex px-2 py-1 text-xs font-semibold leading-5 rounded-full ${
                        order.status === "pending"
                          ? "bg-red-100 text-red-800"
                          : "bg-blue-100 text-blue-800"
                      }`}
                    >
                      {order.status === "pending" ? "قيد الانتظار" : "مفعل"}
                    </span>
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    <span className="font-medium text-gray-900">
                      تاريخ الطلب:
                    </span>{" "}
                    {new Intl.DateTimeFormat("ar-EG", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(new Date(order.created_at))}
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    <span className="font-medium text-gray-900">
                      حالة الطلب:
                    </span>{" "}
                    <span> تأكيد الموافقة</span>
                  </p>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-600">No order Data</p>
            )}

            {/* Pagination for Mobile */}
            <div className="flex justify-center gap-5 items-center p-4">
              <button
                onClick={handlePreviousPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === 1}
              >
                <FaArrowLeft />
              </button>
              <span className="text-gray-500">
                صفحة {currentPage} من {totalPages}
              </span>
              <button
                // onClick={() => handleUpdateChange(order.planId, order.peroidId, order.id)}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === totalPages}
              >
                <FaArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPromotion;
