import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";

// interface Users {
//   id: string;
//   user_id: number;
//   device_id: string;
//   To_number: string;
//   created_at: string;
//   updated_at: string;
// }

// interface UseriDData {
//   [x: string]: any;
//   current_page: number;
//   // Schedules: Users[];
// }

interface UserMasterContextType {
  userData: any | null;
  // userDataId: any | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchUserMaster: () => void;
  error: string | null;
  loading: boolean;
  fetchUserById: (id: string) => Promise<void> | null;
}

export const UserMasterContext = createContext<UserMasterContextType>({
  userData: null,
  // userDataId: null,
  loading: false,
  setLoading: () => {},
  error: null,
  fetchUserById: async () => {},
  fetchUserMaster: () => {},
});

const UserMasterProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { token } = useContext(AuthContext);
  const [userData, setUserData] = useState<any | null>(null);
  // const [userDataId, setUserDataId] = useState<UseriDData | null>(null);
  const [loading, setLoading] = useState(true);
  // const [loadings, setLoadingS] = useState(true);
  const [error, setError] = useState<string | null>(null);
  // Fetch the latest device master data
  const fetchUserMaster = useCallback(async () => {
    if (!token) return;
    setLoading(true);
    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/getmasteruser",
        new FormData(),
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          params: {
            token,
          },
        }
      );
      setUserData(response.data);
      // console.log(response.data)
    } catch (error) {
      console.error("Failed to fetch user master data", error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const fetchUserById = useCallback(
    async (id: string) => {
      const url =
        "https://click.one.sky4system.com/api/user/Setting/GetUserById";
      const headers: HeadersInit = {};
      const formData = new FormData();
      formData.append("id", id);
      // console.log(id);
      if (token) {
        headers["auth-token"] = token;
      }

      try {
        const response = await axios.post(url, formData, {
          headers: {
            ...(token ? { "auth-token": token } : {}),
          },
          // params: {
          //   token,
          // },
        });

        if (!response) {
          throw new Error(`HTTP error! status: ${response}`);
        }

        const data = await response.data;
        // console.log(data);

        if (data.status === true) {
          // console.log(data);
          // setUserDataId(data.data);
          return data.user;
        } else {
          setError(data.msg || "Failed to fetch the schedule by ID");
          return null;
        }
      } catch (err) {
        setError("An error occurred while fetching the schedule by ID");
        return null;
      }
    },
    [token]
  );

  useEffect(() => {
    fetchUserMaster();
  }, [fetchUserMaster]);

  return (
    <UserMasterContext.Provider
      value={{
        userData,
        loading,
        error,
        setLoading,
        // userDataId,
        fetchUserById,
        fetchUserMaster,
      }}
    >
      {children}
    </UserMasterContext.Provider>
  );
};

export default UserMasterProvider;
