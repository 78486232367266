// src/components/Content.tsx
import React from "react";
import CartList from "../../components/cart/CartList";
// import UserMaster from "./devices/UserMaster";
import { Outlet } from 'react-router-dom';
import DashboardAdmin from "./admin/DashboardAdmin";

const Content: React.FC = () => {
  return (
    <div className="overflow-y-auto hide-scrollbar ">
      <CartList />
      <DashboardAdmin/>
      <Outlet />
    </div>
  );
};

export default Content;
