import { AiFillFileText } from "react-icons/ai";
import CreateCompainSMS from "./CreateCompainSMS";
import React from "react";

const ComainMessagecomponent: React.FC = () => {
  return (
    <div className="justify-start container mt-14">
      <div className="flex justify-start gap-3 sm:w-10/12 mx-auto text-lg mt-8">
        <AiFillFileText size={25} className="text-cyan-600" />
        <span>ارسال رسائل مجدولة</span>
      </div>
      <CreateCompainSMS />
    </div>
  );
};

export default ComainMessagecomponent;
