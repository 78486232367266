import React, { useContext } from "react";
import PromotionPage from "./PromotionPage";
import { FaSpinner } from "react-icons/fa";
import { PlanHomeContext } from "../context/devices/PlanHomeContext";

const PricingSectionPage: React.FC = () => {
  const { planData, isloading, fetchPlanMaster } = useContext(PlanHomeContext);

  if (isloading) {
    return (
      <div className="flex justify-center mt-10 items-center min-h-screen bg-gray-50">
        <FaSpinner className="animate-spin text-blue-600" size={32} />
      </div>
    );
  }

  // console.log(planData.data)

  if (!planData || !planData.data || planData.data.length === 0) {
    return <p>No data available
      <button onClick={fetchPlanMaster} disabled={isloading}>click</button>

    </p>;
  }

  return (
    <div>
      <h2 className="text-3xl font-bold mt-4 text-center">الأسعار</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-2 xl:grid-cols-3 gap-4 justify-center items-center px-4 my-4">
        {planData.data.map((plan: any) => {
          let features = [];
          if (
            plan.data &&
            typeof plan.data === "string" &&
            plan.data.trim() !== ""
          ) {
            try {
              const parsedData = JSON.parse(plan.data);
              features = parsedData.features || [];
            } catch (error) {
              console.log("Error parsing plan data:", error);
            }
          }

          return (
            <PromotionPage
              key={plan.id}
              title={plan.title}
              price={plan.price}
              days={plan.days}
              plan={plan.plan}
              description={plan.description}
              features={features}
              status={plan.status}
              buttonText={plan.buttonText}
              isHighlighted={plan.isHighlighted}
              id={plan.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PricingSectionPage;
