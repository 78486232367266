import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../../context/AuthContext";
import { IoMdClose } from "react-icons/io";
import { FaSpinner } from "react-icons/fa";

interface TransactionDetails {
  user_id: number;
  name: string;
  jounral_name: string;
  currency_name: string;
  trx_type: number;
  currency: string;
  referance_id: number;
  transaction_date: string;
  year: number;
  transaction_status: number;
  master_id: number;
  debit_amount: string;
  credit_amount: string;
  debit_local: string;
  credit_local: string;
  details: string;
  status: number;
  charge: string;
  deviceid: string;
  trans_date: string;
  balance: number;
}

interface TransactionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  masterId: number | null;
}

const TransactionDialog: React.FC<TransactionDialogProps> = ({
  isOpen,
  onClose,
  masterId,
}) => {
  const [transactionDetails, setTransactionDetails] = useState<
    TransactionDetails[] | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    if (isOpen && masterId) {
      fetchTransactionDetails(masterId);
    }
  }, [isOpen, masterId]);

  const fetchTransactionDetails = async (id: number) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://click.one.sky4system.com/api/user/Setting/GetTransactionDetails?MasterId=${id}`,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      const data = response.data.data.TransactionDetails; // Extract the array
      setTransactionDetails(data);
    } catch (err) {
      setError("Failed to fetch transaction details.");
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25">
      <div className="relative w-full sm:max-w-4xl h-full sm:h-auto bg-white sm:rounded-lg sm:shadow-lg">
        <button
          className="absolute top-0 right-0 sm:rounded-bl-lg sm:rounded-tr-lg bg-red-500 text-gray-100 hover:text-gray-200"
          onClick={onClose}
        >
          <IoMdClose size={25} />
        </button>
        <div className="p-6">
          {/* {loading && <p className="text-center text-gray-700">Loading...</p>} */}
          <p className="text-center text-lg text-green-500 font-normal">تفاصيل القيد</p>

          {loading ? (
            <div>
              <div className="text-center py-4">
                <div className="flex justify-center items-center">
                  <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
                </div>
              </div>
            </div>
          ) : error ? (
            <p className="text-center text-red-500">{error}</p>
          ) : transactionDetails && transactionDetails.length > 0 ? (
            <div className="scrollbar-light mt-8 overflow-x-auto">
              <table className="min-w-full max-h-screen border-collapse">
                <thead className="bg-slate-100 border-b border-gray-300">
                  <tr className="bg-slate-100 border-collapse shadow-sm">
                    <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                      الاسم
                    </th>
                    <th className="px-4 py-3 font-semibold text-center text-xs text-gray-600 uppercase tracking-wider">
                      سند الايداع
                    </th>
                    <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                      الرصيد
                    </th>
                    <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                      العملة
                    </th>
                    <th className="px-4 py-3 font-semibold text-center text-xs text-gray-600 uppercase tracking-wider">
                      رقمم المرجع
                    </th>
                    <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                      تايخ القيد
                    </th>
                    <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                      السنة
                    </th>
                    <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                      مدين
                    </th>
                    <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                      دائن
                    </th>
                    <th className="px-4 py-3 font-semibold text-center text-xs text-gray-600 uppercase tracking-wider">
                      سعر الصرف
                    </th>

                    <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                      الحالة
                    </th>
                    <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                      البيان
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {transactionDetails
                    .sort(
                      (a, b) =>
                        new Date(b.transaction_date).getTime() -
                        new Date(a.transaction_date).getTime()
                    )
                    .map((transaction) => (
                      <tr
                        key={transaction.user_id}
                        className={`hover:bg-gray-100 ${(() => {
                          const now = new Date();
                          now.setDate(now.getDate());
                          return new Date(
                            transaction.transaction_date
                          ).toDateString() === now.toDateString()
                            ? "bg-green-50"
                            : "";
                        })()}`}
                        
                      >
                        <td className="px-4 py-3 text-right whitespace-nowrap text-xs text-gray-800">
                          {transaction.name}
                        </td>
                        <td className="px-10 py-3 text-right whitespace-nowrap text-xs text-gray-800">
                          {transaction.jounral_name}
                        </td>
                        <td
                          className={`px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700 ${
                            transaction.balance < 0
                              ? "bg-blue-300"
                              : transaction.balance > 0
                              ? "bg-red-300"
                              : ""
                          }`}
                        >
                          <span className="font-bold">
                            {Math.abs(transaction.balance)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </td>
                        <td className="px-4 py-3 text-right whitespace-nowrap text-xs text-gray-800">
                          {transaction.currency_name}
                        </td>
                        <td className="px-20 py-3 text-right whitespace-nowrap text-xs text-gray-800">
                          {transaction.referance_id}
                        </td>
                        <td className="px-4 py-3 text-right whitespace-nowrap text-xs text-gray-800">
                          {transaction.transaction_date}
                        </td>
                        <td className="px-4 py-3 text-right whitespace-nowrap text-xs text-gray-800">
                          {transaction.year}
                        </td>
                        <td className="px-4 py-3 text-right whitespace-nowrap text-xs text-gray-800">
                          <span className="font-bold">
                            {Math.abs(parseFloat(transaction.debit_amount))
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </td>
                        <td className="px-4 py-3 text-right whitespace-nowrap text-xs text-gray-800">
                          <span className="font-bold">
                            {Math.abs(parseFloat(transaction.credit_amount))
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </td>
                        <td className="py-3 px-10 whitespace-nowrap text-right text-xs text-gray-700">
                          <span className="font-bold">
                            {Math.abs(parseFloat(transaction.charge || "0"))
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700">
                          <span
                            className={`inline-flex px-2 py-1 text-xs font-semibold leading-5 rounded-full ${
                              transaction.status === 1
                                ? "bg-blue-100 text-blue-800"
                                : "bg-red-100 text-red-800"
                            }`}
                          >
                            {transaction.status === 1 ? "مرحل" : "مسودة"}
                          </span>
                        </td>

                        <td className="py-3 px-4 whitespace-nowrap text-right text-xs text-gray-700">
                          {transaction.details}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-center text-gray-700">لا توجد بيانات متاحة.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionDialog;
