import React from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

interface Feature {
  name: string;
  isEnabled: boolean;
}

interface PromotionPageProps {
  id: number;
  price: string;
  plan: string;
  title: string;
  days: string;
  status: number;
  description: string;
  features: Feature[];
  buttonText: string;
  isHighlighted?: boolean;
}

const PromotionPage: React.FC<PromotionPageProps> = ({
  price,
  title,
  id,
  status,
  days,
  features = [],
  buttonText,
  isHighlighted = false,
}) => {
  return (
    <div>
      {status === 1 ? (
        <div
          className={`w-96 sm:w-72 dark:bg-gray-800 text-gray-700 dark:text-gray-300 rounded-lg lg:w-[20rem] overflow-hidden shadow-lg p-6 ${isHighlighted ? 'dark:bg-gray-700 text-gray-700 dark:text-gray-300 border-1 border-gray-300' : 'dark:bg-gray-900 text-gray-700 dark:text-gray-300'} ${
            isHighlighted
              ? "border-2 border-green-500 dark:border-green-500"
              : "bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-300"
          }`}
        >
          <div>
            {/* <FiWhat */}
          </div>
          <div className="text-center mb-4">
            <h2 className="text-3xl font-semibold text-gray-600 dark:text-gray-100">
              {title}
            </h2>
          </div>
          <div className="text-center mb-4">
            <h2
              className={`rounded-full ${
                isHighlighted
                  ? "bg-gray-100 dark:bg-gray-700 text-3xl font-semibold"
                  : ""
              } text-2xl font-semibold`}
            >
              <div className="flex justify-center gap-2">
                <span className="text-2xl font-semibold text-gray-600 dark:text-gray-200">
                  {price}$/</span>
                <span className="text-xl text-sky-600 flex items-center gap-1">
                  <span style={{ marginTop: "2px" }}>{days}</span>
                  <span>يوم</span>
                </span>
              </div>
            </h2>
          </div>
          <hr className="mb-10 mx-3 text-gray-600 dark:text-gray-500" />
          <ul className="mb-6" dir="rtl">
            {features.length > 0 ? (
              features.map((feature, index) => (
                <li key={index} className="flex items-center mb-2">
                  {feature.isEnabled ? (
                    <FaCheckCircle
                      className="w-6 h-6 ml-2 text-[#375896] dark:text-[#5ca0f2]"
                    />
                  ) : (
                    <FaTimesCircle className="w-6 h-6 ml-2 text-red-500 dark:text-red-400" />
                  )}
                  <span className="my-1 font-semibold">{feature.name}</span>
                </li>
              ))
            ) : (
              <li className="text-gray-500 dark:text-gray-400">
                No features available
              </li>
            )}
          </ul>
          <Link
            to={`/promotions/${id}`}
            className={`w-full py-2 rounded-full ${
              isHighlighted
                ? "bg-[#30aaf0] text-white"
                : "bg-white dark:bg-gray-800 border border-gray-500 dark:border-gray-600 text-gray-700 dark:text-gray-300"
            } font-semibold text-center inline-block`}
          >
            {buttonText}
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default PromotionPage;
