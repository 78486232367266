// ResetUserContext.tsx
import React, { createContext, ReactNode } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface ResetUserContextType {
  resetPassword: (
    email: string,
    password: string,
    otp: string,
    navigate: ReturnType<typeof useNavigate>,
    setErrors: (errors: Record<string, string>) => void
  ) => Promise<void>;
}

export const ResetUserContext = createContext<ResetUserContextType>({
  resetPassword: async () => {},
});

const ResetUserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const resetPassword = async (
    email: string,
    password: string,
    otp: string,
    navigate: ReturnType<typeof useNavigate>,
    setErrors: (errors: Record<string, string>) => void
  ) => {
    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/reset_password",
        { email, password, otp }
      );
      // console.log(response.data);
      if (response.data.status === true) {
        localStorage.removeItem("email");
        navigate("/login");
      }
      if (response.data.errNum === "E040") {
        setErrors({ usererror: "عنوان البريد الاكتروني عير موجود" });
      } else if (response.data.errNum === "E050") {
        setErrors({ otprerror: "الكود الذي ادخلته غير صالح" });
      } 
      else if (response.data.errNum === "502") {
        setErrors({ waitotp: response.data.msg });
      } else {
        setErrors({ general: response.data.msg });
      }
    } catch (error) {
      setErrors({ general: "An error occurred. Please try again later." });
    }
  };

  return (
    <ResetUserContext.Provider value={{ resetPassword }}>
      {children}
    </ResetUserContext.Provider>
  );
};

export default ResetUserProvider;
