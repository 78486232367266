import React, { useState, useContext, Fragment, useEffect } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { FaTimes, FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CreateDeviceContext } from "../../context/devices/CreatDeviceContex";
import Notification from "../../pages/notifications/Notification";

interface CreateDialogDeviceProps {
  isOpen: boolean;
  closeModal: () => void;
}

interface ErrorState {
  name?: string;
  number?: string;
  apiError?: string;
}

const CreateDialogDevice: React.FC<CreateDialogDeviceProps> = ({
  isOpen,
  closeModal,
}) => {
  const { createdivec } = useContext(CreateDeviceContext);
  const [name, setName] = useState("");
  // const [number, setNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<ErrorState>({});
  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  const validate = () => {
    const newErrors: ErrorState = {};

    if (!name.trim()) newErrors.name = "الاسم مطلوب";
    // if (!number.trim()) newErrors.number = "رقم الهاتف مطلوب";
    // else if (!/^\d{12}$/.test(number))
    //   newErrors.number = "رقم الهاتف يجب أن يكون بالصيغة الصحيحة";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateDevice = async () => {
    if (!validate()) return;
    setIsLoading(true);
    try {
      const result = await createdivec(name, navigate);
      if (result && result.status === true) {
        setNotification({ message: "تم انشاء الجهاز", type: "success" });
        closeModal();
      // } else {
      //   setErrors((prevErrors) => ({
      //     ...prevErrors,
      //     apiError: result?.message || "رقم الهاتف موجود مسبقا",
      //   }));
      }
    } catch (error) {
      console.error(error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        apiError: "حدث خطأ أثناء إنشاء الجهاز",
      }));
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => setNotification(null), 3000);
      return () => clearTimeout(timeout);
    }
  }, [notification]);

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto" dir="rtl">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between items-center">
                    <DialogTitle
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      إنشاء جهاز
                    </DialogTitle>
                    <button
                      onClick={closeModal}
                      className="text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                      <FaTimes className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="mt-2">
                    <div className="relative">
                      <input
                        type="text"
                        className={`w-full p-2 mt-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 ${
                          errors.name ? "border-red-500" : ""
                        }`}
                        placeholder="اسم الجهاز"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {errors.name && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.name}
                        </p>
                      )}
                    </div>
                    {/* <div className="relative">
                      <input
                        type="text"
                        className={`w-full p-2 mt-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 ${
                          errors.number ? "border-red-500" : ""
                        }`}
                        placeholder="967772423450"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                      />
                      {errors.number && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.number}
                        </p>
                      )}
                    </div> */}
                  </div>

                  {errors.apiError && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.apiError}
                    </p>
                  )}

                  <div className="mt-4">
                    <button
                      type="button"
                      className={`inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-[#49c6ff] border border-transparent rounded-md hover:bg-[#3ac1ff] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 ${
                        isLoading ? "cursor-not-allowed opacity-50" : ""
                      }`}
                      onClick={handleCreateDevice}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <FaSpinner className="w-5 h-5 mr-3 animate-spin" />
                      ) : null}
                      {isLoading ? "إنشاء..." : "إنشاء جهاز"}
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
};

export default CreateDialogDevice;
