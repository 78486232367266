import React from 'react';

interface CardProps {
  icon: JSX.Element;
  value: string;
  label: string;
  // percentage: string;
  // percentageIncrease: boolean;
}

const Card: React.FC<CardProps> = ({ icon, value, label }) => {
  return (
    <div className="bg-white rounded-sm px-4 py-2 flex flex-col items-end justify-center border border-stroke px-7.5 shadow-default">
      <div className="bg-slate-100 text-indigo-400 p-2 rounded-md">
        {icon}
      </div>
      <h2 className="mt-4 text-3xl text-gray-600 font-bold">{value}</h2>
      <p className="">{label}</p>
      {/* <p className={`mt-2 ${percentageIncrease ? 'text-green-500' : 'text-red-500'}`}>
        {percentage} {percentageIncrease ? '↑' : '↓'}
      </p> */}
    </div>
  );
};

export default Card;
