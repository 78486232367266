import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";

interface UserEmailContextType {
  mailData: any | null;
  // mailDataId: any | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchUserEmail: () => void;
  error: string | null;
  loading: boolean;
  // fetchUserById: (id: string) => Promise<void> | null;
}

export const UserEmailContext = createContext<UserEmailContextType>({
  mailData: null,
  // mailDataId: null,
  loading: false,
  setLoading: () => {},
  error: null,
  // fetchUserById: async () => {},
  fetchUserEmail: () => {},
});

const UserEmailProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [mailData, setMailData] = useState<any | null>(null);
  // const [mailDataId, setMailDataId] = useState<UseriDData | null>(null);
  const [loading, setLoading] = useState(true);
  // const [loadings, setLoadingS] = useState(true);
  const [error, setError] = useState<string | null>(null);
  // Fetch the latest device master data
  const fetchUserEmail = useCallback(async () => {
    if (!token) {
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/Setting/Email-Setting",
        new FormData(),
        {
          headers: {
            "auth-token": token,
          },
          params: {
            token,
          },
        }
      );

      // if (response.status !== 200) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }

      const data = response.data;
      // console.log(data);
      if (data) {
        setMailData(data);
        // console.log(data);
      } else {
        setError("Failed to fetch the email settings.");
      }
    } catch (error) {
      setError("An error occurred while fetching user email settings.");
      console.error("Failed to fetch user email data", error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchUserEmail();
  }, [fetchUserEmail]);

  return (
    <UserEmailContext.Provider
      value={{
        mailData,
        loading,
        error,
        setLoading,
        // mailDataId,
        // fetchUserById,
        fetchUserEmail,
      }}
    >
      {children}
    </UserEmailContext.Provider>
  );
};

export default UserEmailProvider;
