// src/components/TextGroupComponent.tsx
import React from "react";
import { AiFillFileText } from "react-icons/ai";
import RichTextEditorGroup from "./RichTextEditorGroup";

const TextGroupComponent: React.FC = () => {

  return (
    <div className="justify-start container mt-14">
      <div className="flex justify-start gap-3 sm:w-10/12 mx-auto text-lg mt-8">
      <AiFillFileText size={25} className="text-cyan-600"/>
      <span>ارسال نص للمجموعات</span>
        </div>

      <RichTextEditorGroup />
    </div>
  );
};

export default TextGroupComponent;
