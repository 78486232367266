import React, { useContext, useState } from "react";
import { FaSpinner, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { UserMasterContext } from "../../../../context/UserMasterContext";
import { LuRefreshCcw } from "react-icons/lu";

const UsersSettings: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const { userData, fetchUserMaster, loading } = useContext(UserMasterContext);

  const [filters, setFilters] = useState({
    name: "",
    phone: "",
  });

  const sortDevicesByDate = (data: any[]) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);
      return dateB.getTime() - dateA.getTime();
    });
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const applyFilters = (data: any[]) => {
    return data.filter((device) => {
      const matchesName = device.name
        .toLowerCase()
        .includes(filters.name.toLowerCase());

      return matchesName;
    });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value);
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  const sortedUser = sortDevicesByDate(userData?.data ?? []);
  const totalPages = Math.ceil(sortedUser.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredDevice = applyFilters(sortedUser);
  const currentDevice = filteredDevice.slice(indexOfFirstItem, indexOfLastItem);

  // console.log(userData)

  if (loading) {
    return (
      <div className="text-center py-4">
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
          تحميل...
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 my-4 overflow-auto">
      {/* <TimeNotification /> */}

      <div className="bg-white rounded-sm shadow-sm max-h-screen hide-scrollbar px-0 overflow-x-auto scrollbar-light">
        {/* Desktop Table */}
        <div className="lg:block" dir="rtl">
          <div className="flex p-2 mx-0">
            <div className="flex flex-col lg:flex-row gap-2 mb-4">
              <input
                type="text"
                name="name"
                placeholder="اسم المستخدم"
                value={filters.name}
                onChange={handleFilterChange}
                className="p-2 mt-4 border border-gray-400 rounded-md focus:outline-none focus:ring-[1px] focus:ring-gray-400 transition duration-300 ease-in-out transform hover:scale-105"
              />
              <button
                onClick={fetchUserMaster}
                className={`${loading ? "cursor-not-allowed opacity-50" : ""}`}
                disabled={loading}
                type="button"
              >
                <LuRefreshCcw className="text-blue-500 mt-2.5 mx-4" size={25} />
              </button>
            </div>
          </div>
          <div className="overflow-x-auto scrollbar-light">
            <table className="min-w-full px-4 divide-y-1 hide-scrollbar overflow-x-scroll scrollbar-light divide-gray-200">
              <thead className="bg-white border-b-[1px] border-gray-300">
                <tr>
                  <th className="px-4 cursor-pointer py-4 flex font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex">المستخدم</div>
                  </th>
                  <th className="px-4 cursor-pointer py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex"> البريد الاكتروني</div>
                  </th>
                  <th className="px-4 cursor-pointer py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex">رقم الهاتف</div>
                  </th>
                  {/* <th className="px-4 cursor-pointer py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex">كلمة المرور</div>
                  </th> */}
                  <th className="px-4 cursor-pointer py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex">تاريخ الانشاء</div>
                  </th>
                  <th className="px-4 cursor-pointer py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                    <div className="flex">الأجهزة</div>
                  </th>
                  <th className="px-2 py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                    عمليات
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y overflow-x-scroll divide-gray-200">
                {currentDevice?.map((user: any) => (
                  <tr key={user.id}>
                    <td
                      className="px-4 py-2 whitespace-nowrap border focus:border-blue-500 text-xs text-gray-700 font-semibold"
                      tabIndex={0}
                    >
                      {user.name} | {user.username}
                    </td>

                    <td
                      className="px-4 py-2 whitespace-nowrap border focus:border-blue-500 text-xs text-gray-700 font-semibold"
                      tabIndex={0}
                    >
                      {user.email}
                    </td>
                    <td
                      className="px-4 py-2 whitespace-nowrap border focus:border-blue-500 text-xs text-gray-700 font-semibold"
                      tabIndex={0}
                    >
                      {user.phone}
                    </td>
                    <td
                      className="px-4 py-2 whitespace-nowrap border focus:border-blue-500 text-xs text-gray-700 font-semibold"
                      tabIndex={0}
                    >
                      {user.created_at}
                    </td>
                    <td
                      className="px-4 py-2 whitespace-nowrap border focus:border-blue-500 text-xs text-blue-600 font-bold"
                      tabIndex={0}
                    >
                      <Link
                        title={`${user.name}`}
                        className="text-blue-400 "
                        to={`/user-device-settings/${user.id}`}
                      >
                        عرض
                      </Link>
                    </td>
                    <td
                      className="border-t cursor-pointer text-center text-xs border-gray-200 py-2 px-2"
                      tabIndex={0}
                    >
                      <Link
                        title={`${user.name}`}
                        className="text-blue-400 "
                        to={`/user-settings/${user.id}`}
                      >
                        <FiEdit size={25} className="text-[#2a66b1]" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center p-4">
            <div className="flex items-center gap-2">
              <label htmlFor="itemsPerPage" className="text-gray-500">
                الصفوف بكل صفحة:
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className="text-sm text-gray-900 border focus:outline-none border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-300 focus:border-gray-300 p-1 dark:focus:border-gray-400"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={filteredDevice.length}>الكل</option>
              </select>
            </div>
            <div className="flex justify-center gap-4 items-center p-4">
              <button
                onClick={handlePreviousPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === 1}
              >
                <FaArrowLeft />
              </button>
              <span className="text-gray-500">
                صفحة {currentPage} من {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === totalPages}
              >
                <FaArrowRight />
              </button>
            </div>
          </div>
          {/* Pagination */}
        </div>
      </div>
    </div>
  );
};

export default UsersSettings;
