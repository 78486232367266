import { AiFillContacts } from "react-icons/ai";
import GroupContact from "./GroupContact";

const GroupContactComponent: React.FC = () => {
  return (
    <div className="flex-1 justify-start sm:w-10/12 space-y-4 container mt-14">
      <div className="flex justify-start gap-3 sm:w-10/12 mx-auto text-lg mt-8">
        <AiFillContacts size={25} className="text-cyan-600" />
        <span>جهات الاتصال</span>
      </div>
      <GroupContact />
    </div>
  );
};

export default GroupContactComponent;
