// MailTemplateDisplay.tsx
import React, {
  useContext,
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
} from "react";
import { FaSpinner } from "react-icons/fa";
import { LuRefreshCcw } from "react-icons/lu";
import { UserEmailContext } from "../../../../context/UserEmailContext";
import { MailData } from "./mail/types";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { AuthContext } from "../../../../context/AuthContext";
import axios from "axios";
import Section from "./mail/Section";
// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { vs } from "react-syntax-highlighter/dist/esm/styles/prism"; // VS Code-like theme

interface EmailSettings {
  name?: string;
  host?: string;
  port?: string;
  enc?: string;
  username?: string;
  password?: string;
}

const MailTemplateDisplay: React.FC = () => {
  const { mailData, fetchUserEmail, loading } = useContext(UserEmailContext);
  const { token } = useContext(AuthContext);

  // const CurrentData: MailData[] = Array.isArray(mailData)
  //   ? mailData
  //   : [mailData];

  const CurrentData: MailData[] = Array.isArray(mailData)
    ? mailData
    : mailData !== null && mailData !== undefined
    ? [mailData]
    : [mailData];
  // console.log(CurrentData)
  // State for editable fields
  const [emailSettings, setEmailSettings] = useState<EmailSettings>({
    name: "",
    host: "",
    port: "",
    enc: "",
    username: "",
    password: "",
  });

  const [emailTemplate, setEmailTemplate] = useState<string>("");

  const [isSubmittingSettings, setIsSubmittingSettings] =
    useState<boolean>(false);
  const [isSubmittingTemplate, setIsSubmittingTemplate] =
    useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  useEffect(() => {
    if (CurrentData?.length > 0 && CurrentData[0]?.mail_config) {
      const mail = CurrentData[0];
      setEmailSettings({
        name: mail.mail_config?.name || "",
        host: mail.mail_config?.host || "",
        port: mail.mail_config?.port || "",
        enc: mail.mail_config?.enc || "",
        username: mail.mail_config?.username || "",
        password: mail.mail_config?.password || "",
      });
      setEmailTemplate(unescapeHtml(mail.email_template || ""));
    }
  }, [mailData]);
  

  // Helper function to unescape HTML
  const unescapeHtml = (html: string | undefined): string => {
    if (!html) {
      // console.warn("Received undefined or null input in unescapeHtml");
      return ""; // Or return the default value you want
    }
    return html
      .replace(/\\n/g, "\n")
      .replace(/\\"/g, '"')
      .replace(/\\\\/g, "\\");
  };

  const handleSettingsChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setEmailSettings((prev) => ({ ...prev, [name]: value }));
  };

  // Handle email template change
  const handleTemplateChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEmailTemplate(e.target.value);
  };

  // Submit email settings
  const handleSubmitSettings = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmittingSettings(true);
    setSubmitSuccess(null);
    setSubmitError(null);

    try {
      const formData = new FormData();
      formData.append("email_method", emailSettings.name || "");
      formData.append("name", emailSettings.name || "");
      formData.append("host", emailSettings.host || "");
      formData.append("port", emailSettings.port || "");
      formData.append("enc", emailSettings.enc || "");
      formData.append("username", emailSettings.username || "");
      formData.append("password", emailSettings.password || "");

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://click.one.sky4system.com/api/user/Setting/EmailSettingUpdate",
        headers: {
          "auth-token": token,
        },
        data: formData,
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      setSubmitSuccess("إعدادات البريد الإلكتروني تم تحديثها بنجاح.");
      fetchUserEmail(); // Refresh data
    } catch (error: any) {
      console.error(error);
      setSubmitError(
        error.response?.data?.message ||
          "فشل في تحديث إعدادات البريد الإلكتروني."
      );
    } finally {
      setIsSubmittingSettings(false);
    }
  };

  // Submit email template
  const handleSubmitTemplate = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmittingTemplate(true);
    setSubmitSuccess(null);
    setSubmitError(null);

    try {
      const formData = new FormData();
      formData.append("email_template", emailTemplate);
      formData.append("email_from", emailSettings.username || "");

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://click.one.sky4system.com/api/user/Setting/EmailTemplateUpdate",
        headers: {
          "auth-token": token,
        },
        data: formData,
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      setSubmitSuccess("نموذج البريد الإلكتروني تم تحديثه بنجاح.");
      fetchUserEmail(); // Refresh data
    } catch (error: any) {
      console.error(error);
      setSubmitError(
        error.response?.data?.message || "فشل في تحديث نموذج البريد الإلكتروني."
      );
    } finally {
      setIsSubmittingTemplate(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center py-4">
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
          تحميل...
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 my-4 overflow-auto">
      <div className="flex flex-col lg:flex-row gap-4 mx-4 mb-4">
        <button
          onClick={fetchUserEmail}
          className={`${
            loading ? "cursor-not-allowed opacity-50" : ""
          } flex gap-2 border border-gray-300 hover:border-blue-500 rounded-lg p-2`}
          disabled={loading}
          type="button"
        >
          <span>تحديث</span>
          <LuRefreshCcw className="text-blue-500 mb-1" size={25} />
        </button>
      </div>

      {/* Display submission success or error messages */}
      {submitSuccess && (
        <div className="bg-green-100 text-green-700 p-3 rounded mb-4">
          {submitSuccess}
        </div>
      )}
      {submitError && (
        <div className="bg-red-100 text-red-700 p-3 rounded mb-4">
          {submitError}
        </div>
      )}

      {/* Email Settings Form */}
      <form
        onSubmit={handleSubmitSettings}
        className="bg-white mx-4 rounded-sm shadow-sm p-6 mb-6"
      >
        <h2 className="text-2xl font-bold mb-4">إعدادات البريد الإلكتروني</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block font-medium">طريقة البريد الإلكتروني</label>
            <select
              name="email_method"
              value={emailSettings.name}
              onChange={handleSettingsChange}
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-2 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 "
              required
            >
              <option value="">اختر طريقة</option>
              <option value="smtp">SMTP</option>
              <option value="sendmail">Sendmail</option>
              {/* Add other methods as needed */}
            </select>
          </div>
          <div>
            <label className="block font-medium">اسم</label>
            <input
              type="text"
              name="name"
              value={emailSettings.name}
              onChange={handleSettingsChange}
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-2 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 "
              required
            />
          </div>
          <div>
            <label className="block font-medium">المضيف</label>
            <input
              type="text"
              name="host"
              value={emailSettings.host}
              onChange={handleSettingsChange}
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-2 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 "
              required
            />
          </div>
          <div>
            <label className="block font-medium">المنفذ</label>
            <input
              type="number"
              name="port"
              value={emailSettings.port}
              onChange={handleSettingsChange}
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-2 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 "
              required
            />
          </div>
          <div>
            <label className="block font-medium">التشفير</label>
            <select
              name="enc"
              value={emailSettings.enc}
              onChange={handleSettingsChange}
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-2 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 "
              required
            >
              <option value="">اختر نوع التشفير</option>
              <option value="tls">TLS</option>
              <option value="ssl">SSL</option>
              <option value="none">لا يوجد</option>
            </select>
          </div>
          <div>
            <label className="block font-medium">اسم المستخدم</label>
            <input
              type="email"
              name="username"
              value={emailSettings.username}
              onChange={handleSettingsChange}
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-2 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 "
              required
            />
          </div>
          <div>
            <label className="block font-medium">كلمة المرور</label>
            <input
              type="password"
              name="password"
              value={emailSettings.password}
              onChange={handleSettingsChange}
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-2 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 "
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
          disabled={isSubmittingSettings}
        >
          {isSubmittingSettings ? (
            <div className="flex gap-2">
              <FaSpinner className="animate-spin my-1" />
              <span>حفظ</span>
            </div>
          ) : (
            "حفظ الإعدادات"
          )}
        </button>
      </form>

      {/* Email Template Form */}
      <form
        onSubmit={handleSubmitTemplate}
        className="bg-white mx-4 rounded-sm shadow-sm p-6 mb-6"
      >
        <h2 className="text-2xl font-bold text-gray-700 mb-4">
          نموذج البريد الإلكتروني
        </h2>
        <Section title="نموذج البريد الإلكتروني">
          <div className="mb-4">
            {/* <label className="block font-medium mb-2">
              قالب البريد الإلكتروني
            </label> */}
            <textarea
              dir="ltr"
              value={emailTemplate}
              style={{ width: "100%", height: "400px" }}
              onChange={handleTemplateChange}
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-2 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100  h-60"
              required
            />
            {/* <SyntaxHighlighter language="html" style={vs} showLineNumbers={false}>
            {emailTemplate}
          </SyntaxHighlighter> */}
          </div>
        </Section>

        <button
          type="submit"
          className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center"
          disabled={isSubmittingTemplate}
        >
          {isSubmittingTemplate ? (
            <div className="flex gap-2">
              <FaSpinner className="animate-spin my-1" />
              <span>حفظ</span>
            </div>
          ) : (
            "حفظ القالب"
          )}
        </button>
      </form>

      {/* Preview Section */}
      <div className="bg-white mx-4 rounded-sm shadow-sm p-6">
        <h2 className="text-2xl text-gray-700 font-bold mb-4">
          معاينة نموذج البريد الإلكتروني
        </h2>
        {CurrentData.length === 0 ? (
          <div className="p-6 text-center">
            <h2 className="text-2xl font-bold">
              لا توجد إعدادات بريد إلكتروني متاحة
            </h2>
            <p className="mt-4">يرجى التحقق لاحقًا أو الاتصال بالدعم.</p>
          </div>
        ) : (
          CurrentData?.map((mail: MailData) => {
            const rawHtml = unescapeHtml(mail?.email_template);

            const templateData = {
              fullname: "Yasser",
              username: "Al-Obaidy",
              message:
                "Thank you for using our service. We hope you have a great experience!",
            };

            const populateTemplate = (
              template: string,
              variables: { [key: string]: string }
            ): string => {
              let populated = template;
              for (const key in variables) {
                const regex = new RegExp(`{{\\s*${key}\\s*}}`, "g");
                populated = populated.replace(regex, variables[key]);
              }
              return populated;
            };

            const populatedHtml = populateTemplate(rawHtml, templateData);

            const sanitizedHtml = DOMPurify.sanitize(populatedHtml);

            const parsedHtml = parse(sanitizedHtml);

            return (
              <div key={mail?.id} className="border-b pb-6">
                <div style={{ overflowX: "auto" }}>{parsedHtml}</div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default MailTemplateDisplay;
