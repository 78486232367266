import React, { useContext, useMemo, useState } from "react";
import Select from "react-select";
import { RepetatioContext } from "../../../../context/devices/RepetatioContext";

interface OptionType {
  value: string;
  label: string;
  repeatDetails: any;
}

interface RepeatationSelectProps {
  onRepeatationSelect: (repeatDetails: any) => void;
}

const RepeatationSelect: React.FC<RepeatationSelectProps> = ({ onRepeatationSelect }) => {
  const { RepeatData } = useContext(RepetatioContext);
  // const defaultId = 7;
  
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  const options = useMemo(() => {
    if (!RepeatData || !RepeatData.data) return [];

    return RepeatData.data.map((repeat: any) => ({
      value: repeat.id,
      label: repeat.name || "no options",
      repeatDetails: repeat,
    }));
  }, [RepeatData]);

  const handleChange = (selected: OptionType | null) => {
    setSelectedOption(selected);
    if (selected) {
      onRepeatationSelect(selected.repeatDetails);
    } else {
      onRepeatationSelect(null);
    }
  };

  return (
    <div className="mt-8">
      <Select
        options={options}
        className="border border-blue-500"
        onChange={handleChange}
        value={selectedOption}
        placeholder="...مرات التكرار"
      />
    </div>
  );
};

export default RepeatationSelect;
