import React, { useCallback, useContext, useEffect, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { MessageApiContext } from "../../../context/messages/MessageApiContext";
// import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import DateFilterr from "./DateFilter";
import { Link } from "react-router-dom";
// import { format } from "date-fns";
import moment from "moment";
import * as XLSX from "xlsx";
import TimePickerComponentGrid from "../messages/campaign/TimePickerComponentGrid";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { CiFilter } from "react-icons/ci";

const gridStyle = { minHeight: 350 };

window.moment = moment;
type FilterValue = {
  name: string;
  operator: string;
  type: string;
  value: string;
};
const DataGrid: React.FC<{ enableFiltering?: boolean }> = () => {
  const context = useContext(MessageApiContext);
  const [cellSelection] = useState({ "2,name": true });
  // const [enableFiltering] = useState(true);
  // const [filteredMessages, setFilteredMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [localFilters, setLocalFilters] = useState({
    status: "",
    type: "",
    to_number: "",
    from_date: "",
    to_date: "",
    Search: "",
  });

  const defaultFilterValue: FilterValue[] = [
    { name: "status", operator: "contains", type: "string", value: "pending" },
  ];

  const [filtersValue] = useState<FilterValue[]>(defaultFilterValue);

  const columns = [
    {
      minWidth: 120,
      name: "messageype",
      defaultFlex: 1,
      header: "مرفق الرسالة",
      enableColumnFilterContextMenu: false,
      render: ({ value }: { value: string }) => {
        try {
          const parsedMessage = JSON.parse(value);

          if (parsedMessage.image) {
            return (
              <div
                className="w-20 h-auto cursor-pointer"
                onClick={() => {
                  setSelectedImage(parsedMessage.image.url);
                  setIsModalOpen(true);
                }}
              >
                <span className="text-center text-blue-500 font-bold">عرض</span>
              </div>
            );
          } else if (parsedMessage.document) {
            return (
              <a
                href={`${parsedMessage.document.url}`}
                className="w-20 h-auto cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-center text-blue-500 font-bold">عرض</span>
              </a>
            );
          }

          if (parsedMessage.text) {
            return <span>{null}</span>;
          }

          return <p>Unknown message format</p>;
        } catch (error) {
          return <p>Invalid message format</p>;
        }
      },
    },
    {
      name: "message",
      header: "الرسالة",
      enableColumnFilterContextMenu: false,
      minWidth: 220,
      render: ({ value }: { value: string }) => {
        try {
          const parsedMessage = JSON.parse(value);

          if (parsedMessage.text) {
            return <p>{parsedMessage.text}</p>;
          }

          return <p></p>;
        } catch (error) {
          return <p>{null}</p>;
        }
      },
    },
    {
      name: "from",
      minWidth: 220,
      header: "المرسل",
      enableColumnFilterContextMenu: false,
    },
    {
      minWidth: 190,
      name: "to",
      header: "المستلم",
      enableColumnFilterContextMenu: false,
    },

    {
      name: "status",
      header: "الحالة",
      enableColumnFilterContextMenu: false,
      minWidth: 160,
      render: ({ value }: { value: number | string }) => {
        // Check if value is valid (number or string)
        if (value === "pending") {
          return (
            <div className="cursor-pointer">
              <p>مرسلة</p>
            </div>
          );
        } else {
          return (
            <div className="cursor-pointer">
              <p>فاشلة</p>
            </div>
          );
        }
      },
    },
    {
      name: "updated_at",
      header: "تاريخ الارسال",
      minWidth: 250,
      enableColumnFilterContextMenu: false,
      filterEditor: DateFilter,
      filterEditorProps: (_props: any, { index }: { index: number }) => {
        return {
          dateFormat: "MM-DD-YYYY",
          cancelButton: false,
          highlightWeekends: false,
          placeholder: index === 1 ? "تاريخ البدابة..." : "الى الان...",
        };
      },
      render: (
        { value }: { value: string },
        { dateFormat }: { dateFormat: string }
      ) => moment(value).format(dateFormat),
    },
    {
      name: "id",
      minWidth: 10,
      header: "عمليات",
      render: ({ value }: { value: number | string }) => {
        // Check if value is valid (number or string)
        if (value) {
          return (
            <div className="cursor-pointer text-blue-500">
              <Link to={`/messages/${value}`}>
                <p>إعادة الارسال</p>
              </Link>
            </div>
          );
        }
        // Fallback if no valid id is found
        return <p>No ID available</p>;
      },
    },
  ];

  const exportToExcel = () => {
    const data = dataSource();

    const worksheetData = [
      // Define Arabic headers
      ["المرسل", "المستلم", "الرسالة", "الحالة", "تاريخ الارسال"],
      ...data.map((message) => [
        message.from,
        message.to,
        message.message,
        message.status === "pending" ? "مرسلة" : "فاشلة",
        moment(message.updated_at).format("YYYY-MM-DD"),
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Messages");

    // Styling: Add RTL and header styles
    worksheet["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 60 },
      { wch: 10 },
      { wch: 20 },
    ]; // Set column widths
    worksheet["A1"].s = {
      font: { bold: true, sz: 14, color: { rgb: "#cbd5e1" } },
      alignment: { horizontal: "center", readingOrder: 2 },
    };
    worksheet["B1"].s = {
      font: { bold: true, sz: 14, color: { rgb: "#cbd5e1" } },
      alignment: { horizontal: "center", readingOrder: 2 },
    };

    XLSX.writeFile(workbook, "Fialure-Messages.xlsx");
  };

  if (!context) {
    throw new Error(
      "MessageApiContext must be used within a MessageApiProvider"
    );
  }

  const { Messages, loadingm, setFilters, filters } = context;

  const filterMessagesByDate = useCallback(
    (startDate: string, endDate: string) => {
      if (!context || !context.Messages) return;

      const filtered = context.Messages?.filter((message: any) => {
        const messageDate = new Date(message.updated_at);
        return (
          messageDate >= new Date(startDate) && messageDate <= new Date(endDate)
        );
      });

      setFilters(filtered);
    },
    [context, setFilters]
  );

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const applyFilters = () => {
    const updatedFilters: any = {};

    if (localFilters.status !== "") {
      updatedFilters.status = localFilters.status;
    }

    if (localFilters.type) {
      updatedFilters.type = localFilters.type;
    }

    if (localFilters.to_number) {
      updatedFilters.to_number = localFilters.to_number;
    }

    if (localFilters.from_date) {
      updatedFilters.from_date = localFilters.from_date;
    }

    if (localFilters.to_date) {
      updatedFilters.to_date = localFilters.to_date;
    }

    if (localFilters.Search) {
      updatedFilters.Search = localFilters.Search;
    }

    setFilters(updatedFilters);
  };

  const dataSource = useCallback(() => {
    return Array.isArray(Messages)
      ? Messages.map((message) => ({
          id: message.id,
          from: message.from,
          to: message.to,
          device_id: message.device_id,
          message: message.message,
          messageype: message.message,
          status: message.status,
          updated_at: message.updated_at,
        }))
      : [];
  }, [Messages]);

  const isData = dataSource();

  // console.log(isData);
  return (
    <div dir="ltr" className="mt-8 mx-5 overflow-x-auto">
      <div className="text-right">{/* <span>الرسائل الناجحة</span> */}</div>
      <div className="sm:flex gap-2 justify-end grid">
        {isData?.length > 0 && (
          <div className="my-2">
            <button
              onClick={exportToExcel}
              className="px-4 py-2.5 sm:mx-0 mx-1 bg-blue-500 text-white rounded"
            >
              Excel استخراج
            </button>
          </div>
        )}
        <DateFilterr onDateFilterChange={filterMessagesByDate} />
      </div>
      <div
        dir="rtl"
        className="grid grid-cols-1 md:grid-cols-3 gap-1 mx-0 p-1 my-2 bg-slate-100 border border-gray-300 rounded-lg"
      >
        {/* Status Filter */}

        {/* Type Filter */}

        <div>
          <label htmlFor="type" className="block mb-0.5">
            نوع الرسالة:
          </label>
          <select
            id="type"
            value={localFilters.type}
            onChange={(e) =>
              setLocalFilters({ ...localFilters, type: e.target.value })
            }
            className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
          >
            <option value="">الكل</option>
            <option value="schedule-message">رسائل مجدولة</option>
            <option value="API">رسائل ال API</option>
          </select>
        </div>

        {/* Search Filter */}

        <div>
          <label htmlFor="search" className="block mb-0.5">
            بحث الرسالة:
          </label>
          <input
            type="text"
            id="search"
            value={localFilters.Search}
            onChange={(e) =>
              setLocalFilters({ ...localFilters, Search: e.target.value })
            }
            className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
            placeholder="بحث..."
          />
        </div>

        {/* To Number Filter */}
        <div>
          <label htmlFor="to_number" className="block mb-0.5">
            الرقم:
          </label>
          <input
            type="text"
            id="to_number"
            value={localFilters.to_number}
            onChange={(e) =>
              setLocalFilters({ ...localFilters, to_number: e.target.value })
            }
            className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
            placeholder="الرقم"
          />
        </div>

        {/* From Date Filter */}
        <div>
          <label htmlFor="from_date" className="block mb-0.5">
            تاريخ البدء:
          </label>
          <TimePickerComponentGrid
            initialValue={
              localFilters.from_date ? new Date(localFilters.from_date) : null
            }
            onChange={(newValue) => {
              const formattedDate =
                newValue instanceof Date ? newValue.toISOString() : "";
              setLocalFilters((prevFilters) => ({
                ...prevFilters,
                from_date: formattedDate,
              }));
            }}
          />
        </div>

        {/* To Date Filter */}
        <div>
          <label htmlFor="to_date" className="block mb-0.5">
            الى نهاية تاريخ:
          </label>
          <TimePickerComponentGrid
            initialValue={
              localFilters.to_date ? new Date(localFilters.to_date) : null
            }
            onChange={(newValue) => {
              const formattedDate =
                newValue instanceof Date ? newValue.toISOString() : "";
              setLocalFilters((prevFilters) => ({
                ...prevFilters,
                to_date: formattedDate,
              }));
            }}
          />
        </div>
        <div>
          <button
            onClick={applyFilters}
            disabled={loadingm}
            className={`px-4 py-1.5 flex sm:mt-6 mt-1 items-center gap-2 bg-indigo-100 rounded ${
              loadingm ? "cursor-not-allowed opacity-50" : "hover:bg-indigo-200"
            } transition-colors duration-200`}
          >
            {loadingm ? (
              <AiOutlineLoading3Quarters className="animate-spin" size={20} />
            ) : (
              <CiFilter size={20} />
            )}
            <span>تطبيق الفلتر</span>
          </button>
        </div>
      </div>
      <ReactDataGrid
        idProperty="id"
        style={gridStyle}
        showColumnMenuTool={false}
        columns={columns}
        pagination
        enableFiltering={false}
        cellSelection={cellSelection}
        // resizable={false}
        defaultLimit={10}
        className="text-gray-800 font-bold"
        // rowHeight={150}
        defaultFilterValue={filtersValue}
        dataSource={isData}
      />
      {isModalOpen && (
        <div className="fixed inset-0 py-4 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <button
              className="mt-12 text-red-500"
              onClick={() => setIsModalOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Preview"
                className="max-w-full max-h-screen"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DataGrid;
