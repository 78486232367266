// src/components/ContentDeviceuser.tsx
import React from "react";
import { Outlet } from 'react-router-dom';
import DeviceMaster from "./DeviceMaster";

const ContentDeviceuser: React.FC = () => {
  return (
    <div className="overflow-y-auto hide-scrollbar ">
      <DeviceMaster/>
      <Outlet />
    </div>
  );
};

export default ContentDeviceuser;
