// import ChartOne from "./ChartOne";
// import ChartTwo from "./ChartTwo";

interface DashboardAdminProps {}

const DashboardAdmin: React.FC<DashboardAdminProps> = () => {
  return (
    <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
      {/* <ChartTwo />
      <ChartOne /> */}
    </div>
  );
};

export default DashboardAdmin;
