import React, { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../../../context/AuthContext";
// import { GroupContactContext } from "../../../../context/contactsgroups/GroupContactContext";
import UserSelected from "../../messages/data/UserSelected";
import UserDepositDisplay from "../balance/UserDepositDisplay";
import { UserDepositHistoryContext } from "../../../../context/balance/UserDepositHistoryContext";
// import { useNavigate } from "react-router-dom";

interface SendResult {
  status: "success" | "error";
  message: string;
}

const DepositStore: React.FC = () => {
  const { token } = useContext(AuthContext);
  const context = useContext(UserDepositHistoryContext);

  if (!context) {
    throw new Error("useContext must be used within a ScheduleProvider");
  }

  const { fetchUserDepositHistorys } = context;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [amount, setAmount] = useState<string>("");
  const [amountError, setAmountError] = useState<string | null>(null);

  const [currency, setCurrencyt] = useState<string>("");
  const [curencyError, setCurrencyError] = useState<string | null>(null);

  const [notes, setNotes] = useState<string>("");
  const [noteError, setNotesError] = useState<string | null>(null);

  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [userError, setUserError] = useState<string | null>(null);

  const [sendResults, setSendResults] = useState<SendResult[]>([]);
  // const navigate = useNavigate();
  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCurrency = e.target.value;
    setCurrencyt(selectedCurrency);

    if (!selectedCurrency) {
      setCurrencyError("اختار العملة.");
    } else {
      setCurrencyError(null);
    }
  };
  const getSendResult = async (data: any) => {
    let result: SendResult;
    let successSend: { text: string; type: "success" | "error" };

    if (data.status === true) {
      result = {
        status: "success",
        message: data.msg,
      };
      successSend = {
        text: data.msg,
        type: "success",
      };
    }else if (data.status === false) {
      result = {
        status: "error",
        message: data.msg,
      };
      successSend = {
        text: data.msg,
        type: "error",
      };
    } else {
      result = { status: "error", message: data.msg };
      successSend = { text: data.msg, type: "error" };
    }

    return { sendResult: result, successSend };
  };

  const clearMessages = () => setSendResults([]);

  const resetErrors = () => {
    setNotesError(null);
    setAmount("");
    setCurrencyt("");
    setSelectedUser(null);
    setNotes("");
    setUserError(null);
    setAmountError(null);
  };

  const validateInputs = (): boolean => {
    if (!selectedUser) {
      setUserError("يجب تحديد اسم الحساب.");
      return false;
    }
    if (!amount) {
      setAmountError("يجب ادخال المبلغ.");
      return false;
    }
    if (!amount) {
      setNotesError("اكتب ملاحظة.");
      return false;
    }

    return true;
  };

  // console.log(selectedGroup?.id);

  const handleCreateGroup = async () => {
    if (!validateInputs()) return;
    setIsLoading(true);
    resetErrors();

    const formData = new FormData();
    formData.append("userid", selectedUser?.id);
    formData.append("amount", `${amount}`);
    formData.append("currencyid", `${currency}`);
    formData.append("note", `${notes}`);
    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/Setting/depositStore",
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      // console.log(response.data);
      const result = await getSendResult(response.data);
      setSendResults((prevResults) => [
        ...prevResults,
        {
          status: result.sendResult.status,
          message: result.sendResult.message,
        },
      ]);
      if (response.data.status === true) {
        fetchUserDepositHistorys();
        resetErrors();
        const timeout = setTimeout(() => {
          // navigate("/groupcontact");
          return clearMessages();
        }, 3000);

        return () => clearTimeout(timeout);
      } else if (response.data.status === false) {
        const timeout = setTimeout(() => {
          return clearMessages();
        }, 3000);
        return () => clearTimeout(timeout);
      }
    } catch (error) {
      console.log("internal server error 500:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-1 mx-2  hide-scrollbar">
      {/* Notifications */}

      {sendResults.length > 0 && (
        <div>
          {sendResults?.map((result, index) => (
            <div
              key={index}
              className={`absolute top-16 right-8 flex gap-3 w-auto p-2  ${
                result.status === "error" ? "bg-red-500" : "bg-green-600"
              } text-white text-sm rounded-lg shadow-lg z-50 opacity-75`}
            >
              <button onClick={clearMessages} className="cursor-pointer mt-1">
                <span className="p-1 text-sm text-gray-100">X</span>
              </button>

              <div className="mt-2">
                <p
                  className={`${
                    result.status === "error" ? "text-white" : "text-white"
                  }`}
                >
                  {result.message}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="sm:w-10/12 mt-2 bg-white rounded-none shadow-md px-4 py-1 mx-auto w-full">
        <div className="mb-1 bg-white rounded-none py-1 px-2 font-bold">
          <span className="text-gray-600 text-xl font-normal">
            سند ايداع - قبض
          </span>
        </div>
        <div className="my-2">
          <button
            type="button"
            className={`inline-flex justify-end gap-2 px-6 py-2 mb-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition ${
              isLoading ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={handleCreateGroup}
            disabled={isLoading}
          >
            {isLoading ? "تأكيد..." : "تأكيد"}
          </button>
        </div>
        <div className="mb-0">
          <label className="block text-sm font-medium text-gray-700 mb-0">
            اسم الحساب
          </label>
          <UserSelected
            onUsersSelect={setSelectedUser}
            selectedOptions={selectedUser}
          />
          {userError && <p className="text-red-500 text-sm">{userError}</p>}
        </div>
        <div className="sm:flex gap-2 flex-1 mb-6 w-full">
          {/* Amount Input */}
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-0">
              المبلغ
            </label>
            <div className="relative">
              <input
                type="number"
                className={`w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 ${
                  amountError ? "border-red-500 focus:ring-red-200" : ""
                }`}
                placeholder="المبلغ"
                value={amount} // Use the raw value
                onChange={(e) => {
                  const rawValue = e.target.value;

                  const sanitizedValue = rawValue.replace(/[^\d.]/g, "");

                  const formattedValue =
                    sanitizedValue.split(".").length > 2
                      ? sanitizedValue.substring(
                          0,
                          sanitizedValue.lastIndexOf(".")
                        )
                      : sanitizedValue;

                  setAmount(formattedValue);
                }}
                onBlur={() => {
                  if (amount) {
                    setAmount(amount);
                  }
                }}
              />

              {amountError && (
                <p className="absolute text-red-500 text-sm mt-1">
                  {amountError}
                </p>
              )}
            </div>
          </div>

          {/* Currency Selector */}
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-0">
              العملة
            </label>
            <select
              name="enc"
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
              value={currency}
              onChange={handleCurrencyChange}
            >
              <option value="">اختر اسم العملة</option>
              <option value="USD">دولا</option>
              <option value="YER">ريال</option>
            </select>
            {curencyError && (
              <p className="text-red-500 text-sm">{curencyError}</p>
            )}
          </div>
        </div>
        <div className="mb-1">
          <label className="block text-sm font-medium text-gray-700 mb-0">
            البيان
          </label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
            placeholder="Enter your text here..."
            rows={3}
          />

          {noteError && <p className="text-red-500 text-sm">{noteError}</p>}
        </div>
      </div>
      <div>
        <UserDepositDisplay />
      </div>
    </div>
  );
};

export default DepositStore;
