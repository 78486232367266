import React, { useContext, useState } from "react";
import { DeviceMasterContext } from "../../../context/devices/DeviceMasterContext";
import ParentCeatDevice from "./ParentCeatDevice";
import { FaSpinner, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { AuthContext } from "../../../context/AuthContext";
import Notification from "../../notifications/Notification";
import ConfirmDialog from "./ConfirmDialog";
import { handleDeleteDevice } from "../../../api/helpers";
import { Link } from "react-router-dom";
import { UserMasterContext } from "../../../context/UserMasterContext";
import { IoIosRedo } from "react-icons/io";
import { LuRefreshCcw } from "react-icons/lu";

const UserDevices: React.FC = () => {
  const { token } = useContext(AuthContext);
  const { deviceData, fetchDeviceMaster } = useContext(DeviceMasterContext);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const { userData,loading,fetchUserMaster } = useContext(UserMasterContext);

  const [expandedUsers, setExpandedUsers] = useState<Record<number, boolean>>(
    {}
  );

  // Toggle the expanded state for a specific user
  const toggleUser = (userId: number) => {
    setExpandedUsers((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
  };

  const [filters, setFilters] = useState({
    name: "",
    phone: "",
  });

  const getUserName = (id: string) => {
    if (!userData || !userData.data) {
      return "No User Data";
    }
    const user = userData.data.find((d: { id: string }) => d.id === id);

    return user ? user.username : "Unknown user";
  };

  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  const sortDevicesByDate = (data: any[]) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);
      return dateB.getTime() - dateA.getTime();
    });
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const applyFilters = (data: any[]) => {
    return data.filter((device) => {
      const matchesName = device.name
        .toLowerCase()
        .includes(filters.name.toLowerCase());

      return matchesName;
    });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value);
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  const sortedDevice = sortDevicesByDate(deviceData?.data ?? []);
  const totalPages = Math.ceil(sortedDevice.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredDevice = applyFilters(sortedDevice);
  const currentDevice = filteredDevice.slice(indexOfFirstItem, indexOfLastItem);

  // const userMap = useMemo(() => {
  //   return userData?.data?.reduce((acc: Record<any, any>, user: any) => {
  //     acc[user.master_user_id] = user;
  //     return acc;
  //   }, {});
  // }, [userData?.data]);

  if (loading) {
    return (
      <div className="text-center py-4">
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
          تحميل...
        </div>
      </div>
    );
  }

  return (
    <div dir="rtl" className="px-4 my-4">
      {/* <TimeNotification /> */}

      <div className="bg-white rounded-sm shadow-sm hide-scrollbar max-h-screen px-0 scrollbar-lightr">
        {/* Desktop Table */}
        <div className="hidden lg:block" dir="rtl">
          <div className="flex p-2 mx-1">
            <div className="flex flex-col lg:flex-row gap-2 mb-4">
              <input
                type="text"
                name="name"
                placeholder="اسم الجهاز"
                value={filters.name}
                onChange={handleFilterChange}
                className="p-2 mt-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300 transition duration-300 ease-in-out transform hover:scale-105"
              />
            </div>
            <button
              onClick={fetchUserMaster}
              className={`${loading ? "cursor-not-allowed opacity-50" : ""}`}
              disabled={loading}
              type="button"
            >
              <LuRefreshCcw className="text-blue-500 mx-4" size={25} />
            </button>
          </div>

          <table className="min-w-full divide-y-1 divide-gray-200">
            <thead className="bg-white border-b-[1px] border-gray-300">
              <tr>
                <th className="px-4 cursor-pointer py-4 flex font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                  <div className="flex">الحساب</div>
                </th>
                <th className="px-4 cursor-pointer py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                  <div className="flex"> اسم الجهاز</div>
                </th>
                <th className="px-4 cursor-pointer py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                  <div className="flex">رقم الواتس اب</div>
                </th>
                <th className="px-4 cursor-pointer py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                  <div className="flex">حالة الاتصال</div>
                </th>
                <th className="px-4 cursor-pointer py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                  <div className="flex">حالة الجهاز</div>
                </th>
                <th className="px-4 cursor-pointer py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                  <div className="flex">تاريخ الانتهاء</div>
                </th>
                {/* <th className="px-2 py-4 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                  عمليات
                </th> */}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {userData?.data?.map((user: any) => {
                // Find devices related to the current user
                const userDevices = currentDevice?.filter(
                  (device: any) => device.id_users === user.id
                );

                return (
                  <React.Fragment key={user.id}>
                    {/* User row with collapse/expand toggle */}

                    {userDevices && loading === false  && userDevices.length > 0
                      ? userDevices.map((device: any) => (
                          <tr key={device.id}>
                            <td
                              className="px-4 py-2 whitespace-nowrap text-xs text-gray-700 font-semibold cursor-pointer"
                              // colSpan={7}
                              onClick={() => toggleUser(user.id)}
                            >
                              {getUserName(user.id)}
                            </td>
                            <td
                              title={`تفاصيل جهاز ${device.name}`}
                              className="px-4 py-2 whitespace-nowrap text-xs text-gray-500"
                            >
                              {device.name}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-500">
                              {device.number}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-xs">
                              <span
                                className={`inline-flex px-2 py-1 text-xs font-semibold leading-5 rounded-full ${
                                  device.status === "AUTHENTICATED"
                                    ? "bg-blue-100 text-blue-800"
                                    : "bg-red-100 text-red-800"
                                }`}
                              >
                                {device.status === "AUTHENTICATED"
                                  ? "متصل"
                                  : "غير متصل"}
                              </span>
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-xs">
                              <span
                                className={`inline-flex px-2 py-1 text-xs font-semibold leading-5 rounded-full ${
                                  device.payment_status === 1
                                    ? "bg-blue-100 text-blue-800"
                                    : "bg-red-100 text-red-800"
                                }`}
                              >
                                {device.payment_status === 1
                                  ? "فعال"
                                  : "غير فعال"}
                              </span>
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-500">
                              {new Date(device.ExpiredDate) < new Date() ? (
                                <span className="bg-red-200 p-1 cursor-pointer rounded-lg text-red-800">
                                  الباقه منتهية
                                </span>
                              ) : (
                                device.ExpiredDate
                              )}
                            </td>
                            <td className="">
                              <div className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 whitespace-nowrap text-center text-xs font-medium">
                                <Link
                                  to={`/user-device-settings/${user.id}`}
                                  className=""
                                >
                                  <IoIosRedo
                                    className="text-gray-600"
                                    size={20}
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-between items-center p-4">
            <div className="flex items-center gap-2">
              <label htmlFor="itemsPerPage" className="text-gray-500">
                الصفوف بكل صفحة:
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className="text-xs text-gray-900 border focus:outline-none border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-300 focus:border-gray-300 p-1 dark:focus:border-gray-400"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={filteredDevice.length}>الكل</option>
              </select>
            </div>
            <div className="flex justify-center gap-4 items-center p-4">
              <button
                onClick={handlePreviousPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === 1}
              >
                <FaArrowLeft />
              </button>
              <span className="text-gray-500">
                صفحة {currentPage} من {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === totalPages}
              >
                <FaArrowRight />
              </button>
            </div>
          </div>
          {/* Pagination */}
        </div>
        {/* Mobile Cards */}

        <div className="lg:hidden" dir="rtl">
          <div>
            {userData?.data?.map((user: any) => {
              // Find devices related to the current user
              const userDevices = currentDevice?.filter(
                (device: any) => device.id_users === user.id
              );

              return (
                <div key={user.id} className="bg-white shadow-md rounded-md">
                  <div className="flex border-b px-2 border-gray-300 py-2 justify-between mb-2">
                    <span className="text-xs font-semibold text-gray-700">
                      الحساب
                    </span>
                    <span className="text-xs text-gray-500">
                      {getUserName(user.id)}
                    </span>
                  </div>

                  {userDevices && userDevices.length > 0 ? (
                    userDevices.map((device: any) => (
                      <div
                        key={device.id}
                        className="border border-gray-300 rounded-sm p-1 mb-1"
                      >
                        <div className="flex justify-between mb-2">
                          <span className="text-xs font-semibold text-gray-700">
                            اسم الجهاز
                          </span>
                          <span title={`تفاصيل جهاز ${device.name}`}>
                            {device.name}
                          </span>
                        </div>

                        <div className="flex justify-between mb-2">
                          <span className="text-xs font-semibold text-gray-700">
                            رقم الهاتف
                          </span>
                          <span className="text-xs text-gray-500">
                            {device.number}
                          </span>
                        </div>

                        <div className="flex justify-between mb-2">
                          <span className="text-xs font-semibold text-gray-700">
                            حالة الاتصال
                          </span>
                          <span
                            className={`inline-flex px-2 py-1 text-xs font-semibold leading-5 rounded-full ${
                              device.status === "AUTHENTICATED"
                                ? "bg-blue-100 text-blue-800"
                                : "bg-red-100 text-red-800"
                            }`}
                          >
                            {device.status === "AUTHENTICATED"
                              ? "متصل"
                              : "غير متصل"}
                          </span>
                        </div>

                        <div className="flex justify-between mb-2">
                          <span className="text-xs font-semibold text-gray-700">
                            حالة الجهاز
                          </span>
                          <span
                            className={`inline-flex px-2 py-1 text-xs font-semibold leading-5 rounded-full ${
                              device.payment_status === 1
                                ? "bg-blue-100 text-blue-800"
                                : "bg-red-100 text-red-800"
                            }`}
                          >
                            {device.payment_status === 1 ? "مفعل" : "غير مفعل"}
                          </span>
                        </div>

                        <div className="flex justify-between mb-2">
                          <span className="text-xs font-semibold text-gray-700">
                            تاريخ الانتهاء
                          </span>
                          <span className="text-xs text-gray-500 mt-2">
                            {new Date(device.ExpiredDate) < new Date() ? (
                              <span className="bg-red-200 p-1 cursor-pointer rounded-lg text-red-800">
                                الباقه منتهية
                              </span>
                            ) : (
                              device.ExpiredDate
                            )}
                          </span>
                        </div>

                        <div className="flex justify-start gap-4">
                          {" "}
                          <div className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 whitespace-nowrap text-center text-xs font-medium">
                            <Link
                              to={`/user-device-settings/${user.id}`}
                              className=""
                            >
                              <IoIosRedo className="text-gray-600" size={20} />
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="px-4 py-4 text-xs text-gray-500">
                      لا توجد أجهزة
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      {isDialogOpen && (
        <ConfirmDialog
          isOpenn={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onConfirm={() =>
            handleDeleteDevice(
              selectedDevice,
              token,
              fetchDeviceMaster,
              setIsLoading,
              setNotification,
              setIsDialogOpen,
              setSelectedDevice
            )
          }
          isLoading={isLoading}
          title="تأكيد الحذف"
          message="هل أنت متأكد أنك تريد حذف هذا الجهاز؟"
        />
      )}
    </div>
  );
};

export default UserDevices;
