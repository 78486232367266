import React, { useState, useContext, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<{
    email?: string;
    generalErr?: string;
    username?: string;
    password?: string;
    general?: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const validate = useCallback(() => {
    const newErrors: {
      email?: string;
      generalErr?: string;
      username?: string;
      password?: string;
      general?: string;
    } = {};

    if (!username) newErrors.username = "مطلوب حقل المستخدم";
    if (!password) newErrors.password = "مطلوب حقل كلمة المرور";
    return newErrors;
  }, [username, password]);

  const handleSubmit = async (e: React.FormEvent) => {
    setErrors(validate());
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setIsLoading(true);
      try {
        await login(username, password, navigate, setErrors);
        setErrors((prevErrors) => ({ ...prevErrors, general: "" }));
      } catch (error) {
        setErrors({ general: "Internal Network Connection" });
      } finally {
        setIsLoading(false);
      }
    }
  };
  // console.log(errors)
  return (
    <div
      dir="rtl"
      className="min-h-screen flex items-center justify-center bg-gray-100 selection:bg-red-100"
    >
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-slate-600 text-center">
          تسجيل الدخول
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">المستخدم</label>
            <input
              type="text"
              className={`w-full p-2 border ${
                errors.username ? "border-red-500" : "border-gray-300"
              } rounded mt-1`}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {errors.username && (
              <p className="text-red-500 text-sm mt-1">{errors.username}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">كلمة المرور</label>
            <input
              type="password"
              className={`w-full p-2 border ${
                errors.password ? "border-red-500" : "border-gray-300"
              } rounded mt-1`}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>
          {errors.general && (
            <p className="text-red-500 text-sm mb-4">{errors.general}</p>
          )}
          {errors.generalErr && (
            <p className="text-red-500 text-sm mb-4">{errors.generalErr}</p>
          )}
          {errors.email && (
            <>
              <p className="text-red-500 text-sm mb-1">{errors.email}</p>
              <div className="mt-1 underline text-blue-400 flex justify-center text-md font-normal">
                <Link to={"/verfy-code-mail"}>تحقق</Link>
              </div>
            </>
          )}
          <button
            type="submit"
            className={`w-full bg-[#2a66b1] text-white p-2 rounded mt-4 ${
              isLoading ? "opacity-75 animate-fade" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? "... تسجيل الدخول" : "تسجيل الدخول"}
          </button>
        </form>
        <div className="mt-4 underline text-blue-400 flex justify-center text-md font-normal">
          <Link to={"/signup"}>إنشاء حساب</Link>
        </div>
        <div className="mt-2 underline text-green-500 flex justify-center text-md font-normal">
          <Link to={"/reset-passowrd"}>نسيت كلمة السر</Link>
        </div>
        {/* <div className="mt-4 underline text-blue-400 flex justify-center text-lg font-normal">
          <Link to={"/login"}>لديك حساب بالفعل</Link>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
