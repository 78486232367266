import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../AuthContext";

interface RepetatioContextType {
  RepeatData: any | null;
  fetchRepeatation: () => void;
}

export const RepetatioContext = createContext<RepetatioContextType>({
  RepeatData: null,
  fetchRepeatation: () => {},
});

const RepetationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [RepeatData, setRepeatData] = useState<any | null>(null);

  // Fetch the latest device master data
  const fetchRepeatation = useCallback(async () => {
    if (!token) return;
    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/Repetition-Type",
        new FormData(),
        {
          headers: {
            'auth-token': token,
          },
          params: {
            token,
          },
        }
      );
      // console.log(response.data);
      setRepeatData(response.data);
    } catch (error) {
      console.log("Failed to fetch device master data", error);
    }
  }, [token]);

  useEffect(() => {
    fetchRepeatation();
  }, [fetchRepeatation]);

  return (
    <RepetatioContext.Provider value={{ RepeatData, fetchRepeatation }}>
      {children}
    </RepetatioContext.Provider>
  );
};

export default RepetationProvider;
