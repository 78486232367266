import React, { useEffect, useState } from "react";

interface SwitchToggleProps {
  initialChecked?: 1 | 0;
  onChange: (checked: 1 | 0) => void;
}

const SwitchToggle: React.FC<SwitchToggleProps> = ({
  initialChecked = 1,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState(initialChecked === 1);

  // Sync internal state with prop updates
  useEffect(() => {
    setIsChecked(initialChecked === 1);
  }, [initialChecked]);

  const handleToggle = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    onChange(newChecked ? 1 : 0);
  };

  return (
    <div
      className={`relative inline-flex items-center cursor-pointer select-none transition-colors duration-300 ease-in-out ${
        isChecked ? "bg-green-500" : "bg-gray-300"
      } rounded-full w-12 h-6`}
      onClick={handleToggle}
    >
      <span
        className={`absolute left-0 top-0 block w-6 h-6 rounded-full bg-white shadow-md transform transition-transform duration-300 ease-in-out ${
          isChecked ? "translate-x-6" : "translate-x-0"
        }`}
      />
    </div>
  );
};

export default SwitchToggle;
