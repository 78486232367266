import React, { useCallback, useContext, useMemo } from "react";
import Select, { SingleValue } from "react-select";
import "./css/GroupsSelect.css";
import { AuthorizeUserContext } from "../../../../context/AuthorizeUserContext";

// Define the structure of a Role
interface Role {
  id: number;
  name: string | null;
  is_superadmin: boolean | null;
  permissions: Record<string, any>;
  theme_color: string | null;
  user_master_id: number;
  created_at: string | null;
  updated_at: string | null;
}

interface OptionType {
  value: number;
  label: string | null;
  authDetails: Role;
}

interface AuthSelectProps {
  onAuthSelect: (authDetails: Role) => void;
  selectedOptions: SingleValue<OptionType> | null;
  selectedAuthId: number | null;
}

const AuthSelect: React.FC<AuthSelectProps> = ({
  onAuthSelect,
  selectedOptions,
  selectedAuthId,
}) => {
  const { AuthorizeUserData } = useContext(AuthorizeUserContext);

  // Use useMemo to map roles into select options
  const options = useMemo(() => {
    if (
      !AuthorizeUserData ||
      !AuthorizeUserData.Roles ||
      !Array.isArray(AuthorizeUserData.Roles)
    )
      return [];
    return AuthorizeUserData.Roles.map((auth: Role) => ({
      value: auth.id,
      label: auth.name || "لا يوجد صلاحيات",
      authDetails: auth,
    }));
  }, [AuthorizeUserData]);

  const handleChange = useCallback(
    (selected: SingleValue<OptionType>) => {
      if (selected) {
        onAuthSelect(selected.authDetails);
      }
    },
    [onAuthSelect]
  );

  const selectedValue = useMemo(() => {
    return options.find((option:any) => option?.value === selectedAuthId) || null;
  }, [options, selectedAuthId]);
// console.log(selectedValue)
  return (
    <div className="mt-3 mb-4">
      <Select
        className="border border-blue-500"
        onChange={handleChange}
        options={options}
        value={selectedValue}
        placeholder="...اختار صلاحيات"
        isClearable
      />
    </div>
  );
};

export default AuthSelect;
