import axios from "axios";
type NotificationType = { message: string; type: "success" | "error" };

export const handleOpen = async (
  device: any,
  setSelectedDevice: React.Dispatch<React.SetStateAction<any | null>>
) => {
  setSelectedDevice(device);
};

export const handleGetDevice = async (
  selectedDevice: any,
  token: string | null,
  fetchDeviceMaster: () => void,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsQrCodeModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedDevice: React.Dispatch<React.SetStateAction<any | null>>,
  setQrCodeData: React.Dispatch<React.SetStateAction<string | null>>,
  setNotification: React.Dispatch<
    React.SetStateAction<NotificationType | null>
  >
) => {
  if (selectedDevice) {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/getQr",
        {
          deviceToken: selectedDevice.Token,
          deviceId: selectedDevice.deviceId,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      // console.log(response.data);

      if (response.data.status === true) {
        setQrCodeData(response.data.data.qr);
        // console.log(response.data.data.qr);
        // console.log(response.data);
        fetchDeviceMaster();
        setNotification({
          message: "تم إنشاء رمز الاستجابة السريعة بنجاح",
          type: "success",
        });
      } else {
        setNotification({
          message: "فشل في إنشاء رمز الاستجابة السريعة!",
          type: "error",
        });
      }
    } catch (error) {
      setNotification({ message: "حدث خطأ", type: "error" });
    } finally {
      setIsLoading(false);
      setIsQrCodeModalOpen(true);
      setSelectedDevice(selectedDevice);
    }
  }
};

// export const startSessionCheck = async (
//   selectedDevice: any,
//   token: string | null,
//   fetchDeviceMaster: () => void
// ) => {
//   try {
//     const response = await axios.post(
//       "https://click.one.sky4system.com/api/user/checkSession",
//       {
//         deviceId: selectedDevice.deviceId,
//         deviceToken: selectedDevice.Token,
//       },
//       {
//         headers: {
//           "auth-token": token,
//         },
//       }
//     );

//     if (response.data.msg === "This is Device is  Connected ") {
//       // console.log("Session Connected: ", response.data);
//       fetchDeviceMaster();
//     } else {
//       console.log("Waiting for QR code scan...");
//     }
//   } catch (error) {
//     console.error("Error checking session: ", error);
//   } finally {
//     startSessionCheck(selectedDevice, token, fetchDeviceMaster);
//   }
// };

// export const handleUpdateChangeDevice = async (
//   selectedDevice: any,
//   token: string | null,
//   setIsQrCodeModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
//   setSelectedDevice: React.Dispatch<React.SetStateAction<any | null>>,
//   setIsLoadingC: React.Dispatch<React.SetStateAction<boolean>>,
//   setNotification: React.Dispatch<
//     React.SetStateAction<NotificationType | null>
//   >,
//   fetchDeviceMaster: () => void
// ) => {
//   if (selectedDevice) {
//     setIsLoadingC(true);
//     try {
//       const response = await axios.post(
//         "https://click.one.sky4system.com/api/user/logoutSession",
//         {
//           deviceId: selectedDevice.deviceId,
//           deviceToken: selectedDevice.Token,
//         },
//         {
//           headers: {
//             "auth-token": token,
//           },
//         }
//       );
//       console.log(response.data);
//       if (response.data.status === true) {
//         console.log("disconnected: ", response.data);
//         setNotification({
//           message: "تم فصل الجهاز عن الواتس أب بنجاح",
//           type: "success",
//         });
//         fetchDeviceMaster();
//       }
//     } catch (error) {
//       console.error("Error checking disconnecting: ", error);
//     } finally {
//       setIsLoadingC(false);
//       setSelectedDevice(selectedDevice);
//       setIsQrCodeModalOpen(true);
//     }
//   }
// };

export const handleUpdateChangeDevice = async (
  selectedDevice: any,
  token: string | null,
  setIsQrCodeModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedDevice: React.Dispatch<React.SetStateAction<any | null>>,
  setIsLoadingC: React.Dispatch<React.SetStateAction<boolean>>,
  setNotification: React.Dispatch<React.SetStateAction<NotificationType | null>>,
  fetchDeviceMaster: () => void
) => {
  if (selectedDevice) {
    setIsLoadingC(true);
    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/logoutSession",
        {
          deviceId: selectedDevice.deviceId,
          deviceToken: selectedDevice.Token,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      console.log(response.data);
      if (response.data.status === true) {
        console.log("disconnected: ", response.data);
        setNotification({
          message: "تم فصل الجهاز عن الواتس أب بنجاح",
          type: "success",
        });
        fetchDeviceMaster();
      }
    } catch (error) {
      console.error("Error checking disconnecting: ", error);
      setNotification({
        message: "فشل في فصل الجهاز!",
        type: "error",
      });
    } finally {
      setIsLoadingC(false);
      setSelectedDevice(null);
      setIsQrCodeModalOpen(false);
    }
  }
};
