import React, { useCallback, useContext, useMemo } from "react";
import Select, { SingleValue } from "react-select";
import { GroupsContext } from "../../../../context/contactsgroups/GroupsContext";
import "./css/GroupsSelect.css";

interface OptionType {
  value: string;
  label: string;
  groupDetails?: any;
}

interface GroupsSelectProps {
  onGroupsSelect: (groupDetails: any) => void;
  selectedOptions: SingleValue<OptionType> | null;
  selectedDeviceId: any | null;
}

const GroupsSelectCode: React.FC<GroupsSelectProps> = ({
  onGroupsSelect,
  selectedOptions,
  selectedDeviceId,
}) => {
  const { GroupsData } = useContext(GroupsContext);

  // Use useMemo to map groups into select options
  const options = useMemo(() => {
    if (!GroupsData || !Array.isArray(GroupsData)) return [];

    return GroupsData.flatMap((item: any) => item.data.data)
      .filter(
        (group: any) =>
          group && group.sessionId && group.sessionId === selectedDeviceId
      )
      .map((group: any) => ({
        value: group.id,
        label: group.name || "لا يوجد مجموعات",
        groupDetails: group,
      }));
  }, [GroupsData, selectedDeviceId]);

  const handleChange = useCallback(
    (selected: SingleValue<OptionType>) => {
      if (selected) {
        onGroupsSelect(selected.groupDetails);
      }
    },
    [onGroupsSelect]
  );

  return (
    <div className="mt-3 mb-4">
      <Select
        className="border border-blue-500"
        onChange={handleChange}
        options={options}
        placeholder="...اختار المجموعات"
      />
    </div>
  );
};

export default GroupsSelectCode;
