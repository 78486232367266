// src/components/ReoprtContents.tsx
import React from "react";
import CartList from "./details/CartList";

const ReoprtContents: React.FC = () => {
  return (
    <div className="overflow-y-auto hide-scrollbar ">
      <CartList />
    </div>
  );
};

export default ReoprtContents;
