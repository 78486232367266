// src/AuthContext.tsx
import React, { createContext, useState, ReactNode } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
// import { randomBytes } from "crypto";

type PermissionValue = number | Permission;

type Permission = {
  [label: string]: PermissionValue;
};

type PermissionsState = {
  [group: string]: {
    [permission: string]: Permission;
  };
};
interface AuthContextType {
  isAuthenticated: boolean;
  userId: number | null;
  token: string | null;
  isagent: number | null;
  username: string | null;
  usernamee: string | null;
  role_name: string | null;
  permissions: PermissionsState;
  login: (
    username: string,
    password: string,
    navigate: ReturnType<typeof useNavigate>,
    setErrors: (errors: Record<string, string>) => void
  ) => Promise<void>;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isagent: null,
  userId: null,
  token: null,
  username: null,
  role_name: null,
  usernamee: null,
  permissions: {},
  login: async () => {},
  logout: () => {},
});

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const secretKey = "yJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9";

  // console.log("Generated Secret Key:", secretKey);

  const [username, setUsername] = useState<string | null>(null);
  const [role_name, setRole_name] = useState<string | null>(null);
  // const [permissions, setPermissions] = useState<PermissionsState>(() => {
  //   const storedPermissions = localStorage.getItem("permissions");
  //   return storedPermissions
  //     ? JSON.parse(storedPermissions)
  //     : {};
  // });
  // const [isagent, setisagent] = useState<number | null>(localStorage.getItem("isagent"));

  // Decrypt permissions if they were encrypted before storing

  const decryptPermissions = (
    encryptedData: string,
    secretKey: string
  ): PermissionsState | null => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData ? JSON.parse(decryptedData) : null;
    } catch (error) {
      console.error("Decryption of permissions failed:", error);
      return null;
    }
  };

  const [permissions, setPermissions] = useState<PermissionsState>(() => {
    const storedPermissions = localStorage.getItem("permissions");
    if (storedPermissions) {
      const decrypted = decryptPermissions(storedPermissions, secretKey);
      if (decrypted) {
        return decrypted;
      }

      try {
        return JSON.parse(storedPermissions);
      } catch (error) {
        console.error("Failed to parse permissions:", error);
        return {};
      }
    }
    return {};
  });

  const decryptisagent = (
    encryptedData: string,
    secretKey: string
  ): number | null => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return parseInt(decryptedData, 10);
    } catch (error) {
      console.error("Decryption failed:", error);
      return null;
    }
  };

  // Initialize the isagent state with decryption
  const [isagent, setisagent] = useState<number | null>(() => {
    const storedValue = localStorage.getItem("isagent");
    return storedValue !== null
      ? decryptisagent(storedValue, secretKey)
      : null;
  });
  const [userId, setUserId] = useState<number | null>(() => {
    const storedValue = localStorage.getItem("userid");
    return storedValue !== null
      ? decryptisagent(storedValue, secretKey)
      : null;
  });
  const [usernamee, setUsernamee] = useState<string | null>(
    localStorage.getItem("usernamee")
  );

  // console.log(isagent)

  const login = async (
    username: string,
    password: string,
    navigate: ReturnType<typeof useNavigate>,
    setErrors: (errors: Record<string, string>) => void
  ) => {
    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      const response = await axios.post(
        `https://click.one.sky4system.com/api/user/login`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data)
      if (response.data.status === true) {
        const token = response.data.user.token;
        const isagent: number = response.data.user.isagent;
        const userId: number = response.data.user.id;
        const usernamee = response.data.user.username;
        const role_name = response.data.user.role_name;
        const permissions = response.data.user.permissions;

        const encryptedData = CryptoJS.AES.encrypt(
          isagent.toString(),
          secretKey
        ).toString();
        const encryptedDataUId = CryptoJS.AES.encrypt(
          userId.toString(),
          secretKey
        ).toString();

        setToken(token);
        setUsername(usernamee);
        setisagent(isagent);
        setUserId(userId);
        setUsernamee(usernamee);
        setRole_name(role_name);

        setPermissions(permissions);

        // console.log(userId)

        // Optionally, encrypt permissions before storing

        const encryptedPermissions = CryptoJS.AES.encrypt(
          JSON.stringify(permissions),
          secretKey
        ).toString();
        localStorage.setItem("permissions", encryptedPermissions);

        // localStorage.setItem("permissions", JSON.stringify(permissions));
        localStorage.setItem("token", token);
        localStorage.setItem("userid", encryptedDataUId);
        localStorage.setItem("isagent", encryptedData);
        localStorage.setItem("usernamee", usernamee);
        localStorage.setItem("role_name", role_name);

        // Store token in an HttpOnly cookie

        // Cookies.set("token", token, { httpOnly: true, secure: true });
        // Cookies.set("isagent", isagent, { httpOnly: true, secure: true });
        // Cookies.set("usernamee", usernamee, { secure: true });

        navigate("/dashboard");
      } else {
        if (response.data.errNum === "E001") {
          setErrors({ generalErr: "بيانات الدخول غير صحيح." });
        }
        else if (response.data.errNum === "401") {
          setErrors({ email: "يرجى التحقق من عنوان بريدك الإلكتروني للتحقق من حسابك عن طريق إرسال الرمز إلى البريد الإلكتروني." });
        }
        
        else {
          setErrors({ general: response.data.msg });
        }
      }
    } catch (error) {
      throw new Error("Invalid credentials");
    }
  };

  const logout = () => {
    setToken(null);
    setUsername(null);
    setRole_name(null);
    setisagent(null);
    setUsernamee(null);
    setUserId(null);
    setPermissions({});

    // Clear cookies
    localStorage.removeItem("permissions");
    localStorage.removeItem("token");
    localStorage.removeItem("usernamee");
    localStorage.removeItem("role_name");
    localStorage.removeItem("isagent");
    localStorage.removeItem("userid");
  };

  setTimeout(() => {
    logout();
  }, 86400000);
  // 86400000  3 days
  // 1 * 24 * 60 * 60 * 1000  86400000   3 day
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!token,
        isagent,
        userId,
        token,
        username,
        usernamee,
        role_name,
        permissions,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
