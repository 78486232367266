// GenerateOtpContext.tsx
import React, { createContext, ReactNode, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface GenerateOtpContextType {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  generateOtp: (
    email: string,
    setErrors: (errors: Record<string, string>) => void
  ) => Promise<void>;
}

export const GenerateOtpContext = createContext<GenerateOtpContextType>({
  email: "",
  setEmail: () => {},
  generateOtp: async () => {
    console.warn("generateOtp function is not implemented");
  },
});

const GenerateOtpProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [email, setEmail] = useState(() => {
    return localStorage.getItem("email") || "";
  });
  const navigate = useNavigate();

  const generateOtp = async (
    email: string,
    setErrors: (errors: Record<string, string>) => void
  ) => {
    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/generate_otp",
        { email }
      );
      console.log(response.data);

      if (response.data.status === true) {
        localStorage.setItem("email", email);
        navigate("/verify-code");
      } else {
        if (response.data.errNum === "404"){
          setErrors({ usererror: 'عنوان البريد الاكتروني غير موجود' });
        }else{
          setErrors({ general: response.data.msg });
        }
      }
    } catch (error) {
      setErrors({ general: "An error occurred. Please try again later." });
    }
  };

  return (
    <GenerateOtpContext.Provider value={{ email, setEmail, generateOtp }}>
      {children}
    </GenerateOtpContext.Provider>
  );
};

export default GenerateOtpProvider;
