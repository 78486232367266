import React, { useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";

interface PermissionDetail {
  [key: string]: number | PermissionDetail | [];
}

interface PermissionsDisplayProps {
  permissions: PermissionDetail | undefined;
}

const PermissionsDisplay: React.FC<PermissionsDisplayProps> = ({
  permissions,
}) => {
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);

  const toggleCategory = (category: string) => {
    setExpandedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  // Add a fallback for undefined or null permissions
  if (!permissions) {
    return <div>No permissions available</div>;
  }

  return (
    <div className="text-right">
      {Object.entries(permissions).map(([category, actions], idx) => (
        <div key={idx} className="mb-2">
          <button
            onClick={() => toggleCategory(category)}
            className="flex items-center justify-between w-full px-2 py-1 bg-gray-100 rounded-md hover:bg-gray-200 transition"
          >
            <span className="font-semibold">{category}</span>
            {expandedCategories.includes(category) ? (
              <FaChevronUp />
            ) : (
              <FaChevronDown />
            )}
          </button>
          {expandedCategories.includes(category) && (
            <div className="mt-1 ml-4">
              {typeof actions === "object" && !Array.isArray(actions) ? (
                Object.entries(actions).map(([action, detail], actionIdx) => (
                  <div key={actionIdx} className="mb-1">
                    <ul className="list-disc list-inside">
                      {typeof detail === "object" && !Array.isArray(detail) ? (
                        Object.entries(detail).map(([desc, value], descIdx) => (
                          <li key={descIdx} className="flex items-center">
                            {value === 1 ? (
                              <FaCheckCircle className="text-green-500 mr-2" />
                            ) : (
                              <FaTimesCircle className="text-red-500 mr-2" />
                            )}
                            <span>{desc}</span>
                          </li>
                        ))
                      ) : (
                        <li className="flex items-center">
                          {detail === 1 ? (
                            <FaCheckCircle className="text-green-500 mr-2" />
                          ) : (
                            <FaTimesCircle className="text-red-500 mr-2" />
                          )}
                          <span>{detail}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                ))
              ) : (
                <div className="flex items-center">
                  {actions === 1 ? (
                    <FaCheckCircle className="text-green-500 mr-2" />
                  ) : (
                    <FaTimesCircle className="text-red-500 mr-2" />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PermissionsDisplay;
