import React, { useCallback, useContext, useMemo, useState } from "react";
import Select, { MultiValue } from "react-select";
import "./css/GroupsSelect.css";
import { ContactContext } from "../../../../context/contactsgroups/ContactContext";
import colourStyles from "./colourStyles";
// import { DeviceMasterContext } from "../../../../context/devices/DeviceMasterContext";

interface OptionType {
  value: string;
  label: string;
  contactDetails?: any;
}

interface ContactSelectProps {
  onContactSelect: (selectedGroupIds: string[]) => void;
  selectedOptions: string[];
  selectedDeviceId: any | null;  
}

const ContactSelect: React.FC<ContactSelectProps> = ({
  onContactSelect,
  selectedOptions = [], 
  selectedDeviceId,
}) => {
  const { ContactsData } = useContext(ContactContext);
  // const { deviceData } = useContext(DeviceMasterContext);
  const [selectAll, setSelectAll] = useState<boolean>(false);
 
  // Use memoization to avoid recalculating options unless ContactsData changes
  const options = useMemo(() => {
    if (!ContactsData || !Array.isArray(ContactsData)) return [];

    // console.log(ContactsData);

    // Flattening the nested data and filtering valid contacts
    return ContactsData.flatMap((item: any) => item?.data?.data)
      .filter((contact: any) => 
        contact && contact.sessionId && contact.sessionId === selectedDeviceId
      )
      .map((contact: any) => {
        const numericId = contact.id.split('@')[0]
        return {
          value: numericId,
          label: contact.name || "لا يوجد جهات اتصال",
          contactDetails: contact,
        };
      });
  }, [ContactsData,selectedDeviceId]);

  // Handle changes in the selection
  const handleChange = useCallback(
    (newValue: MultiValue<OptionType>) => {
      const selected = newValue as OptionType[];
      const selectedGroupIds = selected.map((option) => option.value);
      onContactSelect(selectedGroupIds);
    },
    [onContactSelect]
  );

  // Handle "Select All" functionality
  const handleSelectAllChange = useCallback(() => {
    if (selectAll) {
      onContactSelect([]);
    } else {
      const allGroupIds = options.map((option) => option.value);
      onContactSelect(allGroupIds);
    }
    setSelectAll(!selectAll);
  }, [onContactSelect, options, selectAll]);

  // Custom select-all checkbox component
  const customSelectAllOption = () => (
    <div className="select-all-option">
      <input
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAllChange}
        id="select-all"
      />
      {/* <label htmlFor="select-all" className="ml-2">
        اختار الكل
      </label> */}
    </div>
  );

  return (
    <div className="mt-8">
      <Select
        options={options}
        isMulti
        onChange={handleChange}
        value={options.filter(
          (option) =>
            Array.isArray(selectedOptions) &&
            selectedOptions.includes(option.value)
        )}
        components={{ DropdownIndicator: customSelectAllOption }}
        placeholder="...اختار جهات الاتصال"
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        styles={colourStyles}
      />
    </div>
  );
};

export default ContactSelect;
