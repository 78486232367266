import React, { useContext, useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { AuthorizeUserContext } from "../../../../context/AuthorizeUserContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../context/AuthContext";
import CheckBoxToggle from "../../devices/settings/CheckBoxToggle";
import { IoMdArrowForward } from "react-icons/io";

interface SendResult {
  number: string;
  status: "success" | "error";
  message: string;
  isLoadingr?: boolean;
}
type PermissionValue = number | Permission;

type Permission = {
  [label: string]: PermissionValue;
};

type PermissionsState = {
  [group: string]: {
    [permission: string]: Permission;
  };
};

const UpdateAuthorizeUsersId: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { token } = useContext(AuthContext);

  const {
    fetchAuthorizeUserById,
    fetchAuthorizeUserData,
    loading,
    setLoading,
  } = useContext(AuthorizeUserContext);
  const [sendResults, setSendResults] = useState<SendResult[]>([]);
  const [authName, setauthName] = useState("");
  const [isLoadings, setIsLoading] = useState<boolean>(false);
  const [authNameError, setauthNameError] = useState<string | null>("");
  const [permissions, setPermissions] = useState<PermissionsState>({});

  const [successSend, setSuccessSend] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);
  const navigate = useNavigate();

  const resetErrors = () => {
    setauthNameError(null);
    setSendResults([]);
  };
  const clearMessages = () => setSendResults([]);

  const getSendResult = async (data: any, number: string) => {
    let result: SendResult;
    let successSend: { text: string; type: "success" | "error" };

    if (data.errNum === "S000") {
      result = {
        number,
        status: "success",
        message: `تم تأكيد العملية بنجاح `,
      };
      successSend = {
        text: `تمت عملية التأكيد `,
        type: "success",
      };
    } else if (data.errNum === "403") {
      result = {
        number,
        status: "error",
        message: `Error occure 403`,
      };
      successSend = {
        text: `Error occure 403`,
        type: "error",
      };
    } else if (data.errNum === "401") {
      result = {
        number,
        status: "error",
        message: `Error occure 401`,
      };
      successSend = { text: `Error occure 401`, type: "error" };
    } else {
      result = { number, status: "error", message: `فشل في تأكيد البيانات` };
      successSend = { text: `فشل في تحديث البيانات `, type: "error" };
    }
    setSendResults((prevResults) => {
      const existingResultIndex = prevResults.findIndex(
        (r) => r.number === number
      );

      if (existingResultIndex !== -1) {
        return prevResults.map((r, i) =>
          i === existingResultIndex ? { ...r, ...result } : r
        );
      } else {
        return [...prevResults, result];
      }
    });

    return { sendResult: result, successSend };
  };

  const validateInputs = (): boolean => {
    if (!authName) {
      setauthNameError("يجب كتابة اسم صلاحية المستخدم.");
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (fetchAuthorizeUserById && id) {
        setLoading(true);
        try {
          const AuthDataUser = await fetchAuthorizeUserById(id);
          // console.log(AuthDataUser);
          if (AuthDataUser) {
            const { name, permissions: userPermissions } = AuthDataUser || {};
            // console.log(userPermissions)
            setauthName(name || "");
            setPermissions(userPermissions || {});
          } else {
            console.log("No user data received");
          }
        } catch (error) {
          console.log("Error fetching user data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [id, fetchAuthorizeUserById, setLoading]);

  const handleUpdateAuthUser = async () => {
    resetErrors();
    if (!validateInputs()) return;
    setIsLoading(true);

    // const formData = new FormData();
    // formData.append("id", `${id}`);
    const formData = {
      id: id,
      name: authName,
      permissions: permissions,
    };

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/Setting/Save-Role",
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      if (response.data.status === true) {
        fetchAuthorizeUserData();
        // console.log(response.data);
        const timeout = setTimeout(() => {
          navigate("/authorize-user");
        }, 1000);
        return () => clearTimeout(timeout);
      } else {
        setTimeout(() => {
          clearMessages();
        }, 2000);
      }
      const result = getSendResult(response.data, "");
      setSuccessSend((await result).successSend);
      return (await result).sendResult;
    } catch (error) {
      console.log("internal server error 500:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // const handlePermissionChange = (permissionKey: string) => {
  //   const [group, item] = permissionKey.split(".");
  //   setPermissions((prevPermissions) => {
  //     const currentValue = prevPermissions[group][item];
  //     const newValue = currentValue === 1 ? 0 : 1;
  //     return {
  //       ...prevPermissions,
  //       [group]: {
  //         ...prevPermissions[group],
  //         [item]: newValue,
  //       },
  //     };
  //   });
  // };

  const handlePermissionChange = (permissionKey: string) => {
    // Use '||' as the delimiter to avoid conflicts with existing dots in keys
    const keys = permissionKey.split("||");
    const [group, item, subItem] = keys;

    setPermissions((prevPermissions: any) => {
      const currentGroup = { ...(prevPermissions[group] ?? {}) };
      const currentItem = { ...(currentGroup[item] ?? {}) };

      if (subItem) {
        const currentValue = currentItem[subItem] as number | undefined;
        const newValue = currentValue === 1 ? 0 : 1;

        return {
          ...prevPermissions,
          [group]: {
            ...currentGroup,
            [item]: {
              ...currentItem,
              [subItem]: newValue,
            },
          },
        };
      } else {
        const currentValue = currentItem as number | undefined;
        const newValue = currentValue === 1 ? 0 : 1;

        return {
          ...prevPermissions,
          [group]: {
            ...currentGroup,
            [item]: newValue,
          },
        };
      }
    });
  };

  // console.log(currentDevice);

  // const getUserName = (user_master_id: number) => {
  //   if (!userData || !userData.data) {
  //     return "No User Data";
  //   }
  //   const user = userData?.data.find(
  //     (d: { master_user_id: number }) => d.master_user_id === user_master_id
  //   );
  //   // console.log(user)
  //   return user ? user.username : "Unknown user";
  // };

  if (loading) {
    return (
      <div className="text-center py-4">
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
          تحميل...
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 my-4 overflow-auto" dir="rtl">
      {/* <TimeNotification /> */}

      <div className="mt-8 mx-2 flex justify-center gap-4 hide-scrollbar">
        {/* Notifications */}

        {sendResults.length > 0 && (
          <div>
            {sendResults?.map((result, index) => (
              <div
                key={index}
                className={`absolute top-16 right-8 flex gap-3 w-auto p-2  ${
                  result.status === "error" ? "bg-red-500" : "bg-green-600"
                } text-white text-sm rounded-lg shadow-lg z-50 opacity-75`}
              >
                <button onClick={clearMessages} className="cursor-pointer mt-1">
                  <span className="p-1 text-lg text-gray-100">X</span>
                </button>

                <div className="mt-2">
                  <p
                    className={`${
                      result.status === "error" ? "text-white" : "text-white"
                    }`}
                  >
                    {result.message}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="sm:w-10/12 mx-auto w-full">
          <div className="my-6 absolute top-12">
            {successSend && (
              <p
                className={`text-sm mt-1 firework-message ${
                  successSend.type === "error" ? "error-message" : ""
                }`}
              >
                {successSend.text}
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </p>
            )}
          </div>
          <button
            onClick={() => navigate(-1)}
            className="flex items-center mb-4 ةف-6 rounded-lg p-2 hover:bg-gray-100 transition"
            aria-label="Go Back"
          >
            <IoMdArrowForward className="w-6 h-6 mr-2" />
            <span>رجوع</span> {/* Optional: Add text for better UX */}
          </button>
          <div className="mb-4">
            <div className="items-left w-3xl">
              <label htmlFor="authName">
                <span className="mr-4">اسم الصلاحية</span>
              </label>
              <div className="mb-4">
                <input
                  type="text"
                  value={authName || ""}
                  className={`w-full p-2 border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-2.5 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 rounded mt-1`}
                  onChange={(e) => setauthName(e.target.value)}
                  id="authName"
                  name="authName"
                />
              </div>
              {authNameError && (
                <p className="text-red-500 text-sm">{authNameError}</p>
              )}
            </div>
          </div>
          {Object.entries(permissions).map(([groupKey, groupValue]) => (
            <div key={groupKey} className="mb-2">
              <h3 className="font-semibold">{groupKey}</h3>
              {Object.entries(groupValue).map(([itemKey, itemValue]) => {
                if (typeof itemValue === "object" && itemValue !== null) {
                  return (
                    <div key={`${groupKey}||${itemKey}`} className="ml-4">
                      {/* <h4 className="font-medium">{itemKey}</h4> */}
                      {Object.entries(itemValue).map(
                        ([subItemKey, subItemValue]) => (
                          <div
                            key={`${groupKey}||${itemKey}||${subItemKey}`}
                            className="flex items-center ml-4"
                          >
                            <CheckBoxToggle
                              initialChecked={Boolean(subItemValue)} // Convert number to boolean
                              onChange={() =>
                                handlePermissionChange(
                                  `${groupKey}||${itemKey}||${subItemKey}`
                                )
                              }
                            />
                            <span className="ml-2">{subItemKey.trim()}</span>
                          </div>
                        )
                      )}
                    </div>
                  );
                }

                // Render top-level permissions (non-nested)
                return (
                  <div
                    key={`${groupKey}||${itemKey}`}
                    className="flex items-center"
                  >
                    <CheckBoxToggle
                      initialChecked={Boolean(itemValue)} // Convert number to boolean
                      onChange={() =>
                        handlePermissionChange(`${groupKey}||${itemKey}`)
                      }
                    />
                    <span className="ml-2">{itemKey.trim()}</span>
                  </div>
                );
              })}
            </div>
          ))}

          <div className="my-4">
            <button
              type="button"
              className={`inline-flex justify-end gap-2 px-6 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition ${
                isLoadings ? "cursor-not-allowed opacity-50" : ""
              }`}
              onClick={handleUpdateAuthUser}
              disabled={isLoadings}
            >
              {isLoadings ? "جاري التأكيد..." : "تأكيد"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAuthorizeUsersId;
