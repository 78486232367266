// src/components/TextComponent.tsx
import React from "react";
import RichTextEditor from "./RichTextEditor";
import { AiFillFileText } from "react-icons/ai";

const TextComponent: React.FC = () => {

  return (
    <div className="justify-start container mt-14">
      <div className="flex justify-start gap-3 sm:w-10/12 mx-auto text-lg mt-8">
        <span>ارسال رسائل نصية</span>
        <AiFillFileText size={25} className="text-cyan-600"/>
        </div>

      <RichTextEditor />
    </div>
  );
};

export default TextComponent;
