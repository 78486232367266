import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthProvider from "./context/AuthContext";
import Login from "./pages/auth/Login";
import NotFound from "./pages/NotFound";
import PrivateRoute from "./routes/PrivateRoute";
import DeviceMasterProvider from "./context/devices/DeviceMasterContext";
import UserMasterProvider from "./context/UserMasterContext";
import Layout from "./components/layouts/Layout";
import Contentt from "./pages/Content";
import SignUp from "./pages/auth/SignUp";
import CreateUserProvider from "./context/CreateUserContext";
import CreateDeviceProvider from "./context/devices/CreatDeviceContex";
import OpenDesviceDetials from "./pages/dashboard/devices/OpenDesviceDetials";
import TextComponent from "./pages/dashboard/messages/TextComponent";
import Content from "./pages/dashboard/Content";
import MediaComponent from "./pages/dashboard/messages/MediaComponent";
import EditProfile from "./pages/dashboard/profile/EditProfile";
import OrderPage from "./pages/dashboard/orders/OrderPage";
import DeviceOrderProvider from "./context/devices/DeviceOrederContext";
import PricingSection from "./pages/dashboard/orders/PricingSection";
import DevicePeriodContextProvider from "./context/devices/DevicePeriodContext";
import OrderSubscripPage from "./pages/dashboard/orders/OrderSubscripPage";
import EditPromotion from "./pages/dashboard/orders/EditPromotion";
import ProtectedRoute from "./routes/ProtectedRoute";
import PlanContextProvider from "./context/devices/PlanContext";
import UpdatePlan from "./pages/dashboard/orders/UpdatePlan";
// import ContactProvider from "./context/contactsgroups/ContactContext";
import DeviceUseDetails from "./pages/dashboard/devices/DeviceUseDetails";
import GroupsProvider from "./context/contactsgroups/GroupsContext";
import TextGroupComponent from "./pages/dashboard/messages/TextGroupComponent";
import MediaGroupComponent from "./pages/dashboard/messages/MediaGroupComponent";
import CompaignComponent from "./pages/dashboard/messages/campaign/CompaignComponent";
import ContactsProvider from "./context/contactsgroups/ContactContext";
import ScheduleProvider from "./context/messages/ScheduleContext";
import ComainMessagecomponent from "./pages/dashboard/messages/campaign";
import UpdateCompainSMS from "./pages/dashboard/messages/campaign/UpdateCompainSMS";
import GroupContactComponent from "./pages/dashboard/messages/groups";
import GroupProvider from "./context/contactsgroups/GroupContactContext";
// import CreateNewGroup from "./pages/dashboard/messages/groups/CreateNewGroup";
import CreateGroupCoding from "./pages/dashboard/messages/groups/CreateGroupCoding";
import CreateContactsId from "./pages/dashboard/messages/groups/CreateContactsId";
import RepetationProvider from "./context/devices/RepetatioContext";
import GroupAdnContacts from "./pages/dashboard/messages/groups/GroupAdnContacts";
import DataGrid from "./pages/dashboard/reports/DataGrid";
import MessageApiProvider from "./context/messages/MessageApiContext";
import ResendMessages from "./pages/dashboard/reports/ResendMessages";
import Contentuser from "./pages/dashboard/devices/Contentuser";
import ReoprtContents from "./pages/dashboard/reports/ReoprtContents";
import SchedualDataGridMEssages from "./pages/dashboard/reports/SchedualDataGridMEssages";
import UsersSettings from "./pages/dashboard/reports/users/UsersSettings";
import UpdateUsersData from "./pages/dashboard/reports/users/UpdateUsersData";
import DataGridFaulSMS from "./pages/dashboard/reports/DataGridFaulSMS";
import DataGridSucessSMS from "./pages/dashboard/reports/DataGridSucessSMS";
import DashbordProvider from "./context/DashbordContext";
// import DeviceMaster from "./pages/dashboard/reports/users/DeviceMaster";
import ContentDeviceuser from "./pages/dashboard/reports/users/Contentuser";
import OpenDesviceDetialsAdmin from "./pages/dashboard/devices/OpenDesviceDetialsAdmin";
import AuthorizeUserProvider from "./context/AuthorizeUserContext";
import AuthorizeUsers from "./pages/dashboard/reports/users/AuthorizeUsers";
import UpdateAuthorizeUsersId from "./pages/dashboard/reports/users/UpdateAuthorizeUsersId";
import CreatAuthorizeUsers from "./pages/dashboard/reports/users/CreatAuthorizeUsers";
import UserEmailProvider from "./context/UserEmailContext";
import MailTemplateDislay from "./pages/dashboard/reports/users/MailTemplateDislay";
import RequestOtp from "./pages/auth/RequestOtp";
import ResetPassword from "./pages/auth/ResetPassword";
import GenerateOtpProvider from "./context/GenerateOtpContext";
import ResetUserProvider from "./context/ResetUserContext";
import VerifyUserProvider from "./context/VerifyUserContext";
import VerifyCreateUserCodes from "./pages/auth/VerifyCreateUserCodes";
import UserBalanceProvider from "./context/balance/UserBalanceContext";
import UserBalanceDisplay from "./pages/dashboard/reports/balance/UserBalanceDisplay";
import UserDepositHistoryProvider from "./context/balance/UserDepositHistoryContext";
import UserDepositDisplay from "./pages/dashboard/reports/balance/UserDepositDisplay";
import UserAgenBalanProvider from "./context/balance/UserAgenBalanceContext";
import UsersAgentBalanceDisplay from "./pages/dashboard/reports/balance/UsersAgentBalanceDisplay";
import UserStatementDisplay from "./pages/dashboard/reports/balance/UserStatementDisplay";
import UserStatementTransactionProvider from "./context/balance/UserStatementContext";
import DepositStore from "./pages/dashboard/reports/account/DepositStore";
import PlanHomeContextProvider from "./context/devices/PlanHomeContext";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <PlanHomeContextProvider>
          <Routes>
            <Route
              path="/signup/*"
              element={
                <CreateUserProvider>
                  <SignUp />
                </CreateUserProvider>
              }
            />
            <Route path="/login" element={<Login />} />

            <Route
              path="/reset-passowrd"
              element={
                <GenerateOtpProvider>
                  <RequestOtp />
                </GenerateOtpProvider>
              }
            />

            <Route
              path="/verify-code"
              element={
                <ResetUserProvider>
                  <ResetPassword />
                </ResetUserProvider>
              }
            />
            <Route
              path="/verfy-code-mail"
              element={
                <VerifyUserProvider>
                  <VerifyCreateUserCodes />
                </VerifyUserProvider>
              }
            />
            <Route path="/" element={<Contentt />} />

            <Route element={<PrivateRoute element={<Layout />} />}>
              <Route
                path="/*"
                element={
                  <UserMasterProvider>
                    <DeviceMasterProvider>
                      <DashbordProvider>
                        <CreateDeviceProvider>
                          <DeviceOrderProvider>
                            <UserDepositHistoryProvider>
                              <DevicePeriodContextProvider>
                                <PlanContextProvider>
                                  <GroupsProvider>
                                    <ContactsProvider>
                                      <RepetationProvider>
                                        <ScheduleProvider>
                                          <GroupProvider>
                                            <AuthorizeUserProvider>
                                              <MessageApiProvider>
                                                <UserEmailProvider>
                                                  <Routes>
                                                    <Route
                                                      path="/dashboard"
                                                      element={<Content />}
                                                    >
                                                      <Route
                                                        path="devices/:deviceId"
                                                        element={
                                                          <OpenDesviceDetials />
                                                        }
                                                      />
                                                      <Route
                                                        path=":deviceId"
                                                        element={
                                                          <DeviceUseDetails />
                                                        }
                                                      />
                                                    </Route>
                                                    {/* d */}
                                                    <Route
                                                      path="/whatsapp"
                                                      element={
                                                        <ProtectedRoute
                                                          element={
                                                            <Contentuser />
                                                          }
                                                          requiredSection="Device"
                                                          requiredAction="view"
                                                        />
                                                      }
                                                    >
                                                      <Route
                                                        path="devices/:deviceId"
                                                        element={
                                                          <OpenDesviceDetials />
                                                        }
                                                      />
                                                      <Route
                                                        path=":deviceId"
                                                        element={
                                                          <DeviceUseDetails />
                                                        }
                                                      />
                                                    </Route>

                                                    {/* ed */}
                                                    <Route
                                                      path="/sendmessages"
                                                      element={
                                                        <ProtectedRoute
                                                          element={
                                                            <TextComponent />
                                                          }
                                                          requiredSection="Promotion"
                                                          requiredAction="sendmessages"
                                                        />
                                                      }
                                                    />

                                                    <Route
                                                      path="/sendmefilessages"
                                                      element={
                                                        <ProtectedRoute
                                                          element={
                                                            <MediaComponent />
                                                          }
                                                          requiredSection="Promotion"
                                                          requiredAction="sendfilemessages"
                                                        />
                                                      }
                                                    />
                                                    <Route
                                                      path="/user-settings"
                                                      element={
                                                        <UsersSettings />
                                                      }
                                                    />
                                                    <Route
                                                      path="/user-settings/:id"
                                                      element={
                                                        <UpdateUsersData />
                                                      }
                                                    />

                                                    {/*  */}
                                                    {/* */}
                                                    {/*  */}

                                                    <Route
                                                      path="/user-device-settings/:master_user_id"
                                                      element={
                                                        <ContentDeviceuser />
                                                      }
                                                    >
                                                      <Route
                                                        path=":deviceId"
                                                        element={
                                                          <OpenDesviceDetialsAdmin />
                                                        }
                                                      />
                                                    </Route>
                                                    <Route
                                                      path="/authorize-user"
                                                      element={
                                                        <AuthorizeUsers />
                                                      }
                                                    />
                                                    <Route
                                                      path="/mail-settings"
                                                      element={
                                                        <MailTemplateDislay />
                                                      }
                                                    />
                                                    <Route
                                                      path="/authorize-user/:id"
                                                      element={
                                                        <UpdateAuthorizeUsersId />
                                                      }
                                                    />
                                                    <Route
                                                      path="/create-userauth"
                                                      element={
                                                        <CreatAuthorizeUsers />
                                                      }
                                                    />
                                                    {/*  */}
                                                    <Route
                                                      path="/groupsm"
                                                      element={
                                                        <ProtectedRoute
                                                          element={
                                                            <TextGroupComponent />
                                                          }
                                                          requiredSection="groupsm"
                                                          requiredAction="Sendmessages"
                                                        />
                                                      }
                                                    />
                                                    <Route
                                                      path="/groupsendf"
                                                      element={
                                                        <ProtectedRoute
                                                          element={
                                                            <MediaGroupComponent />
                                                          }
                                                          requiredSection="groupsm"
                                                          requiredAction="Sendfilemessages"
                                                        />
                                                      }
                                                    />
                                                    <Route
                                                      path="/sendcampaign"
                                                      element={
                                                        <ProtectedRoute
                                                          element={
                                                            <CompaignComponent />
                                                          }
                                                          requiredSection="Promotion"
                                                          requiredAction="sendcampaign"
                                                        />
                                                      }
                                                    />
                                                    <Route
                                                      path="/updatecampaign/:id"
                                                      element={
                                                        <UpdateCompainSMS />
                                                      }
                                                    />
                                                    <Route
                                                      path="/sendcampaignsms"
                                                      element={
                                                        <ComainMessagecomponent />
                                                      }
                                                    />
                                                    {/* groups-contact */}
                                                    <Route
                                                      path="/groupcontact"
                                                      element={
                                                        <ProtectedRoute
                                                          element={
                                                            <GroupContactComponent />
                                                          }
                                                          requiredSection="contact"
                                                          requiredAction="view"
                                                        />
                                                      }
                                                    />
                                                    <Route
                                                      path="/groupcontact/:id"
                                                      element={
                                                        <CreateContactsId />
                                                      }
                                                    />
                                                    <Route
                                                      path="/messages/:id"
                                                      element={
                                                        <ResendMessages />
                                                      }
                                                    />
                                                    <Route
                                                      path="/creatnewgroup"
                                                      element={
                                                        <GroupAdnContacts />
                                                      }
                                                    />
                                                    <Route
                                                      path="/codegroup"
                                                      element={
                                                        <CreateGroupCoding />
                                                      }
                                                    />
                                                    {/* groups-contact */}
                                                    <Route
                                                      path="/editprofile"
                                                      element={<EditProfile />}
                                                    />
                                                    <Route
                                                      path="/orders"
                                                      element={<OrderPage />}
                                                    />
                                                    <Route
                                                      path="/promotions"
                                                      element={
                                                        <PricingSection />
                                                      }
                                                    />

                                                    <Route
                                                      path="/deposit-store"
                                                      element={<DepositStore />}
                                                    />

                                                    <Route
                                                      path="/updatepricing"
                                                      element={
                                                        <ProtectedRoute
                                                          element={
                                                            <UpdatePlan />
                                                          }
                                                          requiredSection="Device"
                                                          requiredAction="view"
                                                        />
                                                      }
                                                    />
                                                    {/* requiredPermission="Device.view" */}
                                                    <Route
                                                      path="/updatepromotions"
                                                      element={
                                                        <ProtectedRoute
                                                          element={
                                                            <EditPromotion />
                                                          }
                                                          requiredSection="Device"
                                                          requiredAction="view"
                                                        />
                                                      }
                                                    />
                                                    <Route
                                                      path="/reports"
                                                      element={
                                                        <DataGridSucessSMS />
                                                      }
                                                    />

                                                    <Route
                                                      path="/success-message"
                                                      element={<DataGrid />}
                                                    />
                                                    <Route
                                                      path="/failure-message"
                                                      element={
                                                        <DataGridFaulSMS />
                                                      }
                                                    />
                                                    <Route
                                                      path="/total-shcedual"
                                                      element={
                                                        <SchedualDataGridMEssages />
                                                      }
                                                    />
                                                    <Route
                                                      path="/reports"
                                                      element={
                                                        <ProtectedRoute
                                                          element={
                                                            <ReoprtContents />
                                                          }
                                                          requiredSection="reports"
                                                          requiredAction="view"
                                                        />
                                                      }
                                                    />
                                                    <Route
                                                      path="/promotions/:planId"
                                                      element={
                                                        <OrderSubscripPage />
                                                      }
                                                    />

                                                    <Route
                                                      path="/*"
                                                      element={<NotFound />}
                                                    />
                                                  </Routes>
                                                </UserEmailProvider>
                                              </MessageApiProvider>
                                            </AuthorizeUserProvider>
                                          </GroupProvider>
                                        </ScheduleProvider>
                                      </RepetationProvider>
                                    </ContactsProvider>
                                  </GroupsProvider>
                                </PlanContextProvider>
                                <UserBalanceProvider>
                                  <UserAgenBalanProvider>
                                    <UserStatementTransactionProvider>
                                      <Routes>
                                        <Route
                                          path="/balance"
                                          element={<UserBalanceDisplay />}
                                        />
                                        <Route
                                          path="/deposit-history"
                                          element={<UserDepositDisplay />}
                                        />
                                        <Route
                                          path="/deposit-user-history"
                                          element={<UsersAgentBalanceDisplay />}
                                        />
                                        <Route
                                          path="/statement-transaction"
                                          element={<UserStatementDisplay />}
                                        />
                                        <Route
                                          path="/*"
                                          element={<NotFound />}
                                        />
                                      </Routes>
                                    </UserStatementTransactionProvider>
                                  </UserAgenBalanProvider>
                                </UserBalanceProvider>
                              </DevicePeriodContextProvider>
                            </UserDepositHistoryProvider>
                          </DeviceOrderProvider>
                        </CreateDeviceProvider>
                      </DashbordProvider>
                    </DeviceMasterProvider>
                  </UserMasterProvider>
                }
              />
              <Route path="/*" element={<NotFound />} />
            </Route>
          </Routes>
        </PlanHomeContextProvider>
      </Router>
    </AuthProvider>
  );
};

export default App;
