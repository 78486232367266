// src/components/Contentuser.tsx
import React from "react";
// import UserMaster from "./devices/UserMaster";
import { Outlet } from 'react-router-dom';
import UserMaster from "./UserMaster";

const Contentuser: React.FC = () => {
  return (
    <div className="overflow-y-auto hide-scrollbar ">
      <UserMaster/>
      <Outlet />
    </div>
  );
};

export default Contentuser;
