import React, { useState, useCallback, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  parsePhoneNumberFromString,
  CountryCode,
  E164Number,
} from "libphonenumber-js";
import zxcvbn from "zxcvbn";
import { CreateUserContext } from "../../context/CreateUserContext";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";

const SignUp: React.FC = () => {
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState<E164Number | string>("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [, setCountry] = useState<CountryCode | undefined>(undefined);
  const [errors, setErrors] = useState<{
    username?: string;
    name?: string;
    phone?: string;
    email?: string;
    password?: string;
    general?: string;
  }>({});

  const [isLoading, setIsLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<number>(0);
  const navigate = useNavigate();
  const { signup } = useContext(CreateUserContext);

  const validate = useCallback(() => {
    const newErrors: {
      username?: string;
      name?: string;
      phone?: string;
      email?: string;
      password?: string;
      general?: string;
    } = {};

    if (!username) newErrors.username = "مطلوب حقل المستخدم";
    if (!name) newErrors.name = "مطلوب حقل الاسم";
    if (!phone) newErrors.phone = "مطلوب حقل الهاتف";
    if (!email) newErrors.email = "مطلوب حقل البريد الإلكتروني";
    if (!password) {
      newErrors.password = "مطلوب حقل كلمة المرور";
    } else {
      // Validate password strength
      const result = zxcvbn(password);
      if (result.score < 2) {
        newErrors.password =
          "كلمة المرور ضعيفة، حاول استخدام مزيج من الأحرف الكبيرة والصغيرة والأرقام والرموز";
      } else if (hasRepeatingCharacters(password)) {
        newErrors.password =
          "كلمة المرور تحتوي على أحرف مكررة، حاول استخدام مزيج أكثر تنوعاً";
      } else if (!hasSpecialCharacter(password)) {
        newErrors.password =
          "كلمة المرور يجب أن تحتوي على حرف خاص واحد على الأقل";
      }
    }

    return newErrors;
  }, [username, name, phone, email, password]);

  useEffect(() => {
    setErrors(validate());
  }, [username, name, phone, email, password, validate]);

  useEffect(() => {
    const result = zxcvbn(password);
    setPasswordStrength(result.score);
  }, [password]);

  const handlePhoneChange = (value?: E164Number) => {
    if (value) {
      const phoneNumber = parsePhoneNumberFromString(value);
      if (phoneNumber) {
        const countryCode = phoneNumber.country;

        setPhone(phoneNumber.number as E164Number);
        setCountry(countryCode as CountryCode);
      } else {
        setPhone(value);
        setCountry(undefined);
      }
    } else {
      setPhone("");
      setCountry(undefined);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      // console.log(validationErrors);
    } else {
      setErrors({});
      setIsLoading(true);
      try {
        await signup(
          name,
          username,
          phone,
          email,
          password,
          navigate,
          setErrors
        );
        setErrors((prevErrors) => ({ ...prevErrors, general: "" }));
      } catch (error) {
        setErrors({ general: "خطاء بالاتصال يرجى المحاولة" });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const hasRepeatingCharacters = (password: string) => {
    const pattern = /(.)\1{2,}/;
    return pattern.test(password);
  };

  const hasSpecialCharacter = (password: string) => {
    const pattern = /[!@#$%^&*(),.?":{}|<>]/;
    return pattern.test(password);
  };
  return (
    <div className="h-screen overflow-y-auto scrollbar-lightr flex items-center justify-center bg-gray-50 selection:bg-red-100">
      <div
        className="bg-white p-4 rounded-lg shadow-lg w-full max-w-lg"
        dir="rtl"
      >
        <h2 className="text-3xl font-bold mb-2 text-center text-gray-600">
          إنشاء حساب
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-around px-0 mx-3 gap-2">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                المستخدم
              </label>
              <input
                type="text"
                className={`w-full p-2 border ${
                  errors.username ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 `}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              {errors.username && (
                <p className="text-red-500 text-xs mt-1">{errors.username}</p>
              )}
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700">
                الاسم الكامل
              </label>
              <input
                type="text"
                className={`w-full p-2 border ${
                  errors.name ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 `}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && (
                <p className="text-red-500 text-xs mt-1">{errors.name}</p>
              )}
            </div>
          </div>
          <div className="sm:mx-6 mx-3">
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700">
                الهاتف
              </label>
              <div>
                <PhoneInput
                  international
                  // country="YE"

                  className={`w-full p-2 border ${
                    errors.phone ? "border-red-500" : "border-gray-300"
                  } rounded-lg focus:outline-none focus:ring-2 `}
                  placeholder="967772423450 أدخل رقم الهاتف"
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </div>
              {/* <input
                type="text"
                value={phone}
                onChange={handlePhoneChange}
                className={`w-full p-2 border ${
                  errors.phone ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 `}
                placeholder="أدخل رقم الهاتف"
              /> */}
              {errors.phone && (
                <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
              )}
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700">
                البريد الإلكتروني
              </label>
              <input
                type="email"
                className={`w-full p-2 border ${
                  errors.email ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 `}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <p className="text-red-500 text-xs mt-1">{errors.email}</p>
              )}
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700">
                كلمة المرور
              </label>
              <input
                type="password"
                className={`w-full p-2 border ${
                  errors.password ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errors.password && (
                <p className="text-red-500 text-xs mt-1">{errors.password}</p>
              )}
              <div className="mt-2">
                <p
                  className={`text-xs ${
                    passwordStrength === 0
                      ? "text-red-500"
                      : passwordStrength === 1
                      ? "text-yellow-500"
                      : passwordStrength === 2
                      ? "text-yellow-600"
                      : passwordStrength === 3
                      ? "text-green-500"
                      : "text-green-600"
                  }`}
                >
                  قوة كلمة المرور:{" "}
                  {["ضعيفة", "متوسطة", "جيدة", "قوية"][passwordStrength] ||
                    "ضعيفة"}
                </p>
              </div>
            </div>

            {errors.general && (
              <p className="text-red-500 text-center text-xs my-2">
                {errors.general}
              </p>
            )}
            <div className="flex justify-center">
              <button
                type="submit"
                className={`w-full bg-[#2a66b1] text-white p-2 rounded mt-4 ${
                  isLoading ? "opacity-75 animate-fade" : ""
                }`}
                disabled={isLoading}
              >
                {isLoading ? "جارٍ إنشاء حساب..." : "إنشاء حساب"}
              </button>
            </div>
          </div>
        </form>
        <p className="mt-2 text-center">
          هل لديك حساب؟{" "}
          <Link to="/login" className="text-blue-500 hover:underline">
            تسجيل الدخول
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
