import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from "react";
import axios from "axios";
import { AuthContext } from "../AuthContext";

interface DeviceOrderCotextType {
  orderData: any | null;
  loading: boolean;
  fetchOrderMaster: () => void;
}

export const DeviceOrderCotext = createContext<DeviceOrderCotextType>({
  orderData: null,
  loading: false,
  fetchOrderMaster: () => {},
});

const DeviceOrderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [orderData, setOrderData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Fetch the latest device master data
  const fetchOrderMaster = useCallback(async () => {
    if (!token) return;
    setLoading(true);
    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/GETOrderDevice",
        new FormData(),
        {
          headers: {
            'auth-token': token,
          },
          params: {
            token,
          },
        }
      );
      // console.log(response.data)
      setOrderData(response.data);
    } catch (error) {
      console.log("Failed to fetch order data data", error);
    }
    finally {
      setLoading(false); // Stop loading
    }
  }, [token]);

  useEffect(() => {
    fetchOrderMaster();
  }, [fetchOrderMaster]);

  return (
    <DeviceOrderCotext.Provider value={{ orderData,loading, fetchOrderMaster }}>
      {children}
    </DeviceOrderCotext.Provider>
  );
};

export default DeviceOrderProvider;
