import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaPercent, FaSignOutAlt, FaWhatsapp } from "react-icons/fa";
import { AuthContext } from "../context/AuthContext";
import {
  AiFillCode,
  AiFillFileImage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { FiFileText, FiMail, FiSettings } from "react-icons/fi";
import { IoBagHandleOutline } from "react-icons/io5";
import { GiPresent } from "react-icons/gi";
import { MdAccountBalance, MdGroups, MdQueryStats } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowBack } from "react-icons/io";
import { IoMdTime } from "react-icons/io";
import { MdOutlineGroupWork } from "react-icons/md";
import { AiOutlineSend } from "react-icons/ai";
import { RiFileUserLine, RiPriceTag3Line } from "react-icons/ri";
import {
  BiMessageDetail,
  BiSolidContact,
  BiUniversalAccess,
} from "react-icons/bi";
import { MdOutlineAutoFixNormal } from "react-icons/md";
import { FaSquarePollVertical } from "react-icons/fa6";
import { RiDashboardHorizontalLine } from "react-icons/ri";
import { hasPermission } from "../utils/permissionUtils";
import { PiHandDepositBold } from "react-icons/pi";
import { CiDollar } from "react-icons/ci";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { logout, permissions, isagent } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [sidebarExpanded] = useState(storedSidebarExpanded === "true");

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  // const { isagent } = useContext(AuthContext);

  const isDashboardPath = location.pathname.startsWith("/dashboard");
  const { pathname } = location;

  const handleDropdownToggle = (id: string) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  // Handle Esc key to close sidebar
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [sidebarOpen, setSidebarOpen]);

  // Close sidebar when clicking outside of it
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target as Node) ||
        trigger.current.contains(target as Node)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [sidebarOpen, setSidebarOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClick = () => {
    // On mobile, close the sidebar when clicked
    if (isMobile) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  };

  // Close sidebar on pathname change
  useEffect(() => {
    if (sidebarExpanded) {
      setSidebarOpen(false);
    }
  }, [pathname, sidebarExpanded, setSidebarOpen]);

  // Update localStorage and body class based on sidebar state
  const updateBodyClass = (expanded: boolean) => {
    if (expanded) {
      document.body.classList.add("sidebar-expanded");
    } else {
      document.body.classList.remove("sidebar-expanded");
    }
  };

  const canViewWhatsAppDevices = hasPermission(permissions, "Device", "view");
  const canViewContacts = hasPermission(permissions, "contact", "view");

  // const canGroupContacts = hasPermission(
  //   permissions,
  //   "groupContact",
  //   "ViewGroup"
  // );

  const canSendCampaign = hasPermission(
    permissions,
    "Promotion",
    "sendcampaign"
  );

  const canSendfilemessagesGroup = hasPermission(
    permissions,
    "groupsm",
    "Sendfilemessages"
  );
  const canSendmessagesGroup = hasPermission(
    permissions,
    "groupsm",
    "Sendmessages"
  );
  // const canViewGroup = hasPermission(permissions, "Promotion", "view");
  const canViewReports = hasPermission(permissions, "reports", "view");

  const cancodegroupwPromotion = hasPermission(
    permissions,
    "Promotion",
    "codegroup"
  );
  const cansendfilemessagesPromotion = hasPermission(
    permissions,
    "Promotion",
    "sendfilemessages"
  );
  const cansendmessagesPromotion = hasPermission(
    permissions,
    "Promotion",
    "sendmessages"
  );

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    updateBodyClass(sidebarExpanded);
  }, [sidebarExpanded]);
  return (
    <div
      dir="rtl"
      ref={sidebar}
      className={`absolute top-12 right-0 ml-1 overflow-y-auto border-[1px] h-full text-gray-700 border-l border-gray-200 py-2 transition-transform transform ${
        sidebarOpen
          ? "translate-x-0 w-40 sm:w-64 bg-[#f0f0f0]"
          : "sm:w-20 translate-x-full sm:block hidden"
      } md:translate-x-0 z-40 ease-in-out duration-300 scrollbar-light`}
    >
      <div className="flex mx-2 pointer-events-auto">
        <Link to={"/dashboard"}>
          <img
            className="h-10 mb-4 bg-cover"
            src="/logo.png"
            sizes="(max-width: 320px) 280px, (max-width: 480px) 440px, 800px"
            alt="Logo"
            loading="lazy"
          />
        </Link>
      </div>
      <ul className="space-y-2">
        <React.Fragment>
          <li className="group">
            <Link
              onClick={handleClick}
              to="/dashboard"
              className={`flex items-center p-2 rounded-sm transition-colors duration-300 ${
                isDashboardPath
                  ? "bg-stone-200 text-gray-700"
                  : "hover:bg-stone-200 text-gray-700"
              }`}
            >
              <RiDashboardHorizontalLine
                className={`w-6 h-6 ml-3 ${
                  isDashboardPath
                    ? "text-green-500"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-md font-medium ${
                  sidebarOpen ? "block" : "sm:hidden"
                }`}
              >
                لوحة التحكم
              </span>
            </Link>
          </li>
          <li className="group">
            {/* {permissions} */}
            {canViewWhatsAppDevices ? (
              <Link
                onClick={handleClick}
                to="/whatsapp"
                className={`flex items-center p-2 rounded-sm transition-colors duration-300 ${
                  location.pathname === "/whatsapp"
                    ? "bg-stone-200 text-gray-700"
                    : "hover:bg-stone-200 text-gray-700"
                }`}
              >
                <FaWhatsapp
                  className={`w-6 h-6 ml-3 ${
                    location.pathname === "/whatsapp"
                      ? "text-green-500"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-sm font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  أجهزة الواتس اب
                </span>
              </Link>
            ) : (
              <div className="flex items-center p-2 rounded-sm bg-stone-200 text-gray-600 opacity-50 cursor-not-allowed relative">
                <FaWhatsapp className="w-6 h-6 ml-3 text-gray-600" />
                <span
                  className={`text-sm font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  أجهزة الواتس اب
                </span>
              </div>
            )}
          </li>
          <li className="group">
            {canViewContacts ? (
              <Link
                onClick={handleClick}
                to="/groupcontact"
                className={`flex items-center p-2 rounded-sm transition-colors duration-300 ${
                  location.pathname === "/groupcontact"
                    ? "bg-stone-200 text-gray-700"
                    : "hover:bg-stone-200 text-gray-700"
                }`}
              >
                <BiSolidContact
                  className={`w-6 h-6 ml-3 ${
                    location.pathname === "/groupcontact"
                      ? "text-green-500"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-sm font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  جهات الاتصال
                </span>
              </Link>
            ) : (
              <div className="flex items-center p-2 rounded-sm bg-stone-200 text-gray-600 opacity-50 cursor-not-allowed relative">
                <BiSolidContact className="w-6 h-6 ml-3 text-gray-600" />
                <span
                  className={`text-sm font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  جهات الاتصال
                </span>
              </div>
            )}
          </li>
          {/* drop messages */}

          <li className="group">
            <button
              onClick={() => handleDropdownToggle("sendcampaigns")}
              className={`flex items-center p-2 rounded-sm w-full text-right transition-colors duration-300 ${
                location.pathname === "/sendcampaign"
                  ? "bg-stone-200 text-gray-700"
                  : "hover:bg-stone-200 text-gray-700"
              }`}
            >
              <IoMdTime
                className={`w-6 h-6 ml-3 ${
                  location.pathname === "/sendcampaign"
                    ? "text-green-500"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  sidebarOpen ? "block" : "sm:hidden"
                }`}
              >
                الترويج
              </span>
              {openDropdown === "sendcampaigns" ? (
                <IoIosArrowDown className="mr-auto font-bold" size={15} />
              ) : (
                <IoIosArrowBack className="mr-auto font-bold" size={15} />
              )}
            </button>
            <ul
              className={`pl-6 space-y-2 transition-all duration-300 ease-in-out ${
                openDropdown === "sendcampaigns"
                  ? "max-h-screen opacity-100 bg-stone-200"
                  : "max-h-0 hidden opacity-0"
              }`}
            >
              <li>
                {canSendCampaign ? (
                  <Link
                    onClick={handleClick}
                    to="/sendcampaign"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/sendcampaign"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <IoMdTime
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/sendcampaign"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      رسائل مجدولة
                    </span>
                  </Link>
                ) : (
                  <div className="flex items-center p-2 rounded-sm bg-stone-200 text-gray-600 opacity-50 cursor-not-allowed relative">
                    <IoMdTime className="w-6 h-6 ml-3 text-gray-600" />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      رسائل مجدولة
                    </span>
                  </div>
                )}
              </li>
              <li>
                {cansendmessagesPromotion ? (
                  <Link
                    onClick={handleClick}
                    to="/sendmessages"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/sendmessages"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "sm:hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <FiFileText
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/sendmessages"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      رسائل نص
                    </span>
                  </Link>
                ) : (
                  <div className="flex items-center p-2 rounded-sm bg-stone-200 text-gray-600 opacity-50 cursor-not-allowed relative">
                    <FiFileText className="w-6 h-6 ml-3 text-gray-600" />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      رسائل نص
                    </span>
                  </div>
                )}
              </li>
              <li>
                {cansendfilemessagesPromotion ? (
                  <Link
                    onClick={handleClick}
                    to="/sendmefilessages"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/sendmefilessages"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "sm:hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <AiFillFileImage
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/sendmefilessages"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      رسائل ملفات
                    </span>
                  </Link>
                ) : (
                  <div className="flex items-center p-2 rounded-sm bg-stone-200 text-gray-600 opacity-50 cursor-not-allowed relative">
                    <AiFillFileImage className="w-6 h-6 ml-3 text-gray-600" />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      رسائل ملفات
                    </span>
                  </div>
                )}
              </li>
              <li>
                {cancodegroupwPromotion ? (
                  <Link
                    onClick={handleClick}
                    to="/codegroup"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/codegroup"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "sm:hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <MdOutlineAutoFixNormal
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/codegroup"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      ترميز المجموعات
                    </span>
                  </Link>
                ) : (
                  <div className="flex items-center p-2 rounded-sm bg-stone-200 text-gray-600 opacity-50 cursor-not-allowed relative">
                    <MdOutlineAutoFixNormal className="w-6 h-6 ml-3 text-gray-600" />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      ترميز المجموعات
                    </span>
                  </div>
                )}
              </li>
              {/* Add more group links here */}
            </ul>
          </li>

          {/* Groups Dropdown */}

          <li className="group">
            <button
              onClick={() => handleDropdownToggle("groups")}
              className={`flex items-center p-2 rounded-sm w-full text-right transition-colors duration-300 ${
                location.pathname === "/groupsm"
                  ? "bg-stone-200 text-gray-700"
                  : "hover:bg-stone-200 text-gray-700"
              }`}
            >
              <MdGroups
                className={`w-6 h-6 ml-3 ${
                  location.pathname === "/groupsm"
                    ? "text-green-500"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  sidebarOpen ? "block" : "sm:hidden"
                }`}
              >
                المجموعات
              </span>
              {openDropdown === "groups" ? (
                <IoIosArrowDown className="mr-auto font-bold" size={15} />
              ) : (
                <IoIosArrowBack className="mr-auto font-bold" size={15} />
              )}
            </button>
            <ul
              className={`pl-6 space-y-2 transition-all duration-300 ease-in-out ${
                openDropdown === "groups"
                  ? "max-h-screen opacity-100 bg-stone-200"
                  : "max-h-0 hidden opacity-0"
              }`}
            >
              <li>
                {canSendmessagesGroup ? (
                  <Link
                    onClick={handleClick}
                    to="/groupsm"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/groupsm"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <AiOutlineSend
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/groupsm"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      رسائل نصية
                    </span>
                  </Link>
                ) : (
                  <div className="flex items-center p-2 rounded-sm bg-stone-200 text-gray-600 opacity-50 cursor-not-allowed relative">
                    <AiOutlineSend className="w-6 h-6 ml-3 text-gray-600" />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      رسائل نصية
                    </span>
                  </div>
                )}
              </li>
              <li>
                {canSendfilemessagesGroup ? (
                  <Link
                    onClick={handleClick}
                    to="/groupsendf"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/groupsendf"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "sm:hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <MdOutlineGroupWork
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/groupsendf"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      رسائل ملفات
                    </span>
                  </Link>
                ) : (
                  <div className="flex items-center p-2 rounded-sm bg-stone-200 text-gray-600 opacity-50 cursor-not-allowed relative">
                    <MdOutlineGroupWork className="w-6 h-6 ml-3 text-gray-600" />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      رسائل ملفات
                    </span>
                  </div>
                )}
              </li>
              {/* Add more group links here */}
            </ul>
          </li>

          {/*  */}

          {/* pricing */}

          <li className="group">
            <button
              onClick={() => handleDropdownToggle("groupsp")}
              className={`flex items-center p-2 rounded-sm w-full text-right transition-colors duration-300 ${
                location.pathname === "/promotions" ||
                location.pathname === "/orders"
                  ? "bg-stone-200 text-gray-700"
                  : "hover:bg-stone-200 text-gray-700"
              }`}
            >
              <RiPriceTag3Line
                className={`w-6 h-6 ml-3 ${
                  location.pathname === "/promotions" ||
                  location.pathname === "/orders"
                    ? "text-green-500"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  sidebarOpen ? "block" : "sm:hidden"
                }`}
              >
                الاسعار
              </span>
              {openDropdown === "groupsp" ? (
                <IoIosArrowDown className="mr-auto font-bold" size={15} />
              ) : (
                <IoIosArrowBack className="mr-auto font-bold" size={15} />
              )}
            </button>
            <ul
              className={`pl-6 space-y-2 transition-all duration-300 ease-in-out ${
                openDropdown === "groupsp"
                  ? "max-h-screen opacity-100 bg-stone-200"
                  : "max-h-0 hidden opacity-0"
              }`}
            >
              <li>
                <Link
                  onClick={handleClick}
                  to="/promotions"
                  className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                    location.pathname === "/promotions"
                      ? `${
                          sidebarOpen
                            ? "sm:bg-stone-200 text-gray-700"
                            : "sm:bg-stone-200 rounded-lg"
                        }`
                      : "hover:bg-stone-200 text-gray-700"
                  }`}
                >
                  <GiPresent
                    className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                      location.pathname === "/promotions"
                        ? "text-green-500"
                        : "group-hover:text-gray-700"
                    }`}
                  />
                  <span
                    className={`text-xs font-medium ${
                      sidebarOpen ? "block" : "sm:hidden"
                    }`}
                  >
                    الباقات
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClick}
                  to="/orders"
                  className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                    location.pathname === "/orders"
                      ? `${
                          sidebarOpen
                            ? "sm:bg-stone-200 text-gray-700"
                            : "sm:bg-stone-200 rounded-lg"
                        }`
                      : "sm:hover:bg-stone-200 text-gray-700"
                  }`}
                >
                  <IoBagHandleOutline
                    className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                      location.pathname === "/orders"
                        ? "text-green-500"
                        : "group-hover:text-gray-700"
                    }`}
                  />
                  <span
                    className={`text-xs font-medium ${
                      sidebarOpen ? "block" : "sm:hidden"
                    }`}
                  >
                    الطلبات
                  </span>
                </Link>
              </li>
              {/* Add more group links here */}
            </ul>
          </li>

          {/* accounting */}

          {isagent === 1 ? (
            <li className="group">
              <button
                onClick={() => handleDropdownToggle("groupaccount")}
                className={`flex items-center p-2 rounded-sm w-full text-right transition-colors duration-300 ${
                  location.pathname === "/deposit-store" ||
                  location.pathname === "/deposit-withdrow"
                    ? "bg-stone-200 text-gray-700"
                    : "hover:bg-stone-200 text-gray-700"
                }`}
              >
                <FaPercent
                  className={`w-6 h-6 ml-3 ${
                    location.pathname === "/deposit-store" ||
                    location.pathname === "/deposit-withdrow"
                      ? "text-green-500"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-sm font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  الحسابات
                </span>
                {openDropdown === "groupaccount" ? (
                  <IoIosArrowDown className="mr-auto font-bold" size={15} />
                ) : (
                  <IoIosArrowBack className="mr-auto font-bold" size={15} />
                )}
              </button>
              <ul
                className={`pl-6 space-y-2 transition-all duration-300 ease-in-out ${
                  openDropdown === "groupaccount"
                    ? "max-h-screen opacity-100 bg-stone-200"
                    : "max-h-0 hidden opacity-0"
                }`}
              >
                <li>
                  <Link
                    onClick={handleClick}
                    to="/deposit-store"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/deposit-store"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <CiDollar
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/deposit-store"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      سند ايداع - قبض
                    </span>
                  </Link>
                </li>

                {/* Add more group links here */}
              </ul>
            </li>
          ) : null}
          {/* accounting */}
          {/* pricing */}
          {/* settings */}
          {isagent === 1 ? (
            <li className="group">
              <button
                onClick={() => handleDropdownToggle("groupsps")}
                className={`flex items-center p-2 rounded-sm w-full text-right transition-colors duration-300 ${
                  location.pathname === "/user-settings" ||
                  location.pathname === "/deposit-user-history" ||
                  location.pathname === "/updatepromotions" ||
                  location.pathname === "/updatepricing" ||
                  location.pathname === "/authorize-user" ||
                  location.pathname === "/mail-settings"
                    ? "bg-stone-200 text-gray-700"
                    : "hover:bg-stone-200 text-gray-700"
                }`}
              >
                <FiSettings
                  className={`w-6 h-6 ml-3 ${
                    location.pathname === "/user-settings" ||
                    location.pathname === "/deposit-user-history" ||
                    location.pathname === "/updatepromotions" ||
                    location.pathname === "/updatepricing" ||
                    location.pathname === "/mail-settings" ||
                    location.pathname === "/authorize-user"
                      ? "text-green-500"
                      : "group-hover:text-gray-700"
                  }`}
                />
                <span
                  className={`text-sm font-medium ${
                    sidebarOpen ? "block" : "sm:hidden"
                  }`}
                >
                  الإعدادات
                </span>
                {openDropdown === "groupsps" ? (
                  <IoIosArrowDown className="mr-auto font-bold" size={15} />
                ) : (
                  <IoIosArrowBack className="mr-auto font-bold" size={15} />
                )}
              </button>
              <ul
                className={`pl-6 space-y-2 transition-all duration-300 ease-in-out ${
                  openDropdown === "groupsps"
                    ? "max-h-screen opacity-100 bg-stone-200"
                    : "max-h-0 hidden opacity-0"
                }`}
              >
                <li>
                  <Link
                    onClick={handleClick}
                    to="/user-settings"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/user-settings"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <RiFileUserLine
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/user-settings"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      المستخدمين
                    </span>
                  </Link>
                </li>

                <li>
                  <Link
                    onClick={handleClick}
                    to="/deposit-user-history"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/deposit-user-history"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "sm:hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <PiHandDepositBold
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/deposit-history"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      ارصدة حسابات العملاء
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleClick}
                    to="/authorize-user"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/authorize-user"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "sm:hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <BiUniversalAccess
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/authorize-user"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      الصلاحيات
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleClick}
                    to="/mail-settings"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/mail-settings"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <FiMail
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/mail-settings"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      اعدادات الايميل
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleClick}
                    to="/updatepromotions"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/updatepromotions"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "sm:hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <GiPresent
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/updatepromotions"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      تجديد الباقات
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleClick}
                    to="/updatepricing"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/updatepricing"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "sm:hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <AiOutlineShoppingCart
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/updatepricing"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      عروض الأسعار
                    </span>
                  </Link>
                </li>
                {/* Add more group links here */}
              </ul>
            </li>
          ) : null}
          {/* settings */}

          {/* reports */}
          <li className="group">
            <button
              onClick={() => handleDropdownToggle("groupspsreports")}
              className={`flex items-center p-2 rounded-sm w-full text-right transition-colors duration-300 ${
                location.pathname === "/reports" ||
                location.pathname === "/balance" ||
                location.pathname === "/deposit-history" ||
                location.pathname === "/statement-transaction"
                  ? "bg-stone-200 text-gray-700"
                  : "hover:bg-stone-200 text-gray-700"
              }`}
            >
              <FaSquarePollVertical
                className={`w-6 h-6 ml-3 ${
                  location.pathname === "/reports" ||
                  location.pathname === "/balance" ||
                  location.pathname === "/deposit-history" ||
                  location.pathname === "/statement-transaction"
                    ? "text-green-500"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  sidebarOpen ? "block" : "sm:hidden"
                }`}
              >
                التقارير
              </span>
              {openDropdown === "groupspsreports" ? (
                <IoIosArrowDown className="mr-auto font-bold" size={15} />
              ) : (
                <IoIosArrowBack className="mr-auto font-bold" size={15} />
              )}
            </button>
            <ul
              className={`pl-6 space-y-2 transition-all duration-300 ease-in-out ${
                openDropdown === "groupspsreports"
                  ? "max-h-screen opacity-100 bg-stone-200"
                  : "max-h-0 hidden opacity-0"
              }`}
            >
              <li>
                {canViewReports ? (
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      setSidebarOpen(false);
                    }}
                    to="/reports"
                    className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                      location.pathname === "/reports"
                        ? `${
                            sidebarOpen
                              ? "sm:bg-stone-200 text-gray-700"
                              : "sm:bg-stone-200 rounded-lg"
                          }`
                        : "hover:bg-stone-200 text-gray-700"
                    }`}
                  >
                    <BiMessageDetail
                      className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                        location.pathname === "/reports"
                          ? "text-green-500"
                          : "group-hover:text-gray-700"
                      }`}
                    />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      تقارير الرسائل
                    </span>
                  </Link>
                ) : (
                  <div className="flex items-center p-2 rounded-sm bg-stone-200 text-gray-600 opacity-50 cursor-not-allowed relative">
                    <BiMessageDetail className="w-6 h-6 ml-3 text-gray-600" />
                    <span
                      className={`text-xs font-medium ${
                        sidebarOpen ? "block" : "sm:hidden"
                      }`}
                    >
                      تقارير الرسائل
                    </span>
                  </div>
                )}
              </li>
              <li>
                <Link
                  onClick={handleClick}
                  to="/balance"
                  className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                    location.pathname === "/balance"
                      ? `${
                          sidebarOpen
                            ? "sm:bg-stone-200 text-gray-700"
                            : "sm:bg-stone-200 rounded-lg"
                        }`
                      : "sm:hover:bg-stone-200 text-gray-700"
                  }`}
                >
                  <MdAccountBalance
                    className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                      location.pathname === "/balance"
                        ? "text-green-500"
                        : "group-hover:text-gray-700"
                    }`}
                  />
                  <span
                    className={`text-xs font-medium ${
                      sidebarOpen ? "block" : "sm:hidden"
                    }`}
                  >
                    الرصيد
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClick}
                  to="/deposit-history"
                  className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                    location.pathname === "/deposit-history"
                      ? `${
                          sidebarOpen
                            ? "sm:bg-stone-200 text-gray-700"
                            : "sm:bg-stone-200 rounded-lg"
                        }`
                      : "sm:hover:bg-stone-200 text-gray-700"
                  }`}
                >
                  <PiHandDepositBold
                    className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                      location.pathname === "/deposit-history"
                        ? "text-green-500"
                        : "group-hover:text-gray-700"
                    }`}
                  />
                  <span
                    className={`text-xs font-medium ${
                      sidebarOpen ? "block" : "sm:hidden"
                    }`}
                  >
                    حركة الايداع
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClick}
                  to="/statement-transaction"
                  className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                    location.pathname === "/statement-transaction"
                      ? `${
                          sidebarOpen
                            ? "sm:bg-stone-200 text-gray-700"
                            : "sm:bg-stone-200 rounded-lg"
                        }`
                      : "sm:hover:bg-stone-200 text-gray-700"
                  }`}
                >
                  <MdQueryStats
                    className={`${sidebarOpen ? "w-6 h-6" : "w-6 h-6"} ${
                      location.pathname === "/statement-transaction"
                        ? "text-green-500"
                        : "group-hover:text-gray-700"
                    }`}
                  />
                  <span
                    className={`text-xs font-medium ${
                      sidebarOpen ? "block" : "sm:hidden"
                    }`}
                  >
                    كشف الحساب
                  </span>
                </Link>
              </li>
            </ul>
          </li>
          {/* reports */}

          <li className="group">
            <a
              href="https://documenter.getpostman.com/view/18114527/2sA3QwbVPz"
              target="_blank"
              rel="noopener noreferrer"
              className={`flex items-center p-3 rounded-sm transition-colors duration-300 ${
                location.pathname ===
                "https://documenter.getpostman.com/view/18114527/2sA3QwbVPz"
                  ? "bg-stone-200 text-gray-700"
                  : "hover:bg-stone-200 text-gray-700"
              }`}
            >
              <AiFillCode className="w-6 h-6 ml-3" />
              <span
                className={`text-lg font-medium ${
                  sidebarOpen ? "block" : "sm:hidden"
                }`}
              >
                API Docs
              </span>
            </a>
          </li>

          <li className="group">
            <button
              onClick={() => {
                handleLogout();
              }}
              className={`flex items-center p-3 w-full text-left rounded-sm transition-colors duration-300 ${
                location.pathname === "/logout"
                  ? "bg-stone-200 text-gray-700"
                  : "hover:bg-stone-200 text-gray-700"
              }`}
            >
              <FaSignOutAlt
                className={`w-6 h-6 ml-3 ${
                  location.pathname === "/logout"
                    ? "text-gray-700"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-lg font-medium ${
                  sidebarOpen ? "block" : "sm:hidden"
                }`}
              >
                تسجيل الخروج
              </span>
            </button>
          </li>
        </React.Fragment>
      </ul>
    </div>
  );
};

export default Sidebar;
