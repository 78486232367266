import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
} from "react";
import axios from "axios";
import { AuthContext } from "../AuthContext"; // Import AuthContext for token

interface UserBalance {
  id: number;
  name: string;
  currency: string;
  openbalance: string;
  debit_amount: string;
  credit_amount: string;
  debit1: string;
  credit1: string;
  closebalance: string;
  closebalancelocle: string;
  currency_name: string;
}

interface UserBalanceApiResponse {
  status: boolean;
  errNum: string;
  msg: string;
  data: {
    UserBalance: UserBalance[];
  };
}

interface Filters {
  currency: string;
  name: string;
}

interface UserBalanceContextType {
  userBalance: UserBalance[] | null;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  loading: boolean;
  error: string | null;
  fetchUserBalances: () => Promise<void>;
}

interface UserBalanceProviderProps {
  children: ReactNode;
}

export const UserBalanceContext = createContext<UserBalanceContextType | undefined>(undefined);

const UserBalanceProvider: React.FC<UserBalanceProviderProps> = ({ children }) => {
  const [userBalance, setUserBalance] = useState<UserBalance[] | null>(null);
  const [filters, setFilters] = useState<Filters>({
    currency: "",
    name: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Get token from AuthContext
  const { token } = useContext(AuthContext);

  const fetchUserBalances = useCallback(async () => {
    setLoading(true);
    const url = "https://click.one.sky4system.com/api/user/Setting/UserBalance";

    const headers: HeadersInit = {};
    if (token) {
      headers["auth-token"] = token;
    }

    try {
      const response = await axios.get<UserBalanceApiResponse>(url, {
        headers,
        params: {
          token,
        },
      });

      // console.log(response.data)
      if (response.data?.status) {
        setUserBalance(response.data?.data?.UserBalance ?? []);
      } else {
        setError(response.data?.msg || "Failed to fetch user balances");
      }
    } catch (err) {
      setError("An error occurred while fetching user balances.");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchUserBalances();
  }, [fetchUserBalances]);

  return (
    <UserBalanceContext.Provider
      value={{
        userBalance,
        filters,
        setFilters,
        loading,
        error,
        fetchUserBalances,
      }}
    >
      {children}
    </UserBalanceContext.Provider>
  );
};

export const useUserBalance = (): UserBalanceContextType => {
  const context = useContext(UserBalanceContext);
  if (!context) {
    throw new Error("useUserBalance must be used within a UserBalanceProvider");
  }
  return context;
};

export default UserBalanceProvider;
